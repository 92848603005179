import React from "react";
import { FormattedMessage } from 'react-intl';
import { getIn } from 'formik';
import {Form} from "react-bootstrap";

const Input = ({ name, label, padding, columns, formik, type, facultatif, maxlength, required }) => {
    const { errors, handleBlur, setFieldTouched, touched } = formik;
    //le name correspond a la section et le champs attribué
    let allPath = name.split('.');
    const section = allPath[0];
    const champs = allPath[1];
    return (
        <div className={((columns) ? columns : "col-12 col-md-6") + ((padding) ? padding : "")}>
            <div className="mb-4">
                <Form.Label className={
                    //défini si le champs a été touché et si il y a une erreur dessus
                    touched && touched[section] && touched[section][champs] && errors[section] && errors[section][champs] ? "is-invalid-label" : null}>
                    <p className="mb-0">
                        <FormattedMessage
                            //message pour l'input 
                            id={label} 
                            defaultMessage="Not translated"
                            />
                        {required && "*"}
                        {facultatif ? <small className="grey-small ml-2"><FormattedMessage id="general.optional" defaultMessage="Not translated"/></small> : null}
                    </p>
                </Form.Label>
                <Form.Control
                    id={name}
                    value={(formik.values[section][champs]) ? (formik.values[section][champs]) : ""}
                    type={(type === "number") ? "number" : "text"}
                    name={name}
                    onChange={formik.handleChange}
                    isInvalid={getIn(errors, name)}
                    className="form-group"
                    onBlur={handleBlur}
                    onKeyUp={() => setFieldTouched(name, true)}
                    maxLength={maxlength}
                >
                </Form.Control>
                <Form.Control.Feedback type="invalid" className="invalid-feedback">
                    { //si le champs a été touché et qu'il n'est pas valide on lui met la classe d'erreur
                        touched[section] && touched[section][champs] && errors[section] && errors[section][champs] ? (errors[section][champs] === "validation.notvalid") ? <p>Ce champs n'est pas valide</p> : <p>{errors[section][champs]}</p> : null}
                </Form.Control.Feedback>
            </div>
        </div>
    )
}

export default Input;
