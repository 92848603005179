import React, { Component } from "react";
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect
} from "react-router-dom";
import Header from "./Components/Header/Header.jsx";
import Footer from "./Components/Footer/Footer.jsx";
import TranslateProvider from "./Contexts/TranslateContext.jsx";
import PrivateRoute from "./Components/HOC/PrivateRoute.jsx";
import Authentication from "./Components/Authentication/Authentication";
import ConfirmRegistration from "./Components/Authentication/ConfirmRegistration.jsx";
import { CookiesProvider } from "react-cookie";
import MissionsPage from "./Components/Missions/MissionsPage";
import ReinitPassword from "./Components/Authentication/ReinitPassword.jsx";
import { ConfirmPasswordReinitialisation } from "./Components/Authentication/ConfirmPasswordReinitialisation.jsx";
import * as countries from "i18n-iso-countries";
import { ExpertProvider } from "./Contexts/ExpertContext.jsx";
import { HeaderProvider } from "./Components/Header/HeaderContext.jsx";
import ExpertProfile from "./Components/ExpertProfile/ExpertProfile";
import Offres from "./Components/Offres/Offres";
import Offre from "./Components/OffrePage/Offre";
import { OffersProvider } from "./Contexts/OffersContext";
import { ValidationProvider } from "./Contexts/ValidationContext";
import { MissionsProvider } from "./Contexts/MissionsContext";
import AllMissions from "./Components/Missions/AllMissions";
import ToolsPage from "./Components/Tools/ToolsPage";
import ProfileValidatingNotif from "./Components/ExpertProfile/Notification/ProfileValidatingNotif";
import NoMatch from "./Components/NoMatch.jsx";
import { CollapseProvider } from "./Contexts/CollapseContext";
import InternalErrorComponent from "./Components/InternalError";
import ReinitPasswordForm from "./Components/ExpertProfile/Identity/ReinitPassword/ReinitPasswordForm";
import { ArrayEvaluationsProvider } from "./Contexts/EvaluationsContext";
import SeeEvaluations from "./Components/Evaluations/SeeEvaluations";
import ReinitRegistration from "./Components/Authentication/ReinitRegistration";
import { MyApolloProvider } from "./ApolloSetup";
import Rgpd from "./Components/Rgpd/Rgpd.jsx";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
/**
 *
 * Permet de récupérer toutes les informations globales de l'app
 */

const AppProvider = ({ children }) => {
	return (
		<CookiesProvider>
			<TranslateProvider>
				<HeaderProvider>
					<CollapseProvider>
						<ValidationProvider>
							<OffersProvider>
								<ExpertProvider>
									<ArrayEvaluationsProvider>
										<MissionsProvider>{children}</MissionsProvider>
									</ArrayEvaluationsProvider>
								</ExpertProvider>
							</OffersProvider>
						</ValidationProvider>
					</CollapseProvider>
				</HeaderProvider>
			</TranslateProvider>
		</CookiesProvider>
	);
};

/**
 * Router de l'app
 */
class App extends Component {
	/**
	 * Add class fixed-top when scrolling
	 */
	handleScroll = e => {
		let scrollPosY = window.pageYOffset | document.body.scrollTop;
		let subHeader = document.getElementById("sub-header");
		let titleHeader = document.getElementById("title-header");

		if (scrollPosY > 40) {
			if (subHeader) {
				subHeader.className =
					"sub-header d-flex justify-content-between fixed-top shadow-lg";
			}
			if (titleHeader) {
				titleHeader.className = "scrolled";
			}
		} else if (scrollPosY <= 40) {
			if (subHeader) {
				subHeader.className = "sub-header d-flex justify-content-between";
			}
			if (titleHeader) {
				titleHeader.className = "";
			}
		}
	};

	componentDidMount() {
		window.addEventListener("scroll", this.handleScroll);
	}

	render() {
		return (
			<div className="App h-100">
				<Router>
					<AppProvider>
						<MyApolloProvider>
							<Header />
							<Switch>
								<PrivateRoute exact path="/" />
								<Route path="/rgpd" component={Rgpd} />
								<Route path="/authentication/signin/offer_suggestion/:id" component={Authentication}/>
								<Route path="/authentication/signin/offer_postulat/:id" component={Authentication}/>
								<Route path="/authentication/signin/evaluation_expert/:id/page/:page" component={Authentication}/>
								<Route path="/authentication" component={Authentication} />
								<Route path="/confirmRegistration" component={ConfirmRegistration}/>
								<Route path="/reinit_password_form" component={ReinitPassword}/>
								<Route path="/confirmPasswordReinit" component={ConfirmPasswordReinitialisation}/>
								<Route path="/reinitRegistration/:accountCreationToken" component={ReinitRegistration}/>
								<Route path="/not_found" component={NoMatch} />
								<Route path="/internal_error" component={InternalErrorComponent}/>
								<PrivateRoute path="/expert/tools" component={ToolsPage} />
								<PrivateRoute path="/expert/my_profile/validating" component={ProfileValidatingNotif}/>
								<PrivateRoute path="/expert/my_profile/reinit_pasword" component={ReinitPasswordForm}/> 
								<PrivateRoute path="/expert/my_profile" component={ExpertProfile} />
								<PrivateRoute path="/expert/missions/all" component={AllMissions} />
								<PrivateRoute path="/expert/missions" component={MissionsPage} />
								<PrivateRoute path="/expert/offers/:id" component={Offre} />
								<PrivateRoute path="/expert/offers" component={Offres} />
								<PrivateRoute path="/evaluations/:id_mission/page/:page" component={SeeEvaluations} />
								<Route component={() => <Redirect to="/not_found" />} />
							</Switch>
							<Footer />
						</MyApolloProvider>
					</AppProvider>
				</Router>
			</div>
		);
	}
}

export default App;
