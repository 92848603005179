import React, { createContext, useReducer } from 'react';

const initialState = {
    identity: false,
    mission_searched: false,
    abilities: false,
    CV: false,
    supporting_documents: false,
    confirmation: false
}

const ValidationContext = createContext(initialState);

const reducer = (state, action) => {
    const { target, name } = action;
    switch (action.type) {
        case "setValidation": {
            return {
                ...state,
                [name]: target
            }
        }
        default: return state
    }
}

const ValidationProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const setValidation = (e) => { 
        dispatch({ type: "setValidation", name: e.name, target: e.target 
    }) }
    return (
        <ValidationContext.Provider
            value={{
                state,
                methods: {
                    setValidation: setValidation
                }
            }}
        >
        {children}
        </ValidationContext.Provider>
    )
}

export {ValidationContext, ValidationProvider};