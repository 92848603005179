import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form } from 'react-bootstrap';
import { ExpertContext } from "../../Contexts/ExpertContext";
import { Mutation } from "react-apollo";
import { Formik } from 'formik';
import { UPSERT_MISSION_SEARCHED } from './Mutations';
import SubmitButton from "./SubmitButton";
import ModificationAlert from '../Alerts/ModificationAlert';
import { ValidationContext } from "../../Contexts/ValidationContext";
import LoadingAlert from "../Alerts/LoadingAlert";
import useCollapse from "react-collapsed";
import { CollapseContext } from "../../Contexts/CollapseContext";
import { Element } from 'react-scroll';
import { scrollTo } from "./utils";
import Select from 'react-select';
import {TranslateContextWrapper} from "../../Contexts/TranslateContext";

const sectionMessageName = "profile.sections.missionsSearched.";

const customStyles = {
    control: (provided) => ({
        ...provided,
        border: "2px solid #efefef",
        height: "60px",
        color:'#495057',
        fontFamily: "'HELV-NEUE-REG', sans-serifsans-serif",
    }),
    menu: (provided, state) => ({
        ...provided,
        position: "absolute",
        zIndex: "10",
        height: "300px",
    }),
    menuList: (provided, state) => ({
        ...provided,
        height: "300px",
        zIndex: "10",
    })
}

const handleChange = ({ values, setValues }, selected) => {
    setValues({
        ...values,
        identity: {
            ...values.identity,
            mission_duration: selected
        }
    })
}


//Composant qui englobe les missions recherchées

const MissionSearched = (props) => {

    const { state } = useContext(ExpertContext);
    const [alert, openAlert] = useState(false)
    const [alertUpload, openAlertUpload] = useState(false)
    const validationContext = useContext(ValidationContext);
    const collapseContext = useContext(CollapseContext);
    const array = ["short", "medium", "long"];
    const {translateContext} = props;
    const options = [];
    for (let i = 0; i < array.length; i++) options.push({ value: array[i], label: translateContext.translateMessage(`${sectionMessageName}typeOfMission.missionDuration.inputs.${array[i]}`)});

    const { getCollapseProps, getToggleProps } = useCollapse({ isOpen: collapseContext.state.missionSearched });

    return (
        <Mutation
        mutation={UPSERT_MISSION_SEARCHED}
        onCompleted={(datas) => {
            openAlert(true);
            validationContext.methods.setValidation({ name: "mission_searched", target: datas.upSertMissionSearched.validationMissionSearched })
        }}
        onError={(datas) => console.log("missions erreurs")}
        >
            {(missionSearchedCallBack, { loading, errors }) => {
                openAlertUpload(loading)
                
                return (
                    <Formik initialValues={state.expert} onSubmit={(values) => {
                        if (values.identity.mission_duration === "") {
                            values.identity.mission_duration = "NONE"
                        }
                        
                        missionSearchedCallBack({ variables: { mission_duration: JSON.stringify(values.identity.mission_duration), expertID: state.expertID } })
                    }}
                    enableReinitialize
                    render={formikBag => {
                        return (
                            <Element name="missionSearched">
                                    <ModificationAlert alert={alert} openAlert={openAlert} />
                                    {loading && <LoadingAlert alert={alertUpload} openAlert={openAlertUpload} />}
                                    <div id="element" className={(collapseContext.state.missionSearched) ? "profile-container mt-3" : "profile-container underligne mt-3"}>
                                        <div className="row flex-column flex-md-row mb-3">
                                            <div className="col-12 col-md-9 mb-2 mb-md-0">
                                                <div className="d-flex align-items-center justify-content-start">
                                                    <div className="number-menu mr-2 flex-shrink-0">2</div>
                                                    <h1 className="mb-0 px-0"><FormattedMessage id={sectionMessageName + 'title'} defaultMessage="Not translated" /></h1>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md">
                                                <div className="d-flex justify-content-start justify-content-md-end">
                                                    <button className="btn btn-signin" {...getToggleProps({
                                                        onClick: () => {
                                                            collapseContext.methods.setCollapse("missionSearched", !collapseContext.state.missionSearched);
                                                        }
                                                    })}>
                                                        <FormattedMessage id="general.modify" defaultMessage="Not translated" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <section name="missionSearched" {...getCollapseProps()} onTransitionEnd={(e) => {
                                            for (let values in collapseContext.state) {
                                                if (collapseContext.state[values] === true && e.target.getAttribute('name') === values) {
                                                    scrollTo(values);
                                                }
                                            }
                                        }}>
                                            <Form onSubmit={formikBag.handleSubmit}>
                                                <div className="profile-sections">
                                                    <h2 className="title text-uppercase"><FormattedMessage id={sectionMessageName + "typeOfMission.title"} defaultMessage="Not translated" /></h2>
                                                    <div className="row">
                                                        <div className="col">
                                                            <h3><FormattedMessage id={sectionMessageName + "typeOfMission.missionDuration.title"} defaultMessage="Not translated" />*</h3>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col form-group">
                                                            <Select
                                                                styles={customStyles}
                                                                isMulti
                                                                options={options}
                                                                onChange={(selected) => handleChange(formikBag, selected)}
                                                                value={(formikBag.values.identity.mission_duration) ? formikBag.values.identity.mission_duration : null}
                                                                placeholder={translateContext.translateMessage(`${sectionMessageName}typeOfMission.missionDuration.placeholder`)}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="confirmation-container">
                                                    <SubmitButton {...formikBag} />
                                                </div>
                                            </Form>
                                        </section>
                                    </div>
                                </Element>
                            )
                        }}
                    />
                )
            }}
        </Mutation>
    )
}

export default TranslateContextWrapper(MissionSearched);
