import React, { useEffect, useContext } from 'react';
import InputSearch from './InputSearch';
import { TranslateContextWrapper } from '../../Contexts/TranslateContext';
import ProposalsOffers from './ProposalsOffers';
import { OffersContext } from '../../Contexts/OffersContext';
import client from '../../ApolloSetup';
import { GET_ALL_OFFERS_DISPONIBLES, GET_ALL_PROJECTS_ID } from './queries'
import {Loader} from "../Loader/Loader";
import NoOffer from "./NoOffer";

const getAll = async (methods) => {
    try {
        //recupere les infos des offres
        let offers = await client.query({
            query: GET_ALL_OFFERS_DISPONIBLES
        })
        methods.getOffers(offers.data.getAllOffersDisponibles);
        //recupere le tableau d'ID projets
        let projectsID = await client.query({
            query: GET_ALL_PROJECTS_ID
        })
        methods.getProjectsID(projectsID.data.getAllProjects)
        
    } catch (err) {
        console.log(err)
    }
}
const Offres = (props) => {
    const { state, methods } = useContext(OffersContext);

    useEffect(() => {
        getAll(methods);
        return () => methods.resetInput();
    }, [state.reset]);

    if (state.offers && state.offers.length === 0) {
        return (
            <div id="content-offer-empty" className="container my-5 offers">
                <InputSearch />
                <div className="row justify-content-center">
                    <NoOffer />
                </div>
            </div>
        )
    } else if (state.offers && state.offers.length > 0) {
        const valuesDisponibilities = {
            nb_offers: state.offers.length,
            nb_projects: state.projectsID.length,
            plural_project: (state.projectsID.length > 1) ? "s" : "",
            plural_offer: (state.offers.length > 1) ? "s" : "",
        }
        let offersDisponibilities = props.translateContext.translateMessage("offers.offersDisponibilities", "", valuesDisponibilities);
        return (
            <div id="content-offer" className="container my-5 offers">
                <InputSearch />
                <div className="row">
                    <p className="col disponibilities-text">{offersDisponibilities}</p>
                </div>
                <ProposalsOffers />
            </div>
        )
    }
    else {
        return <div><Loader /></div>
    }
}

export default TranslateContextWrapper(Offres);