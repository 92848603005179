import {Component} from 'react';

class OnMountMutation extends Component{
    componentDidMount(){
        this.props.mutate();
    }

    render(){
        return null
    }
}

export default OnMountMutation;