import React from 'react';
import { FormattedMessage } from "react-intl";
import * as moment from "moment";
import { WhiteButton } from "../Buttons/Buttons";

const SeeEvalButton = ({ evaluationEmployee, id }) => {
    if (evaluationEmployee.length === 0) {
        return null;
    } else {
        return <WhiteButton msge="evaluations.seeEvaluations" link={"/evaluations/" + id + "/page/1"} />
    }
}

const MissionComponent = (props) => {
    const { id, title, project } = props.offer;
    if (project) {
        const { chronology } = project;
        let date = chronology.split(",");
        let start = date[0].replace("\"", "");
        let end = date[date.length - 1].replace("\"", "");
        const { lang } = localStorage;

        return (
            <div className="row flex-column">
                <div className="col">
                    <h5 style={{ fontSize: '25px' }} className="mb-2">{title[lang]}</h5>
                    <div className="d-flex justify-content-between">
                        <div className="d-flex justify-content-between w-100">
                            <div className="date mr-5">
                                <p className="title text-uppercase"><FormattedMessage id="missions.missionStart" defaultMessage="Not translated" /></p>
                                <p className="content">{(start) ? moment(start).format("DD/MM/YYYY") : <FormattedMessage id="general.notFilled" defaultMessage="Not translated" />}</p>
                            </div>
                            <div className="date">
                                <p className="title text-uppercase"><FormattedMessage id="missions.missionEnd" defaultMessage="Not translated" /></p>
                                <p className="content">{(end) ? moment(end).format("DD/MM/YYYY") : <FormattedMessage id="general.notFilled" defaultMessage="Not translated" />}</p>
                            </div>
                            {props.date_untied &&
                                <div className="date">
                                    <p className="title text-uppercase"><FormattedMessage id="missions.missionUntied" defaultMessage="Not translated" /></p>
                                    <p className="content">{moment(props.date_untied).format("DD/MM/YYYY")}</p>
                                </div>
                            }
                        </div>
                        <div className='ml-4'>
                            <WhiteButton msge="missions.seeMissionProject" link={"offers/" + id} />
                            <SeeEvalButton {...props} />
                        </div>
                    </div>
                </div>
            </div>
        )
    } else return null;
}
export default MissionComponent;