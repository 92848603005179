import React from "react";
import client from "../../../../ApolloSetup";
import { withCookies } from "react-cookie";
import { FormattedMessage } from "react-intl";
import { Form } from "react-bootstrap";
import { Mutation } from 'react-apollo';
import { Formik } from 'formik';
import * as yup from 'yup';
import { UPDATE_PASSWORD } from "../../Mutations";
import { TranslateContextWrapper } from '../../../../Contexts/TranslateContext.jsx';
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";


const validationSchemas = ({ translateMessage }) => {
    const newPassword = yup.string()
        .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/, translateMessage('validation.passwordnotvalid'))
        .required(translateMessage('validation.required'));
    const oldPassword = yup.string()
        .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/, translateMessage('validation.passwordnotvalid'))
        .required(translateMessage('validation.required'));
    const newPasswordConfirmation = yup.string()
        .equals([yup.ref('newPassword')], translateMessage('validation.passwordNotMatching'))
        .required(translateMessage('validation.required'));

    return yup.object({
        newPassword,
        newPasswordConfirmation,
        oldPassword
    })
}

const ReinitPasswordForm = (props) => {
    const { history } = props;
    return (
        <Mutation
            mutation={UPDATE_PASSWORD}
            onCompleted={async (datas) => {
                await props.cookies.remove("token", { path: '/' });
                await client.mutate({
                    mutation: gql`mutation{
                        logout{
                            description
                        }
                    }`
                })
                history.push("/confirmPasswordReinit");
            }}
            onError={error => {
                console.log(error)
            }}
        >
            {(reinitPassword) => {
                return (
                    <Formik
                        initialValues={{
                            oldPassword: "",
                            newPassword: "",
                            newPasswordConfirmation: ""
                        }}
                        validationSchema={validationSchemas(props.translateContext)}
                        onSubmit={(values) => {
                            reinitPassword({ variables: values })
                        }}
                        render={(formikBag) => {
                            const { values, handleChange, handleSubmit, errors, validateForm } = formikBag;
                            const { oldPassword, newPassword, newPasswordConfirmation } = values;
                            return (
                                <div className="d-flex flex-column justify-content-between mb-5 mt-2">
                                    <div className="d-flex flex-column align-items-center justify-content-between">
                                        <div className="mt-3" >
                                            <h2 id="reinit-form">Réinitialiser mon mot de passe
                                                <div className="encoche-title"></div>
                                            </h2>
                                            <div id="login-form" className="mt-3  px-0">
                                                <Form noValidate className="pt-3 mb-0 mt-2 form reinit-form" onSubmit={handleSubmit}>
                                                    <div className="px-5 pl-5 ">

                                                        <Form.Label htmlFor="oldPassword" ><FormattedMessage id='account.oldPassword' defaultMessage="Not translated" /></Form.Label>
                                                        <Form.Control
                                                            id="oldPassword"
                                                            type='password'
                                                            name='oldPassword'
                                                            value={oldPassword}
                                                            onChange={handleChange}
                                                            isInvalid={errors.oldPassword}
                                                            className='form-group mb-5'
                                                        />
                                                        <Form.Control.Feedback style={{ marginTop: "-30px" }} type="invalid">{errors.oldPassword}</Form.Control.Feedback>
                                                        <Form.Label htmlFor="newPassword"><FormattedMessage id='account.newPassword' defaultMessage="Not translated" /></Form.Label>
                                                        <Form.Control
                                                            id="newPassword"
                                                            name='newPassword'
                                                            type='password'
                                                            value={newPassword}
                                                            onChange={handleChange}
                                                            isInvalid={errors.newPassword}
                                                            className='form-group mb-5'
                                                        />
                                                        <Form.Control.Feedback style={{ marginTop: "-30px" }} type="invalid">{errors.newPassword}</Form.Control.Feedback>
                                                        <Form.Label htmlFor="newPasswordConfirmation"><FormattedMessage id='account.newPasswordConfirmation' defaultMessage="Not translated" /></Form.Label>
                                                        <Form.Control
                                                            id="newPasswordConfirmation"
                                                            name='newPasswordConfirmation'
                                                            type='password'
                                                            value={newPasswordConfirmation}
                                                            onChange={handleChange}
                                                            isInvalid={errors.newPasswordConfirmation}
                                                            className='form-group mb-5'
                                                        />
                                                        <Form.Control.Feedback style={{ marginTop: "-30px", marginBottom: "30px" }} type="invalid">{errors.newPasswordConfirmation}</Form.Control.Feedback>
                                                        <div className="mb-3">
                                                            <button
                                                                type="submit"
                                                                className={'btn btn-block btn-civipol btn-auth-block'}
                                                                onClick={(e) => { validateForm(); }}
                                                            >
                                                                <FormattedMessage id='account.reinitPasswordButton' defaultMessage="Not translated" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }}
                    />
                )
            }}
        </Mutation>

    )
}

export default withRouter(TranslateContextWrapper(withCookies(ReinitPasswordForm)));