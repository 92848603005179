import React, { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { withCookies } from "react-cookie";
import { FormattedMessage } from 'react-intl';

const CreatedAccountAlert = (props) => {

    let { allCookies, cookies } = props;
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (allCookies.Status && allCookies.Status === "COMPLETE") {
            setOpen(true);
        }
    }, [allCookies.Status])

    const handleClick = () => {
        setOpen(false)
        cookies.remove("Status", { path: "/" });
    }
    if (open) {
        return (
            <div>
                <SweetAlert
                    onConfirm={handleClick}
                    onCancel={handleClick}
                    title=""
                    btnSize="sm"
                    showCancel={false}
                    confirmBtnCssClass="btn btn-civipol"
                    closeOnClickOutside={true}
                >
                    <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center">
                        <img src={require("../../Static/Illustrations/illu_validation.svg")} alt="illu_profile" className="img-fluid mb-4 mb-lg-0 mr-lg-5" />
                        <div className="d-flex flex-column">
                            <p className="title font-weight-bold text-center text-lg-left">
                                <FormattedMessage id="validation.confirmationProfileCompletedTitle" defaultMessage="Not translated"></FormattedMessage>
                            </p>
                            <div className="d-flex flex-column">
                                <p className="text text-center text-lg-left mb-0"><FormattedMessage id="validation.confirmationProfileCompleted1" defaultMessage="Not translated"></FormattedMessage></p>
                                <p className="text text-center text-lg-left mb-0"><FormattedMessage id="validation.confirmationProfileCompleted2" defaultMessage="Not translated"></FormattedMessage></p>
                            </div>
                        </div>
                    </div>
                </SweetAlert>
            </div>
        );
    } else return null;
}

export default withCookies(CreatedAccountAlert);