import React, { useEffect, useContext } from 'react';
import MissionsMaper from "./MissionsMaper";
import { WhiteButton } from "../Buttons/Buttons";
import { MissionsContext } from "../../Contexts/MissionsContext";
import { withCookies } from 'react-cookie';
import { GET_EXPERT_MISSIONS } from "./Query";
import client from '../../ApolloSetup';
import { FormattedMessage } from "react-intl";

const AllMissions = (props) => {
    const missionsContext = useContext(MissionsContext);

    const getMissions = async ({ methods }) => {
        try {
            let missions = await client.query({ query: GET_EXPERT_MISSIONS })
            methods.setMissions(missions.data.getMissionsOfUser)
        } catch (err) {
            console.log(err)

        }

    }

    useEffect(() => {
        const { cookies } = props;
        getMissions(cookies, missionsContext);
    }, [])

    return (
        <div className="container my-5 missions">
            <div className="row flex-row">
                <div className="col-12 col-lg-4 mb-4 mb-lg-0">
                    <WhiteButton link="/expert/missions" msge="missions.goBackToMissions" />
                </div>
                <div className="col-12 col-lg-8">
                    <h1><FormattedMessage defaultMessage="Not translated" id="missions.myMissions" /></h1>
                    <div className="encoche-title mb-5"></div>
                    <MissionsMaper all />
                </div>
            </div>
        </div>
    )
}

export default withCookies(AllMissions);
