import React, { useState, useContext, } from 'react';
import { ExpertContext } from "../../../Contexts/ExpertContext";
import { Element } from 'react-scroll';
import { FormattedMessage } from 'react-intl';
import { Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { Mutation } from "react-apollo";
import { UPSERT_IDENTITY } from "../Mutations";
import { identitySchema } from '../schema.identity';
import { TranslateContextWrapper } from '../../../Contexts/TranslateContext.jsx';
import { ValidationContext } from "../../../Contexts/ValidationContext";
import SubmitButton from "../SubmitButton";
import ModificationAlert from '../../Alerts/ModificationAlert';
import LoadingAlert from "../../Alerts/LoadingAlert";
import ContactInformations from "./ExpertContactInformation";
import AboutYou from "./AboutYou";
import 'react-phone-input-2/lib/style.css';
import 'moment/locale/fr';
import useCollapse from "react-collapsed";
import { CollapseContext } from "../../../Contexts/CollapseContext";
import { scrollTo } from "../utils";



//Composant globale qui englobe toute la partie identity
const Identity = (props) => {
    const { state, methods } = useContext(ExpertContext);
    const validationContext = useContext(ValidationContext);
    const collapseContext = useContext(CollapseContext);
    const [alertModification, openAlertModification] = useState(false)
    const [alertUpload, openAlertUpload] = useState(false)
    const [clicked, setClick] = useState(false);

    const collapseProps = useCollapse({ isOpen: collapseContext.state.identity });
    const { getToggleProps, getCollapseProps } = collapseProps;
    return (
        <Mutation
            mutation={UPSERT_IDENTITY}
            onCompleted={(datas) => {
                openAlertModification(true);
                if (datas.upSertIdentity) {
                    methods.setImage(datas.upSertIdentity.profile_pic);
                    methods.updateIdentityAfterFirstUpload(datas.upSertIdentity);
                    let canvas = document.getElementById("canvas")
                    canvas.style.display = "none";
                    validationContext.methods.setValidation({ name: "identity", target: datas.upSertIdentity.validationIdentity })
                }
            }}
            onError={(datas) => {
                console.log("IDENTITY ERROR => ", datas)
            }}
        >
            {(identityCallBack, { loading, errors }) => {
                openAlertUpload(loading)

                return (
                    <Formik initialValues={state.expert}
                        enableReinitialize
                        onSubmit={(values) => {

                            let tmp = Object.create(values);

                            let obj = {
                                identity: tmp.identity,
                            }
                            for (let value in obj.identity) {
                                if (obj.identity[value] === "") {
                                    delete obj.identity[value]
                                }
                            }
                            delete obj.identity.discovered;
                            delete obj.identity.status;
                            delete obj.identity.id;
                            delete obj.identity.mission_duration
                            delete obj.identity.notificationActus
                            delete obj.identity.notificationOffers
                            obj.identity.countries = JSON.stringify(obj.identity.countries)
                            delete obj.identity.ICE;
                            identityCallBack({ variables: { input: obj, expertID: state.expertID } })
                        }}
                        onError={(err) => {
                            console.log(err)
                        }}
                        validationSchema={identitySchema(props.translateContext)}
                        render={formikBag => {
                            return (
                                <Element name="identity">
                                    {loading && <LoadingAlert alert={alertUpload} openAlert={openAlertUpload} />}
                                    <ModificationAlert alert={alertModification} openAlert={openAlertModification} />
                                    <div className={collapseContext.state.identity === true ? "profile-container" : "profile-container underligne"} >
                                        <div className="row flex-column flex-md-row mt-2 mb-3">
                                            <div className="col-12 col-md-9 mb-2 mb-md-0">
                                                <div className="d-flex align-items-center justify-content-start">
                                                    <div className="number-menu mr-2 flex-shrink-0">1</div>
                                                    <h1 className="mb-0 px-0"><FormattedMessage id='profile.sections.identity.title' defaultMessage="Not translated" /></h1>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md">
                                                <div className="d-flex justify-content-start justify-content-md-end">
                                                    <button id="button-test" className="btn btn-signin" {...getToggleProps({ onClick: async (e) => { setClick(!clicked); await collapseContext.methods.setCollapse("identity", !collapseContext.state.identity); } })} >
                                                        <FormattedMessage id="general.modify" defaultMessage="Not translated" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <section name='identity' {...getCollapseProps()} onTransitionEnd={(e) => {
                                                for (let values in collapseContext.state) {
                                                    if (collapseContext.state[values] === true && e.target.getAttribute('name') === values) {
                                                        e.target.style.height = "";
                                                        scrollTo(values);
                                                    }
                                                }
                                        }} >
                                            <Form name="identity"  onSubmit={(e) => {formikBag.handleSubmit(e)}}>
                                                <div className="profile-sections">
                                                    <AboutYou {...formikBag} />
                                                    <ContactInformations {...formikBag} />
                                                </div>
                                                <div className="confirmation-container">
                                                    <SubmitButton {...formikBag} />
                                                </div>
                                            </Form>
                                        </section>
                                    </div>
                                </Element >
                            )
                        }}
                    />
                )
            }}
        </Mutation>
    )
}

export default TranslateContextWrapper(Identity);
