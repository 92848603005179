import React, { Fragment } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { FormattedMessage } from 'react-intl';
import { TranslateContextWrapper } from "../../Contexts/TranslateContext";

const animated = makeAnimated();

export const customStyles = {
    menu: (provided) => ({
        ...provided,
        zIndex: "1000",
    }),
    control: (provided) => ({
        ...provided,
        border: "2px solid #efefef",
        height: "60px",
        fontFamily: "'HELV-NEUE-REG', sans-serifsans-serif",
        color:'#495057'
    }),

}

const handleChange = ({ setValues, values }, selected, section, name) => {
    setValues({
        ...values,
        [section]: {
            ...values[section],
            [name]: (selected.value === "cancel") ? "" : 
            (name === "civility") ? selected.value : selected.label
        }
    })
}

const Selector = (props) => {

    const { array, section, name, path, path2, setTouched, required } = props;
    let isError = false;
    let tab_to_display = [];
    if (name === "civility") {
        tab_to_display = array;
    } else {
        for (let i = 0, count = array.length; i < count; i++) {
            tab_to_display[i] = { value: array[i], label: array[i] }
        }
        tab_to_display.unshift({ value: "cancel", label: "Annuler la sélection" });
    }
    if (props.errors[section] && props.errors[section][name] && props.touched[section] && props.touched[section][name]) {
        isError = true;
    }
    return (
        <Fragment>
            <p className={(props.errors[section] && props.errors[section][name] && props.touched[section] && props.touched[section][name]) ? "is-invalid-label mb-1" : "mb-2"}>
                <FormattedMessage id={((path2) ? path2 : path) + ".inputs." + name} defaultMessage="Not translated" />{required && '*'}
            </p>
            <Select
                onClick={() => setTouched("identity." + section + '.' + name)}
                styles={customStyles}
                options={tab_to_display}
                components={animated}
                onChange={(selected) => { handleChange(props, selected, section, name) }}
                placeholder={(name === "civility") ? props.translateContext.translateMessage("profile.sections.identity.aboutYou.inputs.civilityPlaceholder") : props.translateContext.translateMessage("profile.sections.identity.aboutYou.inputs.placeholderSelector")}
                value={
                    (props.values[section] && props.values[section][name]) ?
                        (name === "civility") ?
                            {label: props.translateContext.translateMessage("profile.sections.identity.aboutYou.inputs." + props.values[section][name].toLowerCase()), value: props.values[section][name],  } : 
                            { label: props.values[section][name], value: props.values[section][name] }
                        : null
                }
            />
            {isError && <p className={"is-invalid"}>
                <FormattedMessage id="validation.notvalid" defaultMessage="Not translated" />
            </p>}

        </Fragment>
    )
}

export default TranslateContextWrapper(Selector);