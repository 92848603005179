import gql from 'graphql-tag'

const GET_OFFERS_BY_PROJECT = gql`
    query getOffersByProject($id: ID!){
        getOffersByProject(id: $id){
            id
        }
    }

`;

const GET_PROJECT_BY_ID = gql`
    query getProjectByOffer($id: ID!){
        getProjectByOffer(id: $id){
            project{
                id 
                title 
                introduction_text
                link
                chronology
                primary_image 
                offers{
                    id
                    id_drupal
                    title
                    description_text
                    mission_duration
                    geo_zone
                    theme
                    expiration_contract
                    introduction_text
                    profile_text
                }
            }
        }
    }
`

const GET_ALL_PROJECTS_ID = gql`
    query {getAllProjects { id }}`

const GET_OFFER = gql`
    query getOffer($id: ID!){
        getOffer(id: $id){
            id
            id_drupal
            title { fr en }
            description_text { fr en }
            mission_length { fr en }
            geographical_zone { fr en }
            application_expiration
            introduction_text { fr en }
            profile_text { fr en }
            job_expertise {fr en parent id_taxo}
            soft_delete
            project {
                id 
                title  { fr en }
                introduction_text { fr en }
                link { fr en }
                chronology
                primary_image { fr en }
                contract_type {fr en}
                themes {fr en id_taxo}
                offers {
                    title { fr en }
                    id
                    soft_delete
                    geographical_zone { fr en }
                    job_expertise {fr en parent id_taxo}
                    application_expiration
                    mission_length{ fr en }
                }
            }
        }
    }
`;

const GET_ALL_OFFERS = gql`
query {
    getAllOffers {
            id
            id_drupal
            title { fr en }
            description_text {fr en}
            mission_length {fr en 
            parent
            }
            geographical_zone {fr en 
            parent
            }
            application_expiration
            introduction_text  {fr en}
            profile_text {fr en}
            job_expertise {fr en
            parent
            id_taxo
            }
            missions {
                staffed
            }
    }
}`;

const GET_ALL_OFFERS_DISPONIBLES = gql`
query {
    getAllOffersDisponibles {
        id
        id_drupal
        title { fr en }
        description_text {fr en}
        mission_length {fr en parent}
        geographical_zone {fr en parent}
        application_expiration
        introduction_text  {fr en}
        profile_text {fr en}
        job_expertise {
            fr
            en
            parent
            id_taxo
        }
        missions {
            staffed
        }
    }
}
`;

const GET_ALL_TAXOS = gql`
query {
    getAllTaxos {
        en
        fr
        id_taxo
        field
        parent
    }
}
`

const SEARCH_OFFER = gql`
query searchOffers($input: String!){
    searchOffers(input: $input) {
        id
        title { fr en }
        description_text {fr en}
        mission_length {fr en 
        parent
        }
        geographical_zone {fr
        en
        parent
        }
        application_expiration
        introduction_text  {fr en}
        profile_text {fr en}
        job_expertise {fr en
        parent
        id_taxo}
    }
}
`


export { GET_ALL_OFFERS, GET_ALL_PROJECTS_ID, GET_PROJECT_BY_ID, GET_OFFERS_BY_PROJECT, GET_OFFER, SEARCH_OFFER, GET_ALL_TAXOS, GET_ALL_OFFERS_DISPONIBLES };