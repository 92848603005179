import * as yup from 'yup';

export const abilitiesSchema = () => {

    const arrayLengthValidation = yup.array().min(1, "validation.required");

    const languages = yup.mixed().test('languages', 'error languagues', function (values) {
        const { path, createError } = this;
        let errorsTmp = [];
        let isFilled = false;

        for (let i = 0; i < values.length; i++) {

            if (values[i].language && values[i].language !== "") {
                errorsTmp[i] = {
                    ...errorsTmp[i],
                    language: (values[i].language === "cancel") ? true : false
                }
            } else {
                errorsTmp[i] = {
                    ...errorsTmp[i],
                    language: true
                }
            }

            if (values[i].level && values[i].level !== "") {
                errorsTmp[i] = {
                    ...errorsTmp[i],
                    level: (values[i].level === "cancel") ? true : false
                }
            } else {
                errorsTmp[i] = {
                    ...errorsTmp[i],
                    level: true
                }
            }

            if (errorsTmp[i].level === false || errorsTmp[i].language === false) {
                isFilled = true;
            }

        }
        //s'il n'y a aucun champs de rempli
        if (!isFilled) {
            return createError({ path: path, message: "validation.selectAtLeastOne" });
        } else {
            //si le premier champs langue/level est correctement rempli
            if ((errorsTmp[0].language === false && errorsTmp[0].level === false)) {
                
                //on regarde pour les autres si ils sont remplis aussi
                for (let i = 1; i < errorsTmp.length; i++) {
                    if ((errorsTmp[i].language === true && errorsTmp[i].level === false) || (errorsTmp[i].language === false && errorsTmp[i].level === true)) {
                        return createError({ path: path, message: JSON.stringify(errorsTmp) });
                    }
                }
                return true;
            } else {
                return createError({ path: path, message: JSON.stringify(errorsTmp) });
            }
        }
    });


    const expert_ability = yup.mixed().test('abilities', 'error abilitites', function (values) {
        const { path, createError } = this;
        let isError = false;
        let errorPath = path;

        if (values) {
            if (values.fonctionnaire === "true") {
                if (!values.fonc_level || !values.DG) {
                    isError = true;
                    errorPath += ".fonctionnaire";
                }
            } else if (values.fonctionnaire === "false") {
                if (!values.accept_service_contract && !values.accept_salarial_contract) {
                    errorPath += ".nonFonctionnaire";
                    isError = true
                }
            }
            if (isError) {
                return createError({ path: errorPath, message: "validation.required" });
            }
            else {
                return true
            }
        } else {
            return createError({ path, message: "validation.required" })
        }
    })

    const skills = arrayLengthValidation;
    return yup.object({
        languages,
        expert_ability,
        skills
    })
}