import gql from 'graphql-tag'

const DEPOSTULE = gql`
    mutation depostule($id_mission: ID!, $expertID: ID){
        depostule(id_mission: $id_mission, expertID: $expertID){
                id
                staffed
                date_postulat
                date_rejected
                date_accepted
                offer {
                    id
                    title { fr en }
                    geographical_zone { fr en }
                    job_expertise {fr en parent id_taxo}
                    application_expiration
                    mission_length{ fr en }
                }
        }
    }
`;
export default DEPOSTULE;