import React, {useContext} from "react";
import { FormattedMessage } from 'react-intl';
import { OffersContext } from '../../Contexts/OffersContext';

const NoOffer = () => {

    const {methods} = useContext(OffersContext);

    const handleClick = (e) => {
        e.preventDefault();
        methods.reset();
    }

    return (
        <div className="d-flex flex-column align-items-center mt-5 template">
            <div className="d-flex flex-column justify-content-center circle-mail-confirmation px-4">
                <div className="position-absolute logo-mail">
                    <img className="img-fluid mb-8" src={require(`../../Static/Illustrations/illu_noserp.svg`)} alt='emailImg' />
                </div>
                <div className="px-4 content-text-circle-mail text-center mb-3">
                    <p className="font-weight-bold text-center title-template">
                        <FormattedMessage id="offers.noResultTitle" defaultMessage="Not translated"/>
                    </p>
                    <p className="mb-5"><FormattedMessage id="offers.noResultText" defaultMessage="Not translated"/></p>
                    <button className="btn btn-civipol" onClick={handleClick}>
                        <FormattedMessage id="offers.noResultAllOffersButton" defaultMessage="Not translated"/>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default NoOffer;