
import * as yup from 'yup';

export const toUpdloadSchema = ({ translateMessage }) => {
    const supportedExtensions = ["pdf", "docx", "jpg", "jpeg", "JPG", "JPEG", "png", "PNG"];
    const supporting_documents = yup
    .array()
    .test("value test", "error test", function (array) {
        const { path, createError } = this;
            let nb_pieces = 0;
            let errors = {};
            let isError = false;
            for (var i = 0, count = array.length; i < count; i++) {
                if (array[i].type !== "CV") {
                    if (array.length > 24) {
                        isError = true;
                        errors.documents = translateMessage("profile.sections.documentToUpload.error_nbMax");
                    }
                    if (array[i].file) {
                        if (array[i].file.size > 5000000) {
                            errors.documents = translateMessage("profile.sections.documentToUpload.error_size");
                            isError = true;
                        }
                    }
                    nb_pieces++;
                    if (array[i].type === "Passport" || array[i].type === "VitalCard") {
                        if (array[i].name
                            && (array[i].name.indexOf('.pdf') === -1
                                && array[i].name.indexOf('.doc') === -1
                                && array[i].name.indexOf('.docx') === -1
                                && array[i].name.indexOf(".jpg") === -1
                                && array[i].name.indexOf(".jpeg") === -1
                                && array[i].name.indexOf(".JPG") === -1
                                && array[i].name.indexOf(".JPEG") === -1
                                && array[i].name.indexOf(".png") === -1
                                && array[i].name.indexOf(".PNG") === -1
                            )) {
                            errors.documents = translateMessage('profile.sections.documentToUpload.error_2');
                            isError = true
                        }
                    }
                    if (array[i].extension && supportedExtensions.includes(array[i].extension)) {
                        isError = false;
                    }
                }
            }
            if (nb_pieces === 0) {
                errors.documents = translateMessage("profile.sections.documentToUpload.error")
                isError = true
            }
            if (isError) {
                return createError({ path, message: errors })
            } else
                return true
        });
    return yup.object({
        supporting_documents: supporting_documents
    })
}