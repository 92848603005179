import React, { useContext } from 'react';
import { Element } from 'react-scroll'
import { FormattedMessage } from 'react-intl';
import { TranslateContextWrapper } from "../../Contexts/TranslateContext";
import CandidaturesMaper from "./CandidaturesMaper";
import { MissionsContext } from '../../Contexts/MissionsContext';
import Notification from "./Notifications";

const Candidatures = (props) => {

    const { translateContext } = props;
    const { state } = useContext(MissionsContext);
    const { candidatures } = { ...state.missions };
    const { loading } = { state };
    let values = {
        nb_demands: candidatures.length,
        plural: (candidatures.length > 1) ? "s" : "",
    }

    let subtitle = translateContext.translateMessage("missions.candidaturesDemands", "", values);

    return (
        <Element id="1" name="1">
            <div className="row">
                <div className="col mb-4">
                    <h1 ><FormattedMessage id="missions.myCandidatures" defaultMessage="Not translated"/></h1>
                    <div className="encoche-title mb-2"></div>
                </div>
            </div>
            <div className="row">
                {(!loading && candidatures.length === 0) && <Notification title='missions.notificationNoCandidatureTitle' text='missions.notificationNoCandidatureText' type="candidature" />}
                {(!loading && candidatures.length > 0) &&
                    <div className="col">
                        <div className="row mb-4">
                            <div className="col">
                                <p className="subtitle">{subtitle}</p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col">
                                <CandidaturesMaper candidatures={candidatures} />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </Element>
    )
}

export default TranslateContextWrapper(Candidatures);
