
const object_format = {
    

    nullToString: (expertInfos) => {
        let tmp = expertInfos;
        for (let value in tmp) {
            if (tmp[value] === null) {
                tmp[value] = "";
            }
            if (Array.isArray(tmp[value])) {
                for (let i = 0, count = tmp[value].length; i < count; i++) {
                    if (tmp[value][i] && tmp[value][i] === null) {
                        tmp[value][i] = "";
                    }
                }
            } else if (typeof tmp[value] === "object") {
                let tab = Object.keys(tmp[value]);
                for (let i = 0, count = tab.length; i < count; i++){
                    if (tmp[value][tab[i]] === null){
                        tmp[value][tab[i]] = "";
                    }
                }
            }
        }
        return tmp;
    }
}

export default object_format;
