import React, { useContext } from "react";
import { ExpertContext } from "../../Contexts/ExpertContext";
import { FormattedMessage } from 'react-intl';
import { Form } from "react-bootstrap";
import DocumentSubContainer from "./documentsToUpload.SubContainer";

const path = "profile.sections.documentToUpload.";

const BtnSelect = (props) => {
    const { keyInput, value, formik } = props;
    const { setValues, values } = formik;
    const id = "add-" + keyInput;
    return (
        <Form.Group className="mb-0">
            <Form.Label
                htmlFor={id}
                className="add-file mb-0"
            >
                <img src={require("../../Static/Icons/icon_addDoc.svg")} alt="add" />
            </Form.Label>
            <Form.Control
                type="file"
                id={id}
                className='input-file'
                onChange={e => {
                    const document = { type: value, link: "", name: e.target.files[0].name, file: e.target.files[0], number: "", mimetype: e.target.files[0].type };
                    setValues({
                        ...values,
                        supporting_documents: [
                            ...values.supporting_documents,
                            document
                        ],
                    })
                }}
            >
            </Form.Control>
        </Form.Group>
    )
}

const DocumentContainer = (props) => {

    const { state } = useContext(ExpertContext);
    const { keyInput, value } = props;
    return (
        <div className={"flex-column" + (keyInput !== 0 ? " to-upload-line" : "")} >
            <div className="d-flex justify-content-between align-items-center py-3">
                <Form.Label
                    className={((state.errorsToUpload && state.errorsToUpload[value]) ? 'is-invalid-label' : null) + 'mb-0'}>
                    <p className="mb-0"><FormattedMessage id={path + value} defaultMessage="Not translated"/></p>
                </Form.Label>
                <BtnSelect {...props} />
            </div>
            <DocumentSubContainer {...props} />
        </div>
    )
}

export default DocumentContainer;