import React from "react";
import { FormattedMessage } from "react-intl";

const PrepareMissionNotification = () => {
    const ButtonAriane = () => {
        return (
            <div className="text-center text-lg-left">
                <a className="btn btn-signin" href={process.env.REACT_APP_ARIANE} target="_blanck" rel="noopener noreferrer"><FormattedMessage id="tools.prepareMissions.arianeButton" defaultMessage="Not translated" /></a>
            </div>
        );
    }

    const ButtonCrisis = () => {
        return (
            <div className="text-center text-lg-left mb-2">
                <a className="btn btn-signin" href={process.env.REACT_APP_CRISIS} target="_blanck" rel="noopener noreferrer"><FormattedMessage id="tools.prepareMissions.crisis24Button" defaultMessage="Not translated" /></a>
            </div>
        );
    }

    const ButtonSignal = () => {
        return (
            <div className="text-center text-lg-left">
                <a className="btn btn-signin" href={process.env.REACT_APP_SIGNAL} target="_blanck" rel="noopener noreferrer"><FormattedMessage id="tools.prepareMissions.signalButton" defaultMessage="Not translated" /></a>
            </div>
        );
    }
    
    const ButtonEmbassy = () => {
        return (
            <div className="text-center text-lg-left">
                <a className="btn btn-signin" href={process.env.REACT_APP_EMBASSY} target="_blanck" rel="noopener noreferrer"><FormattedMessage id="tools.prepareMissions.embassyContactButton" defaultMessage="Not translated" /></a>
            </div>
        );
    }

    return (

        <div className="container-fluid d-flex justify-content-between notifications position-relative mb-4">
            <div className="w-100 d-flex flex-column flex-lg-row justify-content-center align-items-center">
                <img src={require("../../Static/Illustrations/illu_outils_geoloc.svg")} alt="illu_profile" style={{ maxHeight: "140px", maxWidth: "140px" }} className="img-fluid mb-4 mb-lg-0 mr-lg-5" />
                <div className="text-center text-lg-left">
                    <p className="text  mb-0">
                        <FormattedMessage id="tools.prepareMissions.arianeText" defaultMessage="Not translated"></FormattedMessage>
                    </p>
                    <div className="text-center text-lg-left">
                        <ButtonAriane />
                    </div>
                    <hr className="mb-3" />
                    <p className="text  mb-0">
                        <FormattedMessage id="tools.prepareMissions.crisis24Text" defaultMessage="Not translated"></FormattedMessage>
                    </p>
                    <div className="text-center text-lg-left">
                        <ButtonCrisis />
                    </div>
                    <p className="text  mb-0">
                        <FormattedMessage id="tools.prepareMissions.crisis24Text2" defaultMessage="Not translated"></FormattedMessage>
                    </p>
                    <hr className="mb-2" />
                    <p className="text mb-0">
                        <FormattedMessage id="tools.prepareMissions.signalText" defaultMessage="Not translated"></FormattedMessage>
                    </p>
                    <div className="text-center text-lg-left">
                        <ButtonSignal />
                    </div>
                    <hr className="mb-2" />
                    <p className="text mb-0">
                        <FormattedMessage id="tools.prepareMissions.embassyContactText" defaultMessage="Not translated"></FormattedMessage>
                    </p>
                    <div className="text-center text-lg-left">
                        <ButtonEmbassy />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrepareMissionNotification;