 import React from 'react';
import { FormattedMessage } from 'react-intl';
import OfferComponent from "../Offres/OfferComponent";

const ProposalsOffers = (props) => {

    const { offer } = props;
    const { offers } = { ...offer.project };
    return (
        <div className="offers">
            <h2 className="title text-uppercase"><FormattedMessage id="offer.offersAssociate" defaultMessage="Not translated"/></h2>
            {offers.map((value, key) => {
                if (offer.id !== value.id && !value.soft_delete) {
                    return (
                            <div className={(key < offers.length - 1) ? "underligne mb-3" : ""} key={key}>
                                <OfferComponent value={value} key={key} />
                            </div>
                    )
                } else return null
            })}
        </div>
    )
}

export default ProposalsOffers;