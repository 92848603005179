import gql from 'graphql-tag'

const GET_EXPERT_MISSIONS = gql`
    query getMissionsOfUser($expertID: ID){
        getMissionsOfUser(expertID: $expertID){
                id
                staffed
                date_postulat
                date_rejected
                date_accepted
                date_untied
                offer {
                    id
                    title { fr en }
                    geographical_zone { fr en }
                    job_expertise {fr en parent id_taxo}
                    application_expiration
                    mission_length{ fr en }
                    project {
                        id
                        chronology
                    }
                }
                evaluationEmployee {
                    projectCode
                }
            }
    }
`

export {GET_EXPERT_MISSIONS}