import React from 'react';
import { FormattedMessage } from 'react-intl';
import { WhiteButton, ColoredButton } from '../Buttons/Buttons';

/**
 * THIS TEMPLATE IS USED FOR MSGE OF CONFIRMATION OR NOTIFICATIONS
 * Please follow this schemas when you call it to be correct:
 *  
 *  const elems = {};
    elems.img = 'NAME OF YOUR IMG';
    elems.title = 'validation.confirmationMailSentTitle';
    elems.msges = [
        'validation.confirmationMailSent1',
        'validation.confirmationMailSent2'
    ];
    elems.buttons = [
        {color: 'white', msge: 'account.backToHome', link: '/authentication/signin'},
        {color: 'colored', msge: 'account.signup', link: '/authentication/signin'},
    ]

    then call it like: 
    <TemplateEvents {...elems} />
 */


const DisplayButtons = ({ buttons }) => {

    if (buttons) {
        //get an array of buttons object, checj the type of button and display the appropriate button template
        const formattedButton = buttons.map((value, i) => {
            if (value.color === 'white') {
                return (<WhiteButton key={i} {...value} />)
            } else if (value.color === 'colored') {
                return (<ColoredButton key={i} {...value} />)
            } else throw Error("PRECISER LA COULEUR DU BOUTON SVP CF:DANS TEMPLATE EVENTS")
        });
        return formattedButton;
    } else return null;
}

const DisplayMsges = ({ msges }) => {
    if (msges) {
        //get an array of messages  then display it
        const formattedMsges = msges.map((value, i) => {
            return (
                <p key={i}>
                    <FormattedMessage id={value} defaultMessage="Not translated" />
                </p>
            );
        })
        return formattedMsges;
    } else return null;
}

const TemplateEvents = ({ img, title, msges, buttons }) => {


    return (
        <div className="d-flex flex-column align-items-center mt-5 template">
            <div className="d-flex flex-column justify-content-center circle-mail-confirmation px-4">
                <div className="position-absolute logo-mail">
                    <img className="img-fluid mb-8" src={require(`../../Static/Illustrations/${img}`)} alt='emailImg' />
                </div>
                <div className="px-4 content-text-circle-mail text-center">
                    <p className="font-weight-bold text-center title-template">
                        <FormattedMessage id={title} defaultMessage="Not translated" />
                    </p>
                    <div className="text-template">
                        <DisplayMsges msges={msges} />
                    </div>
                    <DisplayButtons buttons={buttons} />
                </div>
            </div>
        </div>)
}

export default TemplateEvents;