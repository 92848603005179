import React  from "react";
import { Form } from "react-bootstrap";

const BtnDelete = (props) => {
    const { value, formik } = props;
    const { setValues, values } = formik;
    const id = 'del-' + value[1];
    return (
        <div>
            <Form.Label
                htmlFor={id}
                className="add-file mb-0">
                <img src={require("../../Static/Icons/icon_removeDoc.svg")} alt="add" />
            </Form.Label>
            <button
                id={id}
                className='input-file'
                onClick={e => {
                    e.preventDefault();
                    let array = values.supporting_documents;
                    array.splice(value[1], 1);
                    //on rempli le tableau des variables formik avec le nouveau tableau
                    setValues({ ...values, supporting_documents: array });
                }}
            >
            </button>
        </div>
    )
}

const Document = (props) => {
    const docsWithNumber = ["Passport", "VitalCard"];
    const { value, formik } = props;
    const { values, setValues } = formik;
    const extension = (value[3]) ? `.${value[3]}` : "";
    return (
        <div className="d-colum align-items-center pb-2">
            <div className={"d-flex align-items-center subselect-toupload justify-content-between" + (props.keyInput !== 0 ? " to-upload-line" : "")}>
                {props.value[0]}{extension}
                <BtnDelete {...props} />
            </div>
            {docsWithNumber.includes(value[2]) &&
                <div className="mb-2">
                    NUMERO
                    <Form.Control
                        value={(values.supporting_documents[value[1]] && values.supporting_documents[value[1]].number)}
                        onChange={(e) => {
                            values.supporting_documents[value[1]].number = e.target.value;
                            setValues(values)
                        }}
                    />
                </div>
            }
        </div>
    )
}

const DocumentSubContainer = (props) => {
    const { value, formik } = props;
    const { values } = formik;
    const { supporting_documents } = values;

    const array = [];
    for (let i = 0; i < supporting_documents.length; i++) {
        if (supporting_documents[i].type === value) {
            array.push([supporting_documents[i].name, i, supporting_documents[i].type, supporting_documents[i].extension]);
        }
    }
    if (array.length > 0) {
        return (
            <div className="px-3 mb-4 py-3" style={{ backgroundColor: "#f7f7f7" }}>
                {array.map((value, key) => (
                    <Document value={value} key={key} keyInput={key} formik={formik} />
                ))}
            </div>
        )
    }
    else return null;
}

export default DocumentSubContainer;