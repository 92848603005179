import React from 'react';
import TemplateEvents from '../Templates/TemplateEvents';
/**
 * Pages of confirmation for password reinit
 */

export const ConfirmPasswordReinitialisation = () => {
    let elems = {
        img: 'illu_password.svg',
        title: "validation.confirmPasswordResetTitle",
        msges: ["validation.confirmPasswordResetDescription"],
        buttons: [{color: 'colored', msge: 'account.signin', link: '/authentication/signin'}]
    };

    return (
        <TemplateEvents {...elems} />
    )
}