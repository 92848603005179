import React, { useContext } from "react";
import { arrayEvaluationsContext } from "../../Contexts/EvaluationsContext";
import { withRouter } from "react-router-dom";
import HeaderPage from "./sections/HeaderPage";
import CompetenceArray from "./sections/CompetenceArray";
import AptitudesArray from "./sections/AptitudeArray";
import General from "./sections/General";
import Pagination from "./sections/Pagination";

const EvaluationExpertComponent = (props) => {

    const { page } = props.match.params;
    const store = useContext(arrayEvaluationsContext);
    const elem = store.state.arrayEvaluations[page - 1];
    return (
        <div>
            <div>
                <HeaderPage elem={elem} />
                <hr className="mb-5" />
                <CompetenceArray elem={elem} />
                <AptitudesArray elem={elem} />
                <hr className="mb-5" />
                <General elem={elem} />
            </div>
            <div>   
                <Pagination store={store}/>
            </div>
        </div>

    );

}

export default withRouter(EvaluationExpertComponent);