import React from 'react';
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo';
import OnMountMutation from '../HOC/OnMountMutation.jsx';
import { withRouter } from 'react-router-dom';
import {withCookies} from 'react-cookie';
import * as moment from 'moment';
import Logout from '../../Services/Logout.jsx';

/**
 * Mutation to confirm the signup when user has clicked on the link
 */
const CONFIRM_MUTATION = gql`
    mutation confirmSignup2($accountCreationToken: String!){
        confirmSignup(accountCreationToken: $accountCreationToken)
        {
            token,
            expert {
                id
                email_1
            }
        }
    }
`;

/**
 * on completed mutation set the token in storage and redirect to home
 */
const mutationComplete = ({confirmSignup}, {history, cookies}) => {
    let expiration = moment().add(1, 'hours').toDate();
    cookies.set('notification', 'confirmation_email', {path:'/', expires: expiration});
    localStorage.setItem('currentExpert', JSON.stringify(confirmSignup));
    history.push('/expert/my_profile');
}

/**
 * Call the confirmSignUp WS to confirm the registration and redirect to signin page
 * 
 */
const ConfirmRegistration = (props) => {
    //get value of the token
    const value = props.location.pathname.replace("/confirmRegistration/code=", "");
    //if value execute mutation
    if (value) {
        return (
            <Mutation
                mutation={CONFIRM_MUTATION}
                onCompleted={(data) => mutationComplete(data, props)}
                onError={() => {props.history.push("/reinitRegistration/" + value);}}
                variables={{ accountCreationToken: value }}
            >
                {(getToken, {error }) => {
                    return (
                        <OnMountMutation mutate={getToken}>
                            {error}
                        </OnMountMutation>
                    )
                }
                }
            </Mutation>
        )
    //else logout (clear storage and token if the is some)
    } else {
        return <Logout />
    }
}

export default withCookies(withRouter(ConfirmRegistration))