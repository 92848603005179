import React, { Fragment } from 'react';
import Signin from './Signin.jsx';
import Signup from './Signup.jsx';
import LeftCard from './LeftCard.jsx';
import { TranslateContextWrapper } from '../../Contexts/TranslateContext.jsx';
import { MailInscription } from "./MailInscription.jsx";
import { ForgottenPassword } from './ForgottenPassword.jsx';
import { MailPassword } from './MailPassword.jsx';
import { Redirect } from "react-router-dom";
import { withCookies } from "react-cookie";
import { withRouter, Link } from "react-router-dom";

//Permet de distribuer la connection, l'enregistrement, la page de password, les pages de confirmation de mdp et de cretation de compte
const Authentication = (props) => {
  const { allCookies, match, history } = props;
  // TODO : GET THE COOKIE, and try to signup directly 
  // then push the correct page. Without getAllOffer, get only one offer. see signin L62

//  const [getAllOffers, { data, called }] = useLazyQuery(GET_ALL_OFFERS);

//   useEffect(() => {
//     if (allCookies.token && match.path === "/authentication/signin/offer_postulat/:id") {
//       getAllOffers();
//     }
//   }, []);

//   if (called && data) {
//     if (data.getAllOffers) {
//       const params = props.match.params.id;
//       const offer = data.getAllOffers.find(offer => offer.id_drupal === params);
//       if (offer) {
//         props.history.push('/expert/offers/' + offer.id);
//       } else {
//         props.history.push('/not_found');
//       }
//     }
//   }

  if (allCookies.token && match.path === "/authentication/signin/offer_suggestion/:id") {

    history.push("/expert/offers/" + match.params.id);
  }
  if (allCookies.token && match.path === "/authentication/signin/evaluation_expert/:id/page/:page") {
    history.push("/evaluations/" + match.params.id + "/page/" + match.params.page);
  }

  const authorizedUrls = [
    "/authentication/signin",
    "/authentication/signup",
    "/authentication/reinit_password",
    "/authentication/mail_inscription",
    "/authentication/email_password",
    "/rgpd",
  ];

  return (
    (authorizedUrls.includes(props.location.pathname) || props.location.pathname.indexOf("/authentication/signin") !== -1) ?
      <div className="wrapper h-100">
        <section className="container-fluid">
          <div className="row flex-row h-100">
            <div className="col-12 col-lg-5" id="sidebar-left">
              <LeftCard />
            </div>
            <div className="col-12 col-lg-7" id="sidebar-right">
              <div className="d-flex flex-column justify-content-between h-100">
                <div className="d-flex flex-column align-items-center justify-content-center mb-5">
                  {
                    <Fragment>
                      <Signin {...props} />
                      <Signup {...props} />
                      <ForgottenPassword {...props} />
                      <MailInscription />
                      <MailPassword />
                    </Fragment>
                  }
                </div>
                <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center px-2 pt-5 pb-3">
                  <span className="order-1 order-sm-0">Copyright © 2019</span>
                  <div className="order-0 order-sm-1 mb-2 mb-sm-0">
                    <ul className="list-unstyled list-inline mb-0">
                      <li className="list-inline-item"><a href="https://civipol.fr/mentions-legales">Mentions légales</a></li>
                      <li className="list-inline-item"><a href="https://civipol.fr/cookies">Cookies</a></li>
                      <Link to='/rgpd' className='list-inline-item' >Rgpd</Link>
                      <li className="list-inline-item"><a href="https://civipol.fr/cgu">CGU</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      : <Redirect to="/not_found" />
  )
}

//Gives translations context to children

export default withRouter(withCookies(TranslateContextWrapper(Authentication)));
