import gql from 'graphql-tag'

const SIGNUP_MUTATION = gql`
    mutation SignupMutation2($email_1: String!, $password: String!, $passwordConfirmation: String!, $firstName: String!, $lastName: String!, $birthday: Date!, $reCaptchaToken: String!)  {
    signup(email_1: $email_1, password: $password, passwordConfirmation: $passwordConfirmation, firstName: $firstName, lastName: $lastName, birthday: $birthday, reCaptchaToken: $reCaptchaToken) {
        id
        email_1
    }
    }`;

const SIGNIN_MUTATION = gql`
    mutation SigninMutation($email_1: String!, $password: String!){
        login(email_1: $email_1, password: $password){
            token
            expert{
                id
                status
                email_1
            }
        }
    }
`


export {SIGNUP_MUTATION, SIGNIN_MUTATION};