import React from "react";
import { evaluationConstants, generalNotationsConstants } from "../evaluation.constants";

const {
    GENERAL_TITLE,
} = evaluationConstants;

const {
    AMELIORABLE,
    CONFORM,
    DEPASSE,
} = generalNotationsConstants;

const General = (props) => {
    const {elem} = props;

    return (
        <div className="row mb-5">
            <div className="col-6">
                <h2 className="title text-uppercase mb-4">{GENERAL_TITLE}</h2>
            </div>
            <div className="col-6 text-left">
                <div disabled className="custom-control custom-radio custom-radio-fat text-left mb-3">
                    <input disabled checked={elem.generalEvaluation === -1} type="radio" value='0' name="general" id="improvable" className="custom-control-input"></input>
                    <label disabled className="custom-control-label" htmlFor="improvable">{AMELIORABLE}</label>
                </div>
                <div disabled className="custom-control custom-radio custom-radio-fat text-left mb-3">
                    <input disabled checked={elem.generalEvaluation === 0} type="radio" value='1' name="general" id="conform" className="custom-control-input"></input>
                    <label disabled  className="custom-control-label" htmlFor="conform">{CONFORM}</label>
                </div>
                <div disabled className="custom-control custom-radio custom-radio-fat text-left mb-3">
                    <input disabled checked={elem.generalEvaluation === 1}  type="radio" value='2' name="general" id="outstanding" className="custom-control-input"></input>
                    <label disabled  className="custom-control-label" htmlFor="outstanding">{DEPASSE}</label>
                </div>
            </div>
        </div>
    );
};

export default General;