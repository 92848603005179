import React, { useContext, useState } from 'react';
import { Element } from 'react-scroll';
import { CollapseContext } from "../../../Contexts/CollapseContext";
import { FormattedMessage } from 'react-intl';
import useCollapse from "react-collapsed";
import { scrollTo } from "../utils";
import { Mutation } from 'react-apollo';
import { Formik } from 'formik';
import { ExpertContext } from '../../../Contexts/ExpertContext';
import PersonsToContact from './PersonsToContact';
import PersonalInformations from './PersonalInformations';
import MedicalInformations from './MedicalInformations';
import { individualInformationsSchema } from '../../../utils/shemas/individualInformations.schema';
import SubmitButton from '../SubmitButton';
import gql from 'graphql-tag';
import LoadingAlert from "../../Alerts/LoadingAlert";
import ModificationAlert from '../../Alerts/ModificationAlert';
import * as yup from 'yup';
//MUTATION
const UPSERT_INDIVIDUALS_INFORMATIONS = gql`
    mutation upsertIndividualInformations($input: IndividualInformationsInputs) {
        upsertIndividualInformations(input: $input) {
            id
        }
    }
`;

//INPUTS VALUES
const ICEinputs = {
  first_name: '',
  last_name: '',
  address_number_street: '',
  address_postal_code: '',
  address_city: '',
  address_country: '',
  relationship: '',
  telephone: '',
  email: '',
};

export const fileInput = {
  name: "",
  file: null,
  mimetype: '',
  size: ''
};

//COMPONENT
const IndividualsInformations = () => {
  const expertStore = useContext(ExpertContext);
  const initialState = {
    ICE: (expertStore.state.expert.ICE && expertStore.state.expert.ICE.length > 0) ? expertStore.state.expert.ICE : [ICEinputs],
    personalInformations: expertStore.state.expert.personalInformations || {
      matrimonialName: '',
      placeOfBirth: '',
      nativeCountry: '',
      passportNumber: '',
      passportPlaceDelivery: '',
      passportExpirationDate: '',
      passportFile: fileInput,
      socialSecurityNumber: '',
      vitalCardFile: fileInput,
      ibanNumber: '',
      ribNumber: '',
      rib: fileInput,
    },
    medicalInformations: expertStore.state.expert.medicalInformations || {
      medicalConsent: false,
      doctorName: '',
      doctorAddress: '',
      doctorPhoneNumberFix: '',
      doctorCellPhoneNumber: '',
      doctorEmail: '',
      bloodGroup: '',
      rhesus: '',
      therapeuticsContrindications: '',
      allergies: ''
    }
  };
  const [alertUpload, openAlertUpload] = useState(false);
  const [alertModification, openAlertModification] = useState(false);
  const collapseContext = useContext(CollapseContext);

  const { getToggleProps, getCollapseProps } = useCollapse({ isOpen: collapseContext.state.individualsInformations });

  return (
    <Mutation
      onCompleted={() => {
        openAlertModification(true);
      }}
      mutation={UPSERT_INDIVIDUALS_INFORMATIONS}
    >
      {(upsertIndividualInformations, { errors, loading }) => {
        openAlertUpload(loading);
        return (
          <Formik
            initialValues={initialState}
            validationSchema={yup.lazy((values) => individualInformationsSchema(values))}
            onSubmit={(values) => {
              let valueCpy = values;
              if (!valueCpy.medicalInformations.medicalConsent || valueCpy.medicalInformations.medicalConsent === "") {
                valueCpy.medicalInformations.medicalConsent = false;
                valueCpy.medicalInformations.doctorName = null;
                valueCpy.medicalInformations.doctorAddress = null;
                valueCpy.medicalInformations.doctorPhoneNumberFix = '';
                valueCpy.medicalInformations.doctorCellPhoneNumber = '';
                valueCpy.medicalInformations.doctorEmail = null;
                valueCpy.medicalInformations.bloodGroup = null;
                valueCpy.medicalInformations.rhesus = null;
                valueCpy.medicalInformations.therapeuticsContrindications = null;
                valueCpy.medicalInformations.allergies = null;
              } else {
                valueCpy.medicalInformations.medicalConsent = true;
              }
              upsertIndividualInformations({
                variables: {
                  input: valueCpy
                }
              });
            }}
            enableReinitialize
            render={
              (formikProps) => {
                return (
                  <Element name="individualsInformations">
                    {loading && <LoadingAlert alert={alertUpload} openAlert={openAlertUpload} />}
                    <ModificationAlert alert={alertModification} openAlert={openAlertModification} />
                    <div className={(collapseContext.state.individualsInformations) ? "profile-container mt-3" : "profile-container underligne mt-3"}>
                      <div className="row flex-column flex-md-row mb-3">
                        <div className="col-12 col-md-9 mb-2 mb-md-0">
                          <div className="d-flex align-items-center justify-content-start">
                            <div className="number-menu mr-2 flex-shrink-0">5</div>
                            <h1 className="mb-0 px-0"><FormattedMessage id="profile.individualsInformations" defaultMessage="Not translated" /></h1>
                          </div>
                        </div>
                        <div className="col-12 col-md">
                          <div className="d-flex justify-content-start justify-content-md-end">
                            <button className="btn btn-signin" {...getToggleProps({
                              onClick: () => {
                                collapseContext.methods.setCollapse("individualsInformations", !collapseContext.state.individualsInformations);
                              }
                            })}>
                              <FormattedMessage id="general.modify" defaultMessage="Not translated" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <form onSubmit={formikProps.handleSubmit} name="individualsInformations" style={{ height: "inherit!important" }} {...getCollapseProps()} onTransitionEnd={(e) => {
                        for (let values in collapseContext.state) {
                          if (collapseContext.state[values] === true && e.target.getAttribute('name') === values) {
                            e.target.style.height = "";
                            scrollTo(values);
                          }
                        }
                      }}>
                        <div className="profile-sections">
                          {/* ICES */}
                          <h2 className="title text-uppercase"><FormattedMessage id={"profile.sections.identity.personToContact.title"} defaultMessage="Not translated" /> </h2>
                          <PersonsToContact formikProps={formikProps} ICEinputs={ICEinputs} />
                          {/* PERSONAL INFORMATIONS */}
                          <h2 className="title text-uppercase"><FormattedMessage id={"profile.sections.individualsInformations.personalInformations.title"} defaultMessage="Not translated" /></h2>
                          <PersonalInformations formikProps={formikProps} />
                          {/* MEDICAL INFORMATION */}
                          <h2 className="title text-uppercase"><FormattedMessage id={"profile.sections.individualsInformations.medicalInformations.title"} defaultMessage="Not translated" /></h2>
                          <MedicalInformations formikProps={formikProps} />
                        </div>
                        <div className="confirmation-container">
                          <SubmitButton {...formikProps} />
                        </div>
                      </form>
                    </div>
                  </Element >
                );
              }}
          />
        );
      }
      }
    </Mutation>
  );

};

export default IndividualsInformations;