import React, { createContext, useReducer } from 'react';

const initialState = {
    offers: {},
    myMission: {},
    projectsID: {},
    projectID: null,
    project: null,
    offersOfProject: null,
    offer: {},
    loadingOffer: true,
    taxonomies: {},
    reset: false,
    input: "",
    error: null,
}

const OffersContext = createContext(initialState);

const reducer = (state, action) => {
    switch (action.type) {
        case "getOffers": {
            return {
                ...state,
                offers: action.target
            }
        }
        case "getProjectsID": {
            return {
                ...state,
                projectsID: action.target
            }
        }
        case "getProjetID": {
            return {
                ...state,
                projectID: action.target
            }
        }
        case "getMission": {
            return {
                ...state,
                myMission: action.target
            }
        }
        case "getProject": {
            return {
                ...state,
                project: action.target
            }
        }
        case "getOffersOfProject":{
            return {
                ...state,
                offersOfProject: action.target
            }
        }
        case 'setOffer': {
            return {...state, offer: action.target, loadingOffer: false}
        }
        case 'getTaxonomies': {
            return {...state, taxonomies: action.target}
        }
        case 'reset': {
            return {...state, reset: !state.reset, input: ""}
        }
        case "handleSearch": {
            return {...state, input: action.target}
        }
        case "resetInput": {
            return {...state, input: ""}
        }
        case "setError":  {
            return {...state, error: action.target}
        }
        default: {
            return state
        }
    }
}

const OffersProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const getOffers = (value) => { dispatch({ type: "getOffers", target: value }); return (state) }
    const getMission = (value) => { dispatch({ type: "getMission", target: value }) }
    const getProjectsID = (value) => { dispatch({ type: "getProjectsID", target: value }) }
    const getProjectID = (value) => { dispatch({ type: "getProjectID", target: value }) }
    const getProject = (value) => { dispatch({ type: "getProject", target: value }) }
    const getOffersOfProject = (value) => {dispatch({ type: "getOffersOfProject", target: value })}
    const setOffer = (value) => {dispatch({type: "setOffer", target: value})};
    const getTaxonomies = (value) => {dispatch({type: "getTaxonomies", target: value})};
    const reset = () => {dispatch({type: "reset"})};
    const handleSearch = (value) => {dispatch({type: "handleSearch", target: value})}
    const resetInput = () => {dispatch({type: "resetInput"})}
    const setError = (e) => {
        dispatch({type: "setError", target: true})
    }
    return (
        <OffersContext.Provider
            value={{
                state,
                methods: {
                    getOffers: getOffers,
                    getMission: getMission,
                    getProjectsID: getProjectsID,
                    getProjectID: getProjectID,
                    getProject: getProject,
                    getOffersOfProject: getOffersOfProject,
                    setOffer: setOffer,
                    getTaxonomies: getTaxonomies,
                    reset: reset,
                    handleSearch:handleSearch,
                    resetInput: resetInput,
                    setError: setError,
                }
            }}
        >
            {children}
        </OffersContext.Provider>
    )
}

export { OffersContext, OffersProvider };