import React from "react";
import { FormattedMessage } from "react-intl";

const ErrorTools = () => {
    return (
        <div className="container py-5 my-5 tools">
            <div className="container-fluid d-flex justify-content-between notifications position-relative mb-4">
                <div className="w-100 d-flex flex-column flex-lg-row justify-content-center align-items-center">
                    <img src={require("../../Static/Illustrations/illu_expert-attente.svg")} alt="illu_profile" style={{ maxHeight: "140px", maxWidth: "140px" }} className="img-fluid mb-4 mb-lg-0 mr-lg-5" />
                    <div className="text-center text-lg-left">
                        <div className="mb-3">
                            <p className="text  mb-0">
                                <FormattedMessage id="tools.error.text" defaultMessage="Not translated"></FormattedMessage>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ErrorTools;