import gql from 'graphql-tag'



const GET_EXPERT_PROFILE = gql`
query getExpert($expertID: ID) {
    getExpert(expertID: $expertID) {
        firstName
        lastName
        nationality_1
        nationality_2
        birthday
        address_1_number_street
        address_1_postal_code
        address_1_city
        address_1_country
        address_2_number_street
        address_2_postal_code
        address_2_city
        address_2_country
        tel_mobile
        civility
        tel_fix
        email_1
        email_2
        mission_duration
        criminal_conviction
        notificationActus
        notificationOffers
        infosExacts
        profile_pic
        status
        countries
        discovered
        validationIdentity
        validationMissionSearched
        validationExpertAbilities
        validationCV
        validationSupportingDocuments
        validationConfirmation
        validationIndividualInformations
        ICE {
            first_name
            last_name
            address_number_street
            address_postal_code
            address_city
            address_country
            telephone
            email
            relationship
        }
        languages {
            level
            language
            justificatif
        }
        supporting_documents{type number link path name extension}
        expert_ability {
            fonctionnaire
            fonc_level
            DG
            accept_salarial_contract
            accept_service_contract
            last_remuneration
            since
            #new fields
            entity
            level
            is_retired
            date_retired
            is_fonctionnaire
            type_of_contract
        }
        skills {
            type
            year_experience
            diploma
        }
        medicalInformations {
            medicalConsent
            doctorName
            doctorAddress
            doctorPhoneNumberFix
            doctorCellPhoneNumber
            doctorEmail
            bloodGroup
            rhesus
            therapeuticsContrindications
            allergies
        }
        personalInformations {
            matrimonialName
            placeOfBirth
            nativeCountry
            passportNumber
            passportPlaceDelivery
            passportExpirationDate
            socialSecurityNumber
            ibanNumber
            ribNumber
            vitalCardFile
            rib
            passportFile
        }
        wasAcceptedToMission
        needs_renewal_consent
        }
    }
`;


export { GET_EXPERT_PROFILE }

