import React, { useState, useContext, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form } from 'react-bootstrap';
import { messages } from '../../../Static/Messages/messages';
import { TranslateContextWrapper } from '../../../Contexts/TranslateContext.jsx';
import LanguageSelection from './LanguagesSelections';
import { ExpertContext } from '../../../Contexts/ExpertContext';
import { Mutation } from "react-apollo";
import { Formik } from 'formik';
import { UPSERT_ABILITIES } from '../Mutations';
import SubmitButton from "../SubmitButton";
import { abilitiesSchema } from "../schema.abilities";
import ModificationAlert from '../../Alerts/ModificationAlert';
import CountriesSelector from "./abilities.CountriesSelector";
import { ValidationContext } from "../../../Contexts/ValidationContext";
import LoadingAlert from "../../Alerts/LoadingAlert";
import useCollapse from "react-collapsed";
import { CollapseContext } from "../../../Contexts/CollapseContext";
import { Element } from 'react-scroll';
import { scrollTo } from "../utils";
import ProfessionalSituation from './ProfessionalSituation/ProfessionalSituation';
const sectionMessageName = "profile.sections.abilities.";

//composant qui retourne les checkboxes de service Details de l'expert
const LevelCompetences = (props) => {

    return (
        <Fragment>
            <ProfessionalSituation {...props}/>
        </Fragment>
    );
}

/**
 * LANGUAGES SELECTION
 */

const Language = (props) => {
    return (
        <div>
            <h2 className="title text-uppercase"><FormattedMessage id={sectionMessageName + "languages.title"} defaultMessage="Not translated" /></h2>
            <div className="mb-4">
                <p className="mb-0"><small className="grey-small"><FormattedMessage id={sectionMessageName + "languages.subtitle"} defaultMessage="Not translated" /></small></p>
                <a href="https://www.cambridgeenglish.org/fr/exams-and-tests/cefr/" target="_blank" rel="noopener noreferrer">
                    <p><small className="grey-small"><u><FormattedMessage id={sectionMessageName + "languages.subtitleLink"} defaultMessage="Not translated" /></u></small></p>
                </a>
                {props.errors.languages && props.errors.languages.all && <p className="is-invalid"><FormattedMessage id="validation.selectAtLeastOne" defaultMessage="Not translated" /></p>}
            </div>
            <LanguageSelection {...props} />
        </div>
    );
}

/**
 * EXPERTISE DOMAINS
 */


// retourne le composant qui affiche les tags sélectionnés et le champs de tags
const DomainExpertise = (props) => {

    const [state, setState] = useState({ skills: [] });

    const [elemsToNotDisplay, setElemToNotDisplay] = useState([]);

    //permet de supprimer un tag si on a cliqué sur la croix
    const deleteSelection = (e, value) => {
        state.skills[e.target.id] = null;
        state.skills = state.skills.filter((el) => {
            return el != null
        })
        props.values.skills[e.target.id] = null;
        props.values.skills = props.values.skills.filter((el) => {
            return el != null
        })
        props.setValues({ ...props.values })
        reinsertInTagsField(value.type)
        setState(prevState => { return { ...prevState, ...state } })
    }
    //retourne les tags selectionnés
    const ExpertiseSelections = () => {
        return (
            <div>
                <Form.Label className={(props.errors.skills && props.touched.skills) ? "is-invalid-label" : null}>
                    <p><FormattedMessage id={sectionMessageName + "expertiseDomain.selectDomain"} defaultMessage="Not translated" />*</p>
                </Form.Label>
                {(props.errors.skills && props.touched.skills) ? <p className="is-invalid"><FormattedMessage id={sectionMessageName + "expertiseDomain.error"} defaultMessage="Not translated" /></p> : null}
                <div>
                    {   //map le tableau de tags selectionnés
                        props.values.skills.map((value, key) => {
                            if (value !== null && props.values.skills) {
                                //permet de recuperer la traduction du tage
                                let strBtn = props.translateContext.translateMessage(sectionMessageName + "expertiseDomain.tags." + value.type)

                                //permet de mettre ... pour pas que le bouton soit déformé
                                // let strBtnSliced = strBtn.slice(0, 17) + "..."
                                return (
                                    <Form.Group className={"d-flex flex-column justify-content-between expertise-field-container"} key={key}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="tag-selected d-flex align-items-center justify-content-center text-center pl-3 pr-4 py-2 mr-auto">
                                                <img src={require("../../../Static/Icons/icon_addedSkill.svg")} alt="encoche-tag" className="mr-2" />
                                                <span className="white-color font-weight-bold">{strBtn}</span>
                                            </div>
                                            <img
                                                className='btn transparent btn-delete-white px-0 py-0 ml-2'
                                                id={key}
                                                onClick={(e) => deleteSelection(e, value)}
                                                src={require("../../../Static/Icons/icon_removeDoc.svg")}
                                                alt="close_icon"
                                            />
                                        </div>
                                        <div className="row flex-row align-items-center my-3">
                                            <div className="col-12 col-md-6 mb-2 mb-md-0">
                                                <div className="d-flex align-items-center">
                                                    <label className="mb-0 mr-3"><FormattedMessage id={sectionMessageName + "expertiseDomain.yearOfExperiences"} defaultMessage="Not translated" /></label>
                                                    <div style={{ width: "80px" }}>
                                                        <Form.Control
                                                            //input pour la selection du nombre d'années d'experiences
                                                            // className="w-100"
                                                            as="select"
                                                            onChange={e => {
                                                                props.values.skills[key].year_experience = parseInt(e.target.value)
                                                                props.setValues({
                                                                    ...props.values
                                                                })
                                                            }}
                                                            value={props.values.skills[key].year_experience}
                                                        >
                                                            {
                                                                //40 années
                                                                [...Array(40)].map((value, key) => {
                                                                    return (
                                                                        <option
                                                                            key={key}
                                                                        >{key + 1}
                                                                        </option>
                                                                    )
                                                                })}
                                                        </Form.Control>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="d-flex align-items-center justify-content-start">
                                                    <label className="mb-0 mr-3"><FormattedMessage id={sectionMessageName + "expertiseDomain.diploma.text"} defaultMessage="Not translated" /></label>
                                                    <div>
                                                        <Form.Group>
                                                            <Form.Check className="custom-control custom-checkbox checkbox-square" type='checkbox' inline>
                                                                <Form.Check.Input
                                                                    type='checkbox' name="diploma" className="custom-control-input" id={"diploma" + key} value={props.values.skills[key].diploma}
                                                                    checked={props.values.skills[key].diploma}
                                                                    onChange={(e) => {
                                                                        props.values.skills[key].diploma = !props.values.skills[key].diploma;
                                                                        props.setValues(props.values)
                                                                    }}
                                                                />
                                                                <Form.Check.Label htmlFor={"diploma" + key} className='custom-control-label'>
                                                                </Form.Check.Label>
                                                            </Form.Check>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Form.Group>
                                )
                            } return null
                        })}
                </div>
            </div>
        )
    }


    //check dans les tags si ils existent dans la globale pour voir si on les réaffiche ou pas
    const reinsertInTagsField = (value) => {
        for (var i = 0, count = elemsToNotDisplay.length; i < count; i++) {
            if (elemsToNotDisplay[i] === value) {
                elemsToNotDisplay.splice(i, 1);
                i--;
            }
        }
        //fonction qui enregistre les éléments a ne pas afficher dans le champs de tags une fois qu'ils sont séléctionnés
        setElemToNotDisplay(elemsToNotDisplay);
    }

    //retourne false si on a deja cliqué sur un des tags pour voir si on l'affiche dans le champs de tags
    const isNotInSkillArray = (value) => {
        if (props.values.skills) {
            for (var i = 0, count = props.values.skills.length; i < count; i++) {
                if (props.values.skills[i]) {
                    if (value === props.values.skills[i].type) {
                        return false
                    }
                }
            }
        }
        return true
    }

    const compare = (a, b) => {
        const valA = props.translateContext.translateMessage(sectionMessageName + "expertiseDomain.tags." + a);
        const valB = props.translateContext.translateMessage(sectionMessageName + "expertiseDomain.tags." + b);
        let comparison = 0;
        if (valA > valB)
            comparison = 1;
        else if (valA < valB)
            comparison = -1;
        return comparison;
    }
    //retourne le champs de tags
    const TagsField = () => {

        //recupére le champs de tags dans les traductions
        let tags = messages.fr.profile.sections.abilities.expertiseDomain.tags;
        tags = Object.keys(tags);
        tags.sort(compare)
        return (
            <div className="tag-fields mb-4">
                {tags.map((value, key) => {

                    if (isNotInSkillArray(value) === true) {
                        return (
                            <button
                                className='tag-btn my-2 mx-2'
                                key={key}
                                onClick={e => {
                                    e.preventDefault();
                                    setState({
                                        ...state,
                                        skills: [
                                            ...state.skills,
                                            { year_experience: 1, type: value },
                                        ]
                                    })
                                    //on remplis les variables avec les valeurs par défault
                                    props.values.skills.push({ year_experience: 1, type: value });
                                    props.setValues({ ...props.values })
                                    //on rempli les éléments a ne pas réafficher
                                    elemsToNotDisplay.push(value)
                                    setElemToNotDisplay(elemsToNotDisplay)
                                }}
                            >
                                <FormattedMessage id={sectionMessageName + "expertiseDomain.tags." + value} defaultMessage="Not translated" />
                            </button>)
                    } else return null;
                }
                )}
            </div>
        )
    }

    //retourne les tags séléctionnés, le champs de tags et la sélection de diplomes
    return (
        <div className="expertise-selection">
            <h2 className="title text-uppercase"><FormattedMessage id={sectionMessageName + "expertiseDomain.title"} defaultMessage="Not translated" /></h2>
            <ExpertiseSelections state={state} />
            <TagsField />
        </div>
    )
}

/**
 * RENDER ELEMENT
 */

//retourne le block de compétences de l'expert


const Abilities = (props) => {

    const { state } = useContext(ExpertContext);
    const [alert, openAlert] = useState(false)
    const [alertUpload, openAlertUpload] = useState(false)
    const validationContext = useContext(ValidationContext);
    const collapseContext = useContext(CollapseContext);
    const { getToggleProps, getCollapseProps } = useCollapse({ isOpen: collapseContext.state.abilities});
    return (
        <Mutation
            onCompleted={(datas) => {
                openAlert(true);
                validationContext.methods.setValidation({ name: "abilities", target: datas.upSertExpertAbilities.validationExpertAbilities })
            }}
            onError={(datas) => console.log("abilities erreurs")}
            mutation={UPSERT_ABILITIES}
        >
            {(abilitiesCallback, { errors, loading }) => {
                openAlertUpload(loading)
                return (
                    <Formik
                        initialValues={state.expert}
                        onSubmit={(values) => {
                            let obj = {
                                languages: values.languages,
                                expert_ability: values.expert_ability,
                                skills: values.skills,
                                countries: values.identity.countries ? JSON.stringify(values.identity.countries) : null,
                            }
                            if (obj.expert_ability.fonctionnaire === true) {
                                obj.expert_ability.accept_salarial_contract = null;
                                obj.expert_ability.last_remuneration = null;
                                obj.expert_ability.accept_service_contract = null;
                            }
                            for (let value in obj.expert_ability) {
                                if (obj.expert_ability[value] === "true" || obj.expert_ability[value] === "false") {
                                    obj.expert_ability[value] = JSON.parse(obj.expert_ability[value])
                                }
                            }
                            for (let value in obj.expert_ability) {
                                if (value !== "last_remuneration" && obj.expert_ability[value] === "") {
                                    delete obj.expert_ability[value]
                                }
                            }
                            abilitiesCallback({ variables: { input: obj, expertID: state.expertID } })
                        }}
                        validationSchema={abilitiesSchema}
                        render={formikBag => {
                            return (
                                <Element name="abilities">
                                    <ModificationAlert alert={alert} openAlert={openAlert} />
                                    <LoadingAlert alert={alertUpload} openAlert={openAlertUpload} />
                                    <div className={(collapseContext.state.abilities) ? "profile-container mt-3" : "profile-container underligne mt-3"}>
                                        <div className="row flex-column flex-md-row mb-3">
                                            <div className="col-12 col-md-9 mb-2 mb-md-0">
                                                <div className="d-flex align-items-center justify-content-start">
                                                    <div className="number-menu mr-2 flex-shrink-0">3</div>
                                                    <h1 className="mb-0 px-0"><FormattedMessage id='profile.sections.abilities.title' defaultMessage="Not translated" /></h1>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md">
                                                <div className="d-flex justify-content-start justify-content-md-end">
                                                    <button className="btn btn-signin" {...getToggleProps({
                                                        onClick: () => {
                                                            collapseContext.methods.setCollapse("abilities", !collapseContext.state.abilities);
                                                        }
                                                    })} >
                                                        <FormattedMessage id="general.modify" defaultMessage="Not translated" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <Form onSubmit={formikBag.handleSubmit} name="abilities" style={{ height: "inherit!important" }} {...getCollapseProps()} onTransitionEnd={(e) => {
                                            for (let values in collapseContext.state) {
                                                if (collapseContext.state[values] === true && e.target.getAttribute('name') === values) {
                                                    e.target.style.height = "";
                                                    scrollTo(values);
                                                }
                                            }
                                        }}>
                                            <div className='profile-sections'>
                                                <Language {...formikBag} translateContext={props.translateContext} />
                                                <LevelCompetences {...formikBag} />
                                                <DomainExpertise {...formikBag} translateContext={props.translateContext} />
                                                <CountriesSelector {...formikBag} />
                                            </div>
                                            <div className="confirmation-container">
                                                <SubmitButton {...formikBag} />
                                            </div>
                                        </Form>
                                    </div>
                                </Element>
                            )
                        }}
                    />
                )
            }}
        </Mutation>

    )
}

export default TranslateContextWrapper(Abilities);
