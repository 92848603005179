import * as yup from 'yup';
import * as countries from "i18n-iso-countries";

export const identitySchema = ({ translateMessage }) => {
    const nameRegex = /^[\w'\-.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{0,}$/;
    const frenchCountries = Object.values(countries.getNames("fr"));
    const civilities = ["MALE", "FEMALE"];
    const SUPPORTED_FORMATS_IMG = [
        "image/jpg",
        "image/jpeg",
        "image/png"
    ];

    //----------fonctions de validations---------------

    const nameValidation = yup.string()
        // .test("len", "validation.textTooLong", val => val.length < 50)
        .matches(nameRegex, translateMessage('validation.lastName'))

    const addressNumbStreetValidation = yup
        .string()
        // .test("len", translateMessage("validation.textTooLong"), val => val.length < 50)

    const cityValidation = yup
        .string()
        // .test("len", translateMessage("validation.textTooLong"), val => val.length < 50)

    const countryValidation = yup
        .mixed()
        .oneOf(frenchCountries, translateMessage("validation.notvalid"))

    const postalValidation = yup.string()
    // .test("len", "validation.textTooLong", val => val.length < 50)
    // .matches(postalCode, {message: translateMessage("validation.notvalid"), excludeEmptyString: true});

    const mobileValidation = yup.string();

    const emailValidation = yup.string()
        .email(translateMessage('validation.notvalid'))

    const civilityValidation = yup.mixed().oneOf(civilities, translateMessage('validation.notvalid'));

    const nationalityValidation = yup.mixed().oneOf(frenchCountries, translateMessage('validation.notvalid'));

    //variables pour la partie infos expert de Identité

    const profile_pic = yup.mixed().test("format", "validation.badFormatRequired", value => {
        if (!value) return true;
        const type = value.type;


        if (value && !value.type) {
            if (value.indexOf('.jpg') === -1
                && value.indexOf('.jpeg') === -1
                && value.indexOf('.JPEG') === -1
                && value.indexOf('.JPG') === -1
                && value.indexOf('.png') === -1
                && value.indexOf('.PNG') === -1
            ) {
                return false
            }
        } else if (SUPPORTED_FORMATS_IMG.includes(type)) {
            return true
        } else if (value.type.indexOf('.jpg') === -1
            && value.type.indexOf('.jpeg') === -1
            && value.type.indexOf('.JPG') === -1
            && value.type.indexOf('.JPEG') === -1
            && value.type.indexOf('.png') === -1
            && value.type.indexOf('.PNG') === -1) {
            return false
        }
        return true
    });
    const civility = civilityValidation.required(translateMessage("validation.required"));
    const firstName = nameValidation.test("len", translateMessage("validation.textTooLong"), val => {if (val) return val.length < 50; else return true;}).required(translateMessage("validation.required"));
    const lastName = nameValidation.test("len", translateMessage("validation.textTooLong"), val => {if (val) return val.length < 50; else return true;}).required(translateMessage("validation.required"));
    const birthday = yup.date().required();
    const address_1_number_street = addressNumbStreetValidation.test("len", translateMessage("validation.textTooLong"), val => {if (val) return val.length < 50; else return true;}).required(translateMessage("validation.required"));
    const address_1_postal_code = postalValidation.test("len", translateMessage("validation.textTooLong"), val => {if (val) return val.length < 20; else return true;}).required(translateMessage("validation.required"));
    const address_1_city = cityValidation.test("len", translateMessage("validation.textTooLong"), val => {if (val) return val.length < 50; else return true;}).required(translateMessage("validation.required"));
    const address_1_country = countryValidation.required(translateMessage("validation.required"));
    const address_2_number_street = addressNumbStreetValidation;
    const address_2_postal_code = postalValidation.test("len", "validation.textTooLong", val => {if (val) return val.length < 20; else return true;});
    const address_2_city = cityValidation;
    const nationality_1 = nationalityValidation.required(translateMessage("validation.required"));
    const email_1 = emailValidation.test("len", translateMessage("validation.textTooLong"), val => {if (val) return val.length < 50; else return true;}).required(translateMessage("validation.required"));
    const email_2 = emailValidation;
    const tel_mobile = mobileValidation.required(translateMessage("validation.required"));
    const tel_fix = mobileValidation;

    // objet de validation pour la partie infos expert de Identité
    const identity = yup.object().shape({
        firstName,
        lastName,
        birthday,
        profile_pic,
        address_1_number_street,
        address_1_postal_code,
        address_1_city,
        address_1_country,
        address_2_number_street,
        address_2_postal_code,
        address_2_city,
        civility,
        nationality_1,
        email_1,
        email_2,
        tel_mobile,
        tel_fix,
    })

    return yup.object({
        identity,
    });
};