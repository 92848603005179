import React from "react";
import { Table } from "react-bootstrap";
import { evaluationConstants, notationsConstants } from "../evaluation.constants";
import { messages } from "../../../Static/Messages/messages";
import {FormattedMessage} from "react-intl";

const {
    COMPETENCES_TITLE,
} = evaluationConstants;

const {
    NON_APPLICABLE,
    NOTIONS,
    CONFIRMED,
    MAITRISE,
    EXPERT,
} = notationsConstants;

const pathCompetences = "evaluationExpert.competencesCriterias.";


const TableHead = () => {
    return (
        <thead >
            <tr>
                <th>
                    <h2 className="title text-uppercase mb-0">{COMPETENCES_TITLE}</h2>
                </th>
                <th>
                    <h2 className="title text-uppercase mb-0">{NON_APPLICABLE}</h2>
                </th>
                <th>
                    <h2 className="title text-uppercase mb-0">{NOTIONS}</h2>
                </th>
                <th>
                    <h2 className="title text-uppercase mb-0">{CONFIRMED}</h2>
                </th>
                <th>
                    <h2 className="title text-uppercase mb-0">{MAITRISE}</h2>
                </th>
                <th>
                    <h2 className="title text-uppercase mb-0">{EXPERT}</h2>
                </th>
            </tr>
        </thead>
    )
}

const CompetencesCriteriasMapper = ({criteres}) => {

    const criterias = messages.fr.evaluationExpert.competencesCriterias;
    let table = Object.keys(criterias).map((value, key) => {
        let key_value = "";
        for (let i = 0; i < criteres.length; i++) {if (criteres[i].name === value) key_value = criteres[i].value};
        return (
            <tr key={key}>
                <td><FormattedMessage id={pathCompetences + value} /></td>
                <td>
                    <div disabled className="custom-control custom-radio custom-radio-fat text-center">
                        <input disabled checked={key_value === -1} type="radio" value='-1' name={value} id={"non_applicable-" + value} className="custom-control-input"></input>
                        <label disabled className="custom-control-label" htmlFor={"non_applicable-" + value}></label>
                    </div>
                </td>
                <td>
                    <div disabled  className="custom-control custom-radio custom-radio-fat text-center">
                        <input disabled checked={key_value === 0} type="radio" value='0' name={value} id={"notions-" + value} className="custom-control-input"></input>
                        <label disabled  className="custom-control-label" htmlFor={"notions-" + value}></label>
                    </div>
                </td>
                <td>
                    <div disabled  className="custom-control custom-radio custom-radio-fat text-center">
                        <input disabled checked={key_value === 1} type="radio" value='1' name={value} id={"confirmed-" + value} className="custom-control-input"></input>
                        <label disabled  className="custom-control-label" htmlFor={"confirmed-" + value}></label>
                    </div>
                </td>
                <td>
                    <div disabled className="custom-control custom-radio custom-radio-fat text-center">
                        <input disabled checked={key_value === 2} type="radio" value='2' name={value} id={"maitrise-" + value} className="custom-control-input"></input>
                        <label disabled className="custom-control-label" htmlFor={"maitrise-" + value}></label>
                    </div>
                </td>
                <td>
                    <div disabled className="custom-control custom-radio custom-radio-fat text-center">
                        <input disabled checked={key_value === 3} value="3" type="radio" name={value} id={"expert-" + value} className="custom-control-input"></input>
                        <label disabled className="custom-control-label" htmlFor={"expert-" + value}></label>
                    </div>
                </td>

            </tr>
        )
    })
    return (
        <tbody>
            {table}
        </tbody>
    )
}

const CompetenceArray = (props) => {
    const { elem } = props;
    return (
        <Table className="mb-5" bordered>
            <TableHead />
            <CompetencesCriteriasMapper criteres={elem.criterias}/>
        </Table>
    )
}

export default CompetenceArray;