import React, { useState, useContext, Fragment } from 'react';
import { Element } from 'react-scroll';
import { FormattedMessage } from 'react-intl';
import { Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { Mutation } from "react-apollo";
import { ExpertContext } from "../../Contexts/ExpertContext";
import { UPSERT_CV } from "./Mutations";
import SubmitButton from './SubmitButton';
import { CVSchema } from './schema.CV';
import { TranslateContextWrapper } from '../../Contexts/TranslateContext.jsx';
import ModificationAlert from '../Alerts/ModificationAlert';
import { ValidationContext } from "../../Contexts/ValidationContext";
import LoadingAlert from "../Alerts/LoadingAlert";
import useCollapse from "react-collapsed";
import { CollapseContext } from "../../Contexts/CollapseContext";
import { scrollTo } from "./utils";
import Select from 'react-select';
import { CheckBoxes } from '../Checkbox/Checkbox';

const array = ["friends", "administration", "institution", "medias", "socialNetworks", "salons", "other"];

const customStyles = {
    control: (provided) => ({
        ...provided,
        border: "2px solid #efefef",
        height: "60px"
    }),
};

const handleChangeSelector = ({ setValues, values }, selected) => {

    setValues({
        ...values,
        identity: {
            ...values.identity,
            discovered: (selected.value === "cancel") ? "" : selected
        }
    })
};

const Selector = ({ translate, ...props }) => {
    let arrayOption = array.map(value => ({ value: value, label: translate.translateMessage("profile.sections.confirmation.howDidYouKnow." + value) }));
    arrayOption.unshift({ value: "cancel", label: "Annuler la sélection" });
    return (
        <div className="mb-4 col-12 col-md-6 pl-0">
            <Select
                styles={customStyles}
                options={arrayOption}
                onChange={(selected) => handleChangeSelector(props, selected)}
                placeholder={translate.translateMessage("profile.sections.confirmation.howDidYouKnow.default")}
                value={(props.values.identity && props.values.identity.discovered) ? props.values.identity.discovered : null}
            />
        </div>
    )
};



//Composant CV
const CVComponent = (props) => {

    const [nameFile, setNameFile] = useState("");
    const { values } = props;

    //on récupère le nom du CV si il est déjà en base 
    const getCVName = () => {
        const { supporting_documents } = values;
        if (supporting_documents) {
            for (let i = 0, count = supporting_documents.length; i < count; i++) {

                if (supporting_documents[i].type === "CV") {
                    const extension = (supporting_documents[i].extension) ? `.${supporting_documents[i].extension}` : "";
                    return `${supporting_documents[i].name}${extension}`;
                }
            }
        }
        return false;
    }
    // retourn le composant de CV
    return (
        <Fragment>

            <Form.Label
                className={
                    //si erreur on surligne en rouge
                    (props.errors.supporting_documents && props.errors.supporting_documents.CV) ? "is-invalid-label"
                        : null
                }
            >
                <p className="mb-0"><FormattedMessage id='profile.sections.CV.subtitle' defaultMessage="Not translated" />*</p>
            </Form.Label>
            <div className="mb-0">
                <p className="mb-0"><FormattedMessage id="profile.sections.CV.advice" defaultMessage="Not translated" /></p>
                <a href="https://europa.eu/europass/eportfolio/screen/profile-wizard?lang=fr" target="_blank" rel="noopener noreferrer">
                    <p> <u><FormattedMessage id={"profile.sections.CV.howTo"} defaultMessage="Not translated" /></u></p>
                </a>
            </div>
            <div className='d-flex align-items-center'>
                <Form.Group>
                    <label htmlFor="fileCV" className="btn btn-signin" style={{ cursor: "pointer" }}>
                        <FormattedMessage id="profile.sections.CV.button" defaultMessage="Not translated" />
                    </label>
                    <p className="mb-0 ml-4">{getCVName() || nameFile || null}</p>
                    <Form.Control
                        type="file"
                        id='fileCV'
                        className='input-file'
                        name="identity.CV"
                        onChange={e => {
                            //une fois qu'on a sélectionné son CV
                            //si le fichier est bien sélectionné
                            if (e.target.files[0]) {
                                //on créé un document
                                const document = { type: "CV", link: "", name: e.target.files[0].name, file: e.target.files[0], number: "", mimetype: e.target.files[0].type };
                                //on créé un tampon du tableau des supportings_documents
                                const newArray = props.values.supporting_documents;
                                let isCV = false;
                                //on parcourt ce tableau
                                for (var i = 0, count = newArray.length; i < count; i++) {
                                    //si un CV est deja dans ce tableau ou le remplace avec le nouveau
                                    if (newArray[i].type === "CV") {
                                        newArray[i] = document;
                                        isCV = true;
                                    }
                                }
                                if (isCV === false) {
                                    newArray.push(document);
                                }
                                props.setValues({ ...values, supporting_documents: newArray })
                                //on set le nouveau nom du CV pour l'afficher
                                setNameFile(e.target.files[0].name)
                            }
                        }}
                        onClick={() => props.setFieldTouched("supporting_documents.CV")}
                        isInvalid={props.errors.supporting_documents && props.errors.supporting_documents.CV}
                    >
                    </Form.Control>
                    <Form.Control.Feedback className="invalid-feedback mt-2">
                        {(props.errors.supporting_documents && props.errors.supporting_documents.CV) ? <p>{props.errors.supporting_documents.CV}</p> : null}
                    </Form.Control.Feedback>
                </Form.Group>
            </div>

        </Fragment>

    )
};

const handleError = ({ graphQLErrors }) => {
    if (Array.isArray(graphQLErrors)) {
        return graphQLErrors[0].message;
    }
};

const CV = (props) => {

    const { state, methods } = useContext(ExpertContext)
    const [alert, openAlert] = useState(false)
    const [alertUpload, openAlertUpload] = useState(false)
    const validationCV = useContext(ValidationContext);
    const collapseContext = useContext(CollapseContext);
    const { getToggleProps, getCollapseProps } = useCollapse({ isOpen: collapseContext.state.CV });

    return (
        <Mutation
            mutation={UPSERT_CV}
            onCompleted={(datas) => {
                openAlert(true);
                if (datas.upsertCV) {
                    methods.setInSupportDocuments(datas.upsertCV);
                }
                validationCV.methods.setValidation({ name: "CV", target: datas.upsertCV.validationCV });
            }}
            onError={handleError}
        >
            {(CVCallback, { error, loading }) => {
                openAlertUpload(loading)

                return (
                    <Formik initialValues={state.expert}
                        enableReinitialize
                        onSubmit={(values) => {
                            const obj = {
                                supporting_documents: values.supporting_documents,
                                notificationActus: values.identity.notificationActus,
                                notificationOffers: values.identity.notificationOffers,
                                criminal_conviction: values.identity.criminal_conviction,
                                discovered: JSON.stringify(values.identity.discovered)
                            }
                            CVCallback({ variables: { input: obj } });
                        }}
                        validationSchema={CVSchema(props.translateContext)}
                        render={formikBag => {
                            return (
                                <Element name="CV">
                                    <ModificationAlert alert={alert} openAlert={openAlert} />
                                    {loading && <LoadingAlert alert={alertUpload} openAlert={openAlertUpload} />}
                                    <div className={(collapseContext.state.CV) ? "profile-container mt-3" : "profile-container underligne mt-3"}>
                                        <div className="row flex-column flex-md-row mb-3">
                                            <div className="col-12 col-md-9 mb-2 mb-md-0">
                                                <div className="d-flex align-items-center justify-content-start">
                                                    <div className="number-menu mr-2 flex-shrink-0">4</div>
                                                    <h1 className="mb-0 px-0"><FormattedMessage id='profile.sections.CV.title' defaultMessage="Not translated" /></h1>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md">
                                                <div className="d-flex justify-content-start justify-content-md-end">
                                                    <button className="btn btn-signin" {...getToggleProps({
                                                        onClick: () => {
                                                            collapseContext.methods.setCollapse("CV", !collapseContext.state.CV);
                                                        }
                                                    })} >
                                                        <FormattedMessage id="general.modify" defaultMessage="Not translated" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <section name="CV" {...getCollapseProps()} onTransitionEnd={(e) => {
                                            for (let values in collapseContext.state) {
                                                if (collapseContext.state[values] === true && e.target.getAttribute('name') === values) {
                                                    e.target.style.height = "";
                                                    scrollTo(values);
                                                }
                                            }
                                        }}>
                                            <Form onSubmit={formikBag.handleSubmit}>
                                                <div className="profile-sections">
                                                    <CVComponent {...formikBag} />
                                                    <div className='row'>
                                                        <div className="col-12">
                                                            <h2 className="title text-uppercase"><FormattedMessage id='profile.sections.confirmation.howDidYouKnow.title' defaultMessage="Not translated" />*</h2>
                                                            <Selector translate={props.translateContext} {...formikBag} />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-12">
                                                            <h2 className="title text-uppercase"><FormattedMessage id='profile.sections.confirmation.notificationsPreferences.title' defaultMessage="Not translated" /></h2>
                                                            <Form.Label
                                                                className={formikBag.errors && formikBag.errors.notificationOffers ? "is-invalid-label" : null}
                                                            >
                                                                <p><FormattedMessage id="profile.sections.confirmation.notificationsPreferences.offers" defaultMessage="Not translated" />*</p>
                                                            </Form.Label>
                                                            <CheckBoxes
                                                                name="notificationOffers"
                                                                {...formikBag}
                                                            />
                                                            <Form.Label className={formikBag.errors && formikBag.errors.notificationActus ? "is-invalid-label" : null}>
                                                                <p><FormattedMessage id="profile.sections.confirmation.notificationsPreferences.actus" defaultMessage="Not translated" />*</p>
                                                            </Form.Label>
                                                            <CheckBoxes
                                                                name="notificationActus"
                                                                {...formikBag}
                                                            />
                                                        </div>
                                                        <div className="col-12">
                                                            <h2 className="title text-uppercase"><FormattedMessage id='profile.sections.confirmation.condamnations.title' defaultMessage="Not translated" />*</h2>
                                                            <Form.Label
                                                                className={formikBag.errors.identity && formikBag.errors.identity.criminal_conviction ? "is-invalid-label" : null}
                                                            >
                                                                <p><FormattedMessage id="profile.sections.confirmation.condamnations.text" defaultMessage="Not translated" /></p>
                                                            </Form.Label>
                                                            <CheckBoxes
                                                                name="criminal_conviction"
                                                                checked={true}
                                                                {...formikBag}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="confirmation-container">
                                                    <SubmitButton {...formikBag} />
                                                </div>
                                            </Form>
                                        </section>
                                    </div>
                                </Element>
                            )
                        }}
                    />
                );
            }}
        </Mutation>
    )
}

export default TranslateContextWrapper(CV);
