import React from "react";
import TemplateEvents from "./Templates/TemplateEvents";

const NoMatch = () => {
    const elems = {};
    elems.img = 'illu_noserp.svg';
    elems.title = 'general.errorPageNotFoundTitle';
    elems.msges = [
        'general.errorPageNotFoundText'
    ];
    elems.buttons = [
        { color: 'white', msge: 'account.backToHome', link: '/' },
    ]
    return (
        <div className="container mb-5">
            <div className="row">
                <div className="col">
                    <TemplateEvents {...elems} />
                </div>
            </div>
        </div>
    )
}

export default NoMatch;