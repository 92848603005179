import client from "../ApolloSetup";
import gql from "graphql-tag"

export const logout = () => {
    localStorage.clear();
    client.mutate({
        mutation: gql`mutation{
            logout{
                description
            }
        }`
    });
}