import React, { useEffect, useContext } from 'react';
import { withCookies } from 'react-cookie';
import client from '../../ApolloSetup';
import { GET_EXPERT_MISSIONS } from "./Query";
import MissionsMenu from './MissionsMenu';
import Candidatures from "./Candidatures";
import Missions from "./Missions";
import { MissionsContext } from "../../Contexts/MissionsContext";
import {Loader} from "../Loader/Loader";

const MissionsPage = (props) => {
    const missionsContext = useContext(MissionsContext)

    const getMissions = async () => {
        try {
            let missions = await client.query({
                query: GET_EXPERT_MISSIONS,
            })
            missionsContext.methods.setMissions(missions.data.getMissionsOfUser)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getMissions();
    }, [missionsContext.missions])

    if (missionsContext.state.loading) return (<div style={{ height: '400px' }}><Loader class="center" /></div>)
    else return (
        <div className="container my-5 missions">
            <div className="row flex-row">
                <div className="col-12 col-lg-4 mb-4 mb-lg-0">
                    <MissionsMenu />
                </div>
                <div className="col-12 col-lg-8">
                    <Candidatures />
                    <Missions />
                </div>
            </div>
        </div>
    )
}

export default withCookies(MissionsPage)