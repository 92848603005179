import React, {useState} from 'react';
import { FormattedMessage } from 'react-intl';
import client from '../../ApolloSetup';
import {RENEW_CONSENT} from '../ExpertProfile/Mutations';

const RenewalNotification = () => {

    const [displayNotif, setDisplayNotif] = useState(true);

    const handleConfirmationRenewalConsent = async () => {
        client.mutate({
            mutation: RENEW_CONSENT,
            fetchPolicy: "no-cache"
        })
        .then(response => setDisplayNotif(false))
    };
    if (!displayNotif) return null;
    return (
        <div className="container-fluid d-flex justify-content-between notifications position-relative mb-4">
            <div className="w-100 d-flex flex-column flex-lg-row justify-content-center align-items-center">
                <img src={require("../../Static/Illustrations/illu_profil.svg")} alt="illu_profile" className="img-fluid mb-4 mb-lg-0 mr-lg-5" />
                <div className="d-flex flex-column">
                    <p className="title text-center text-lg-left">
                        <FormattedMessage id="notifications.renewalConsentTitle" defaultMessage="Not translated"></FormattedMessage>
                    </p>
                    <p className="text text-center text-lg-left mb-3">
                        <FormattedMessage id="notifications.renewalConsentText" defaultMessage="Not translated"></FormattedMessage>
                    </p>
                    <div className="d-flex">
                        <button className='btn btn-civipol' style={{ maxWidth: '300px' }} onClick={handleConfirmationRenewalConsent}>
                            <FormattedMessage id="notifications.renewalConsentButton" defaultMessage="Not translated"></FormattedMessage>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RenewalNotification;