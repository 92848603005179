import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Markup } from 'interweave';
import * as moment from 'moment';

const DescriptionProject = (props) => {

    const { offer } = props;
    const { mission_length } = offer;
    const { project } = offer;
    const { lang } = localStorage;
    if (project) {

        const { title, chronology, introduction_text, link, primary_image, themes } = project;
        let arrayChrono = chronology.split(",");
        let start = arrayChrono[0].replace(/"/g, "");
        let end = arrayChrono[arrayChrono.length - 1].replace(/"/g, "");

        let img = "";
        if (primary_image.fr.length === 0) {
            if (themes.id_taxo === "72") {
                img = require("../../Static/Images/theme-72.jpg")
            }
            else if (themes.id_taxo === "73") {
                img = require("../../Static/Images/theme-73.jpg")
            }
            else if (themes.id_taxo === "75") {
                img = require("../../Static/Images/theme-75.jpg")
            }
        }

        let image = (primary_image.fr.length > 0) ? process.env.REACT_APP_CIVIPOL + primary_image[lang] : img;
        return (
            <div className="description-project" >
                <div className="row mb-5" >
                    <div className="col">
                        <h2 ><FormattedMessage id="offer.aboutProject" defaultMessage="Not translated" /></h2>
                        <div className="encoche-title mb-2"></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-8 mb-5 mb-lg-0">
                        <h2>{(title[lang]) ? title[lang] : <FormattedMessage id="general.notFilled" defaultMessage="Not translated" />}</h2>
                        <div className="mb-5">
                            {introduction_text[lang] ? <Markup content={introduction_text[lang]} /> : <FormattedMessage id="general.notFilled" defaultMessage="Not translated" />}
                        </div>
                        <div className="row flex-row justify-content-center">
                            <a target="_blank" rel="noopener noreferrer" className="d-flex col-6 col-6 din-bold-p justify-content-between align-items-center text-uppercase mb-0 see-on-web-container" style={{ textDecoration: "none" }} href={"http://civipol.fr/" + link[lang]}>
                                <img className="img-fluid" style={{ maxWidth: "90px", minWidth: "90px" }} src={require('../../Static/Illustrations/illu_fiche-projet.svg')} alt="onWebSite" />
                                <FormattedMessage id="offer.seeOnWebSite" defaultMessage="Not translated" />
                            </a>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className="row mb-4">
                            <div className="col">
                                <img className="img-fluid" src={image} alt="img-project" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col description-block-project">
                                <div className="px-3 py-4" style={{ backgroundColor: "#F7F7F7" }}>
                                    <div className="row mb-4">
                                        <div className="col">
                                            <h2><FormattedMessage id="offer.aboutProject" /></h2>
                                            <div className="encoche-title mb-2" />
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col">
                                            <h3 className="text-uppercase"><FormattedMessage id="offer.projectStart" defaultMessage="Not translated" /></h3>
                                            <p>{(start) ? moment(start).format("DD/MM/YYYY") : <FormattedMessage id="general.notFilled" defaultMessage="Not translated" />}</p>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col">
                                            <h3 className="text-uppercase"><FormattedMessage id="offer.projectEnd" defaultMessage="Not translated" /></h3>
                                            <p>{(end) ? moment(end).format("DD/MM/YYYY") : <FormattedMessage id="general.notFilled" defaultMessage="Not translated" />}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <h3 className="text-uppercase"><FormattedMessage id="offer.duration" defaultMessage="Not translated" /></h3>
                                            <p>{(mission_length[lang]) ? mission_length[lang] : <FormattedMessage id="general.notFilled" defaultMessage="Not translated" />}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else return <div><FormattedMessage id="general.notFilled" defaultMessage="Not translated" /></div>
}

export default DescriptionProject;
