import React from "react";
import Select from 'react-select';
import { TranslateContextWrapper } from '../../../Contexts/TranslateContext.jsx';
import * as langs from 'langs';
import { FormattedMessage } from "react-intl";

const translatePath = "profile.sections.abilities.languages.";

const customStyles = {
    control: (provided) => ({
        ...provided,
        border: "2px solid #efefef",
        height: "60px",
        fontFamily: "'HELV-NEUE-REG', sans-serifsans-serif"
    }),

}

const handleSelect = args => (selected) => {
    const { values, setValues, keyElem, select } = args;
    if (selected.value === "cancel") selected.label = null;
    let obj = {
        ...values.languages[keyElem],
        [select]: selected.value
    };
    values.languages[keyElem] = obj;
    setValues(values);
}

const LangueSelection = (props) => {

    const { setValues, keyElem, values, isError } = props;
    const { languages } = values;
    let arrayOptions = [];
    const langues = langs.names(true);
    let valueLangue = null;
    let label = isError ? "is-invalid-label" : "";
    if (languages && languages[keyElem].language && languages[keyElem].language !== "cancel" && languages[keyElem].language !== "") {
        valueLangue = { label: values.languages[keyElem].language, value: values.languages[keyElem].language };
    }
    arrayOptions = langues.map((v) => ({ value: v, label: v }));
    arrayOptions.unshift({ value: "cancel", label: (props.translate) && props.translate("general.cancelSelection") })
    return (
        <div className="col-12 col-md-5 mb-3 mb-md-0">
            <label className={label}>
                <FormattedMessage id={translatePath + 'langue'} defaultMessage="Not translated" />{props.required && "*"}
            </label>
            <Select
                styles={customStyles}
                value={valueLangue}
                options={arrayOptions}
                placeholder={props.translate(`${translatePath}selectLang`)}
                onChange={handleSelect({ values, setValues, keyElem, select: "language" })}
            />
        </div>
    );
}

const LevelSelection = (props) => {

    let arrayOptions = [];
    const levels = ["native", "A1", "A2", "A3", "B1", "B2", "C1", "C2"];
    const { setValues, keyElem, values, isError } = props;
    const { languages } = values;
    let valueLevel = null;
    let label = isError ? "is-invalid-label" : "";

    if (languages && languages[keyElem].level && languages[keyElem].level !== "cancel" && languages[keyElem].level !== "") {
        valueLevel = { label: props.translate(`${translatePath}levelsLang.${values.languages[keyElem].level}`), value: values.languages[keyElem].level };
    }

    arrayOptions = levels.map(v => ({ value: v, label: props.translate(`${translatePath}levelsLang.${v}`) }))
    arrayOptions.unshift({ value: "cancel", label: (props.translate) && props.translate("general.cancelSelection") })
    return (
        <div className="col-12 col-md-5 mb-3 mb-md-0">
            <label className={label}>
                <FormattedMessage id={translatePath + 'europeanLevel'} defaultMessage="Not translated" />{props.required && "*"}
            </label>
            <Select
                styles={customStyles}
                options={arrayOptions}
                value={valueLevel}
                placeholder={props.translate(`${translatePath}selectLevel`)}
                onChange={handleSelect({ values, setValues, keyElem, select: "level" })}
            />
        </div>
    )
}

const AddLanguageAttestation = ({ keyElement, ...formikProps }) => {

    const handleDocumentSelection = (e) => {
        formikProps.setValues({
            ...formikProps.values,
            languages: formikProps.values.languages.map((obj, key) => (key === keyElement) ? {
                ...obj, justificatif: {
                    name: e.target.files[0].name,
                    file: e.target.files[0],
                    mimeType: e.target.files[0].type,
                    size: e.target.files[0].size
                }
            } : obj)
        });
    };

    return (
        <div className="d-flex flex-column align-items-center justify-content-center col-md-2 col-12">
            <label>
                <FormattedMessage id={translatePath + 'justificatif'} defaultMessage="" />
            </label>
            <label className="btn" htmlFor={`langageAttestation-${keyElement}`}>
                <img src={require("../../../Static/Icons/icon_addDoc.svg")} alt="add" />
            </label>
            <input onChange={handleDocumentSelection} type="file" id={`langageAttestation-${keyElement}`} className="input-file" />
        </div>
    );
};

const DisplayLanguageAttestation = (formikProps) => {

    const handleDeleteDocument = (e) => {
        formikProps.setValues({
            ...formikProps.values,
            languages: formikProps.values.languages.map((obj, key) => (key === formikProps.keyElem) ? {
                ...obj,
                justificatif: {
                    name: "",
                    file: null,
                    mimeType: "",
                    size: ""
                }
            } : obj)
        });
    };
    const DeleteButton = () => {
        return (
            <button onClick={handleDeleteDocument} type="button" className="btn">
                <img src={require("../../../Static/Icons/icon_removeDoc.svg")} alt="add" />
            </button>
        );
    }

    return (
        <div className="row flex-row mb-2">
            {(typeof formikProps.values.languages[formikProps.keyElem].justificatif !== "string" && formikProps.values.languages[formikProps.keyElem].justificatif && formikProps.values.languages[formikProps.keyElem].justificatif.file !== null)
                ?
                <div style={{ backgroundColor: "#F7F7F7" }} className="col-6 col-md-12 d-flex justify-content-between align-items-center">
                    <p className="mb-0">{formikProps.values.languages[formikProps.keyElem].justificatif.name}</p>
                    <DeleteButton />
                </div>
                : (typeof formikProps.values.languages[formikProps.keyElem].justificatif === "string" && formikProps.values.languages[formikProps.keyElem].justificatif.length > 0)
                    ?
                    <div style={{ backgroundColor: "#F7F7F7" }} className="col-6 col-md-12 d-flex justify-content-between align-items-center pr-4">
                        <a href={`${process.env.REACT_APP_API_URL}/public/language-justificatifs/${formikProps.values.languages[formikProps.keyElem].justificatif}`}>
                            <FormattedMessage id={translatePath + 'seeJustificatif'} defaultMessage="" />
                        </a>
                        <DeleteButton />
                    </div>
                    :
                    null
            }
        </div>
    );

};

const Optional = ({ optional }) => {
    if (optional) return <small className="grey-small ml-2"><FormattedMessage id="general.optional" defaultMessage="Not translated" /></small>
    else return null;
}

const LanguageComponent = (props) => {

    const { values, keyElem, errors, optional } = props;
    let isInvalid = "";
    let isErrorLevel = null;
    let isErrorLanguage = null;
    let error = null;
    let required = null;

    if (errors && errors.languages) {
        try {
            error = JSON.parse(errors.languages);
            if (error[keyElem].language === true && error[keyElem].level === true && keyElem === 0) {
                isErrorLevel = error[keyElem].level
                isErrorLanguage = error[keyElem].language
                isInvalid = "is-invalid-label form-label";
                required = <FormattedMessage id="validation.required" defaultMessage="Not Translated" />
            }
            if ((error[keyElem].level === true && error[keyElem].language === false) || (error[keyElem].language === true && error[keyElem].level === false)) {
                isErrorLevel = !error[keyElem].level
                isErrorLanguage = !error[keyElem].language
                isInvalid = "is-invalid-label form-label";
            }
        } catch (err) { }
    }

    if (values) {
        const { languages } = values;

        if (languages && languages[keyElem]) {
            return (
                <div className="d-flex flex-column">
                    <div className="d-flex">
                        <p className={isInvalid}> <FormattedMessage id={translatePath + 'langue'} defaultMessage="Not translated" /> {keyElem + 1}</p> <p className="is-invalid">{required}</p>
                        <Optional optional={optional} />
                    </div>
                    <div className="row flex-row justify-content-between align-items-end mb-3">
                        <LangueSelection isError={isErrorLevel} values={languages[keyElem].language} translate={props.translateContext.translateMessage} {...props} required={props.required} />
                        <LevelSelection isError={isErrorLanguage} values={languages[keyElem].level} translate={props.translateContext.translateMessage} {...props} required={props.required} />
                        <AddLanguageAttestation keyElement={keyElem} {...props} />
                    </div>
                    <DisplayLanguageAttestation {...props} />
                </div>
            )
        }
    }
}
export default TranslateContextWrapper(LanguageComponent);