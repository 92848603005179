import React, { useContext, useEffect } from "react";
import { GET_EXPERT_PROFILE } from "./Queries";
import { withCookies } from 'react-cookie';
import { ExpertContext } from "../../Contexts/ExpertContext";
import client from '../../ApolloSetup';
import Menu from '../Menu/Menu';
import { TranslateContextWrapper } from '../../Contexts/TranslateContext.jsx';
import Identity from './Identity/Identity';
import MissionsSearched from './MissionsSearched';
import Abilities from "./Abilities/Abilities";
import CV from './CV';
import DocumentsToUpload from './DocumentsToUpload';
import NotificationComponent from '../Notifications/NotificationsComponents';
import { Loader } from "../Loader/Loader";
import { ValidationContext } from "../../Contexts/ValidationContext";
import CreatedAccountAlert from "../Alerts/CreatedAccountAlert";
import IndividualsInformations from './IndividualsInformations/IndividualsInformations';
//composant qui retourne la page MY_PROFILE
const ExpertProfile = (props) => {
    const { state, methods } = useContext(ExpertContext);
    const validationContext = useContext(ValidationContext);
    //On commence par charger les données qui viennent du serveur
    useEffect(() => {
        methods.resetExpert();
        //On execute la mutation qui permet de recuperer les infos de l'expert
        client.query({ query: GET_EXPERT_PROFILE, fetchPolicy: 'no-cache' }).then((values) => {
            //on stock les variables de validation de chaque section de l'expert
            const { validationIdentity, validationMissionSearched, validationExpertAbilities, validationCV, validationSupportingDocuments, validationConfirmation, validationIndividualInformations } = values.data.getExpert;
            validationContext.methods.setValidation({ name: "identity", target: validationIdentity });
            validationContext.methods.setValidation({ name: "mission_searched", target: validationMissionSearched });
            validationContext.methods.setValidation({ name: "abilities", target: validationExpertAbilities });
            validationContext.methods.setValidation({ name: "CV", target: validationCV });
            validationContext.methods.setValidation({ name: "supporting_documents", target: validationSupportingDocuments });
            validationContext.methods.setValidation({ name: "confirmation", target: validationConfirmation });
            validationContext.methods.setValidation({ name: "individualsInformations", target: validationIndividualInformations });

            //on stocke les variables de l'expert globalement
            methods.getStatus(values.data.getExpert.status);
            methods.getExpert(values.data.getExpert)
        }).catch(err => {
            methods.getError(err)
        })
    }, [])
    //on check si on a bien récupéré les infos du serveur
    if (state.expert) {
        //formulaire de profile de l'expert
        return (
            <div className="container my-5">
                <NotificationComponent expert={state.expert} />
                <CreatedAccountAlert />
                <div className="row flex-row">
                    <div className="col-12 col-lg-4 mb-4 mb-lg-0">
                        <Menu {...state.expert} />
                    </div>
                    <div className="col-12 col-lg-8">
                        <Identity />
                        <MissionsSearched />
                        <Abilities />
                        <CV />
                        {state.expert.wasAcceptedToMission &&
                            <>
                                <IndividualsInformations />
                                <DocumentsToUpload />
                            </>
                        }

                    </div>
                </div>
            </div>
        )
    }

    else if (state.error) return <div>ERROR</div>
    else return (<div style={{ height: '400px' }}><Loader class="center" /></div>)
}

export default TranslateContextWrapper(withCookies(ExpertProfile));
