import React, { useContext } from 'react';
import { FormattedMessage } from "react-intl";
import * as moment from "moment";
import { WhiteButton } from '../Buttons/Buttons';
import { Mutation } from "react-apollo";
import { withCookies } from 'react-cookie';
import DEPOSTULE from "./Mutation";
import { MissionsContext } from "../../Contexts/MissionsContext";

const handleComplete = (datas, missionContext) => {
    missionContext.methods.setMissions(datas.depostule)
}

const CandidatureComponent = ({ id, date_postulat, staffed, offer, allCookies, ...props }) => {

    const { lang } = localStorage;
    const missionContext = useContext(MissionsContext)
    const handleClick = (e, callback) => {
        e.preventDefault();
        try {
            if (id) {
                callback({ variables: { id_mission: id } });
            }
        } catch (err) {
            console.log("error token invalide");
        }
    }
    return (
        <div className="row flex-column">
            <div className="col">
                <h2 style={{fontSize: '25px'}} className="mb-2">{offer.title[lang]}</h2>
                <div className="d-flex justify-content-between align-items-end">
                    <div className="date">
                        <p className="title text-uppercase mb-0"><FormattedMessage id="missions.candidatureDate" defaultMessage="Not translated" /></p>
                        <p className="content mb-0">{moment(date_postulat).format("DD/MM/YYYY")}</p>
                    </div>
                    <Mutation
                        onCompleted={(datas) => {
                            handleComplete(datas, missionContext);
                        }}
                        onError={(error) => { console.log(error) }}
                        mutation={DEPOSTULE}
                    >
                        {(callback, { error, loading }) => {
                            return (
                                <button className="btn btn-signin" onClick={(e) => handleClick(e, callback)} >
                                    <FormattedMessage id="missions.depostuler" defaultMessage="Not translated" />
                                </button>
                            )
                        }}
                    </Mutation>
                    <WhiteButton msge="missions.seeOfferCandidature" link={"offers/" + offer.id} />
                </div>
            </div>
        </div>
    )
}
export default withCookies(CandidatureComponent);
