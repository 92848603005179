import React from 'react';
import { FormattedMessage } from 'react-intl';
import { fileInput } from './IndividualsInformations';
import Selector from '../Selector';
import * as countries from "i18n-iso-countries";

const InvalidFeedBack = ({ formikProps, formKey }) => {
    return (
        <div className="is-invalid">
            <FormattedMessage id={formikProps.errors.personalInformations[formKey]} />
        </div>
    );
};

const handleFile = (e, formikProps, key) => {
    if (e.target && e.target.files[0]) {
        formikProps.setFieldTouched(`personalInformations.${key}`);
        formikProps.setValues({
            ...formikProps.values,
            personalInformations: {
                ...formikProps.values.personalInformations,
                [key]: {
                    name: e.target.files[0].name,
                    file: e.target.files[0],
                    mimeType: e.target.files[0].type,
                    size: e.target.files[0].size
                }
            }
        });
    }
};

const FileGestureComponent = ({
    translationPath,
    isValid,
    formikProps,
    messageKey,
    fileKey
}) => {

    const handleDeleteFile = () => {
        formikProps.setValues({
            ...formikProps.values,
            personalInformations: {
                ...formikProps.values.personalInformations,
                [fileKey]: fileInput
            }
        })
    };
    return (
        <div className="form-group mb-0">
            <label ><FormattedMessage id={`${translationPath}.${fileKey}`} className={!isValid(fileKey) ? "is-invalid" : ""} />*</label>
            {(typeof formikProps.values.personalInformations[fileKey] === 'string' && formikProps.values.personalInformations[fileKey] !== "")
                ?
                <div>
                    <a className="btn btn-signin mr-2" href={`${process.env.REACT_APP_API_URL}/public/personal-informations/${formikProps.values.personalInformations[fileKey]}`}><FormattedMessage id="profile.sections.individualsInformations.personalInformations.seeDocument" defaultMessage="" /></a>
                    <button className="btn btn-signin ml-2" onClick={handleDeleteFile} onBlur={formikProps.handleBlur}><FormattedMessage id="profile.sections.individualsInformations.personalInformations.modifyButton" /></button>
                </div>
                :
                (isValid(fileKey) && formikProps.values.personalInformations[fileKey].file !== null && formikProps.values.personalInformations[fileKey] !== "")
                    // IF FILE SELECTED
                    ?
                    <div className="d-flex flex-column">
                        <p style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "200px"
                        }}
                        >{formikProps.values.personalInformations[fileKey].name}</p>
                        <button className="btn btn-signin" onClick={handleDeleteFile}><FormattedMessage id="profile.sections.individualsInformations.personalInformations.modifyButton" /></button>
                    </div>
                    :
                    // IF NO FILE SELECTED YET
                    <div>
                        <label className="btn btn-signin mb-0" htmlFor={`personalInformations.${fileKey}`}><FormattedMessage id={`${translationPath}.${messageKey}`} /></label>
                        <input id={`personalInformations.${fileKey}`} type="file" className="input-file" name={`personalInformations.${fileKey}`} onChange={(e) => handleFile(e, formikProps, fileKey)} onBlur={formikProps.handleBlur}>
                        </input>
                    </div>
            }
            {/* IF ERROR */}
            {!isValid(fileKey) && <InvalidFeedBack formikProps={formikProps} formKey={fileKey} />}
        </div >
    );
}

const PersonalInformations = ({ formikProps }) => {
    const translationPath = 'profile.sections.individualsInformations.personalInformations.inputs';
    const arrayCountries = Object.values(countries.getNames("fr"));

    const isValid = (value) => (!formikProps.errors || !formikProps.errors.personalInformations || !formikProps.errors.personalInformations[value]) || (!formikProps.touched || !formikProps.touched.personalInformations || !formikProps.touched.personalInformations[value])

    const setValues = (key) => {
        return (formikProps.values.personalInformations[key]) || "";
    };

    return (
        <div className="form mb-4">
            <div className="row flex-row justify-content-around">
                <div className="col-12 col-md-6">
                    <div className="mb-4 form-group">
                        <label htmlFor={`personalInformations.matrimonialName`} className={!isValid('matrimonialName') ? "is-invalid" : ""}><FormattedMessage id={`${translationPath}.matrimonialName`} />*</label>
                        <input className="form-control" name={`personalInformations.matrimonialName`} onChange={formikProps.handleChange} onBlur={formikProps.handleBlur} value={setValues('matrimonialName')}></input>
                        {!isValid('matrimonialName') && <InvalidFeedBack formikProps={formikProps} formKey='matrimonialName' />}
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="mb-4 form-group">
                        <label htmlFor={`personalInformations.placeOfBirth`} className={!isValid('placeOfBirth') ? "is-invalid" : ""}><FormattedMessage id={`${translationPath}.placeOfBirth`} />*</label>
                        <input className="form-control" name={`personalInformations.placeOfBirth`} onChange={formikProps.handleChange} onBlur={formikProps.handleBlur} value={setValues('placeOfBirth')}></input>
                        {!isValid('placeOfBirth') && <InvalidFeedBack formikProps={formikProps} formKey='placeOfBirth' />}
                    </div>
                </div>
            </div>
            <div className="row flex-row justify-content-left">
                <div className="col-12 col-md-6">
                    <div className="mb-4 form-group">
                        <Selector array={arrayCountries} section="personalInformations" name="nativeCountry" path={translationPath.replace('.inputs', '')} {...formikProps} required />
                    </div>
                </div>
            </div>
            <div className="row flex-row justify-content-around">
                <div className="col-12 col-md-6">
                    <div className="mb-4 form-group">
                        <label htmlFor={`personalInformations.passportNumber`} className={!isValid('passportNumber') ? "is-invalid" : ""}><FormattedMessage id={`${translationPath}.passportNumber`} />*</label>
                        <input className="form-control" name={`personalInformations.passportNumber`} onChange={formikProps.handleChange} onBlur={formikProps.handleBlur} value={setValues('passportNumber')}></input>
                        {!isValid('passportNumber') && <InvalidFeedBack formikProps={formikProps} formKey='passportNumber' />}
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="mb-4 form-group">
                        <label htmlFor={`personalInformations.passportPlaceDelivery`} className={!isValid('passportPlaceDelivery') ? "is-invalid" : ""}><FormattedMessage id={`${translationPath}.passportPlaceDelivery`} />*</label>
                        <input className="form-control" name={`personalInformations.passportPlaceDelivery`} onChange={formikProps.handleChange} onBlur={formikProps.handleBlur} value={setValues('passportPlaceDelivery')}></input>
                        {!isValid('passportPlaceDelivery') && <InvalidFeedBack formikProps={formikProps} formKey='passportPlaceDelivery' />}
                    </div>
                </div>
            </div>
            <div className="row flex-row justify-content-around">
                <div className="col-12 col-md-6">
                    <div className="mb-4 form-group">
                        <label htmlFor={`personalInformations.passportExpirationDate`} className={!isValid('passportExpirationDate') ? "is-invalid" : ""}><FormattedMessage id={`${translationPath}.passportExpirationDate`} />*</label>
                        <input
                            name={`personalInformations.passportExpirationDate`}
                            onChange={formikProps.handleChange}
                            type="date"
                            className="date-input"
                            onBlur={formikProps.handleBlur}
                            value={setValues('passportExpirationDate')}
                        />
                        {!isValid('passportExpirationDate') && <InvalidFeedBack formikProps={formikProps} formKey='passportExpirationDate' />}
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <FileGestureComponent translationPath={translationPath} isValid={isValid} formikProps={formikProps} messageKey='importPassportFile' fileKey='passportFile' />
                </div>
            </div>
            <div className="row flex-row justify-content-around">
                <div className="col-12 col-md-6">
                    <div className="mb-4 form-group">
                        <label htmlFor={`personalInformations.socialSecurityNumber`} className={!isValid('socialSecurityNumber') ? "is-invalid" : ""}><FormattedMessage id={`${translationPath}.socialSecurityNumber`} />*</label>
                        <input className="form-control" name={`personalInformations.socialSecurityNumber`} onChange={formikProps.handleChange} onBlur={formikProps.handleBlur} value={setValues('socialSecurityNumber')}></input>
                        {!isValid('socialSecurityNumber') && <InvalidFeedBack formikProps={formikProps} formKey='socialSecurityNumber' />}
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="form-group mb-0">
                        <FileGestureComponent translationPath={translationPath} isValid={isValid} formikProps={formikProps} messageKey='importVitalCardFile' fileKey='vitalCardFile' />
                    </div>
                </div>
            </div>
            <div className="row flex-row justify-content-around">
                <div className="col-12 col-md-6">
                    <div className="mb-4 form-group">
                        <label htmlFor={`personalInformations.ribNumber`} className={!isValid('ribNumber') ? "is-invalid" : ""}><FormattedMessage id={`${translationPath}.ribNumber`} />*</label>
                        <input className="form-control" name={`personalInformations.ribNumber`} onChange={formikProps.handleChange} onBlur={formikProps.handleBlur} value={setValues('ribNumber')}></input>
                        {!isValid('ribNumber') && <InvalidFeedBack formikProps={formikProps} formKey='ribNumber' />}
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="mb-4 form-group">
                        <label htmlFor={`personalInformations.ibanNumber`} className={!isValid('ibanNumber') ? "is-invalid" : ""}><FormattedMessage id={`${translationPath}.ibanNumber`} />*</label>
                        <input className="form-control" name={`personalInformations.ibanNumber`} onChange={formikProps.handleChange} onBlur={formikProps.handleBlur} value={setValues('ibanNumber')}></input>
                        {!isValid('ibanNumber') && <InvalidFeedBack formikProps={formikProps} formKey='ibanNumber' />}
                    </div>
                </div>
            </div>
            <div className="row flex-row justify-content-left">
                <div className="col-12 col-md-6">
                    <div className="form-group mb-0 d-flex flex-column">
                        <FileGestureComponent translationPath={translationPath} isValid={isValid} formikProps={formikProps} messageKey='importRibFile' fileKey='rib' />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PersonalInformations;