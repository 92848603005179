import React from 'react';
import { TranslateContextWrapper } from '../../Contexts/TranslateContext.jsx';
import LogoCivipolBaseExperts from '../LogoCivipolBaseExperts.jsx';
import { FormattedMessage } from 'react-intl';

//header de gauche pour toutes les pages qui on un lien avec l'authentification

const LeftCard = () => {
    return (
        <div className="row flex-row flex-column justify-content-center align-items-start align-items-sm-center py-5">
            <div className="col-12 col-md-10 col-xl-8 mb-5">
                <LogoCivipolBaseExperts />
            </div>
            <div className="col-12 col-md-10 col-xl-8 text-justify">
                <h1 style={{ fontFamily: "DIN-BOLD" }} className=' text-left text-white text-uppercase mb-4 w-75'><FormattedMessage id="account.join" defaultMessage="Not translated"/></h1>
                <p style={{ fontFamily: "Helvetica Neue" }} className='text-white mb-0'><FormattedMessage id="account.description1" defaultMessage="Not translated"/></p>
                <p style={{ fontFamily: "Helvetica Neue" }} className='text-white font-weight-bold mb-5'><FormattedMessage id="account.description3" defaultMessage="Not translated"/></p>
                <p className="text-left mb-0">
                    <a href="https://civipol.fr/" target="_blank"  rel="noopener noreferrer" className='btn btn-civipol'>Découvrir Civipol</a>
                </p>
            </div>
        </div>
    );
}

export default TranslateContextWrapper(LeftCard);
