import * as Scroll from "react-scroll";

let scrollTo = (id) => {
    let scroller = Scroll.scroller;
    scroller.scrollTo(id, {
        duration: 200,
        delay: 200,
        smooth: true,
        offset: -120
    })
}

export {scrollTo};