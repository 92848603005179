import React from "react";
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-scroll';

const MissionsMenu = () => {

    return (
        <div id="padding-menu" className={'sticky-top'}>
            <div id="menu">
                <Link 
                    to="1"
                    id="menu-1"
                    smooth={true}
                    duration={500}
                    spy={true}
                    offset={-200}
                    activeClass="active"
                    className="btn-menu mission d-flex d-flex justify-content-center align-items-center"
                >
                    <span><FormattedMessage id="missions.myCandidatures" defaultMessage="Not translated" /></span>
                </Link>
                <Link 
                    to="2"
                    id="menu-2"
                    smooth={true}
                    duration={500}
                    spy={true}
                    offset={-200}
                    activeClass="active"
                    className="btn-menu mission d-flex  d-flex justify-content-center align-items-center"
                >
                    <span><FormattedMessage id="missions.myMissions" defaultMessage="Not translated" /></span>
                </Link>
            </div>
        </div>
    );
}

export default MissionsMenu;