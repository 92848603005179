import React, {createContext, Component } from 'react';
import {injectIntl} from 'react-intl';

const TranslateContext = createContext({
    lang: 'fr'
})


class TranslateProvider extends Component{
    
    state = {
        intl: this.props.intl,
        translateContext: {
            lang: 'fr',
            translateMessage: this.translateMessage.bind(this),
            translateError: this.translateError
        }
    }

    translateMessage(id, defaultMessage, values){
        const {intl} = this.state;
        defaultMessage = defaultMessage || 'Missing translation';
        return intl.formatMessage({ id, defaultMessage }, values);
    }

    translateError(error){
        const gqlError = error.graphQLErrors[0];
        return this.translateMessage(`errors.${gqlError.extensions.code}`, gqlError.message);
    }
    
    changeLanguage = () => {
        this.setState({lang: (this.state.lang === 'fr') ? 'en' : 'fr'})
    }

    render(){
        const {children} = this.props;
        return(
            <TranslateContext.Provider value={this.state}>
                    {children}
            </TranslateContext.Provider>
        )
    }
}

const TranslateContextWrapper = (Component) => {
    return (props) => {
        return (
            <TranslateContext.Consumer>
                {
                    value => {
                        return (
                            <Component {...props} {...value}/>
                        )
                    }
                }
            </TranslateContext.Consumer>
        )
    }
}

export default injectIntl(TranslateProvider);
export {TranslateContext, TranslateContextWrapper};