import React, { Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { withRouter } from "react-router-dom";

const PrivateRoute = ({
    component: Component,
    ...rest
}) => {
    const { location, match } = rest;
    return (
        <Route {...rest}
            render={
                (props) => {
                    // si le cookie a été set
                    if (location.pathname.indexOf("offer_suggestion") !== -1) {
                        return <Redirect to={
                            {
                                pathname: "/expert/offers/" + match.params.id
                            }
                        } />
                    } else {
                        if (Component) {
                            return (
                                <Fragment>
                                    {/*
                                        //TODO Not activ for prod
                                        <NotificationEngine/> 
                                         */}
                                    <Component {...props} />
                                </Fragment>
                            )
                        } else {
                            return <Redirect to={
                                { pathname: "/expert/my_profile" }
                            } />
                        }
                    }
                }
            } />
    );
}

export default withRouter(withCookies(PrivateRoute));
