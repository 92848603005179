import gql from "graphql-tag";

const GET_EVALUATION_EXPERT_BY_PAGE = gql`
    query getEvaluationByPage($id_mission: ID!) {
        getEvaluationByPage(id_mission: $id_mission) {
            id
            projectCode
            createdAt
            expert {firstName lastName}
            criterias {name value}
            generalEvaluation
            poleChief
            employe {
                login
            }
            mission {
                offer {
                    geographical_zone {fr}
                    title {fr}
                    project {
                        title {
                            fr
                        }
                    }
                }
            }
        }
    }
`;

export {GET_EVALUATION_EXPERT_BY_PAGE};