/**
 * Get a translation for a locale (en/fr), for a nestedMessage. 
 * Warning : this method is recursvie, on sub keys.
 * @param {*} nestedMessages the key for the translated message to retrieve
 * @param {*} prefix (do not use this parameter, it is used on its recursive call)
 * @returns A translation for nestedMessages key 
 * 
 */
export function flattenMessages(nestedMessages, prefix = '') {
    return Object.keys(nestedMessages).reduce((messages, key) => {
        const value = nestedMessages[key];
        const prefixedKey = prefix ? `${prefix}.${key}` : key;

        if (typeof value === 'string') {
            messages[prefixedKey] = value;
        } else {
            Object.assign(messages, flattenMessages(value, prefixedKey));
        }

        return messages;
    }, {});
}


export const messages = {
    'en': {
        general: {
            baseexperts: "Expert database",
            yes: "yes",
            no: "no",
            optional: "Optional",
            visitSite: "Visit the Civipol.fr website",
            modify: "Modify",
            notFilled: "Not filled in",
            errorPageNotFoundTitle: "Error",
            errorPageNotFoundText: "The page you are looking for does not exist",
            errorInternalTitle: "Internal error",
            errorInternalText: "An internal error has occurred. Please try again later or contact Civipol.",
            cancelSelection: "Cancel the selection",
            selector: {
                countryPlaceholder: "Select a country"
            },
            error418: "Our anti-spam verification system returned an error. Please contact Civipol for more information." // About reCaptcha errors
        },
        header: {
            english: "English",
            french: "Français",
            login: "Sign in",
            logout: "Sign out",
            createProfile: "Create my expert profile",
            accountValidation: "Your profiles is being validated",
            accountNotCreated: "Your profiles is not complete",
            menu: {
                my_profile: 'Expert profile',
                missions: 'Missions',
                tools: 'Tools',
                offers: 'Offers',
            },
            titles: {
                my_profile: "Edit my profile",
                missions: "Missions",
                tools: "Tools",
                offers: "Offers",
            }
        },
        footer: {
            contactUs: "Contact us",
            enterpriseSpace: "Civipol space",
            infosDemand: "Export my profile",
            findUs: "Find us on social media:",
        },
        account: {
            email: "Email address",
            password: "Password",
            passwordConfirmation: "Confirm password",
            firstName: "First name",
            lastName: "Last name",
            birthday: "Date of birth",
            birthdayPlaceholder: "{ dateFormat }",
            signin: "Sign in",
            signup: "Create my profile",
            noaccount: "You don’t have an account?",
            noaccount2: "Create my expert profile",
            haveaccount: "I ALREADY HAVE AN ACCOUNT",
            haveaccount2: "You already have an account?",
            join: "Join the Civipol expert database",
            description1: "Civipol is always looking for professionals who are highly qualified, experiences and dedicated, with a varied blend of operational and technical skills.",
            description2: "There are possibilities for junior and senior consultants.",
            description3: "Feel free to set up your profile.",
            discovercivipol: "Discover Civipol",
            forgotPassword: "Forgotten password?",
            reinitPasswordText: "Enter your email address to reset your password.",
            reinitPasswordButton: "Change my password",
            backToHome: "Home",
            oneTryLeftToConnect: "Warning: you have only one chance left to sign in or your account will be temporarily blocked.",
            accountBloquedLogin: "Maximum number of connection attempts reached.Your access has been temporarily blocked.",
            accountBloquedConsent: "Your account is bloqued and can only be unlocked by a civipol employee. Please contact CIVIPOL",
            oldPassword: "Old password",
            newPassword: "New password",
            newPasswordConfirmation: "Confirm new password",
            deleteMyAccount: "Delete my account",
            deleteAccountAlertTitle: "Account deletion",
            deleteAccountAlertText1: "Do you want to delete you Civipol Expert account?",
            deleteAccountAlertText2: "Warning: this action is irreversible. You will no longer be able to access your account and all your data will be lost. Are you sure you want to continue?",

        },
        madification: {
            passwordTitle: "Changes",
            passwordText1: "You have changed your password.",
            passwordText2: "Goodbye – the Civipol team",
            employeeModificationProfileTitle: "Your profile has been changed by Civipol",
            employeeModificationProfileText1: "Here is a list of the changes:",
        },
        validation: {
            confirmationProfileValidatedTitle: "Congratulations!",
            confirmationProfileValidated1: "Your profile has been validated by our team.",
            confirmationProfileValidated2: "Goodbye – Civipol.",


            confirmationProfileCompletedTitle: "Thank you for entering your profile.",
            confirmationProfileCompleted1: "Your profile will now be validate by Civipol.",
            confirmationProfileCompleted2: "Goodbye – Civipol.",

            profileValidatingTitle: "Your profiles is being validated.",
            profileValidatingText1: "Your profile will now be validated by our consultants.",
            profileValidatingText2: "Goodbye – Civipol.",

            confirmPasswordResetTitle: "Your password has been reset.",
            confirmPasswordResetDescription: "You must sign in again by clicking on this link:",



            // reinitPasswordTokenNotValid: "An error has occurred while resetting your password. Please try again by clicking <a href='http://localhost:3000/authentication/reinit_password'>here</a>"
            inscriptionNotActiv: "You did not click on the activation link in the email we sent you.",
            signinError: "The details entered are incorrect.",
            errorFieldEmpty: "Please fill in both fields.",



            confirmationPasswordTitle: "You will shortly receive an email.",
            confirmationPasswordSent1: "We have sent an email to the address given. Click on the link in this email to reset your password.",

            confirmationMailSentTitle: "Confirm your email address",
            confirmationMailSent1: "To verify your email address, we have sent you a message with a confirmation link.",
            confirmationMailSent2: "Your inscription will be validated when you click on the link.",

            required: "This field is required",
            notvalid: "This field is invalid",
            passwordnotvalid: "Your password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one number and one of these special characters: #?!@$%^&* -",
            passwordNotMatching: "The passwords are not the same",
            firstName: "The first name must contain at least 2 character and no numbers",
            lastName: "The last name must contain at least 2 characters and no numbers",
            birthdayFormatting: "The date format in incorrect",
            birthday: "The date of birth must be before { maxBirthDay }",
            userAlreadyExists: "This email address already exists",
            signinEmailNotValid: "Enter a valid email address",
            cgu: "By signing up, you accept our",
            cguLink: "Terms of Use",
            badFormatRequired: "The format is invalid or the file is missing",
            selectAtLeastOne: "Please select at least one language and the corresponding European level",
            fieldNotfilled: "Language or level missing",
            textTooLong: "Maximum number of characters reached.",
        },
        notifications: {
            mailConfirmationTitle: "Thank you for confirming your email address.",
            mailConfirmationText: "You can now enter your profile in order to join our database of Civipol experts.",
            modificationByExpertTitle: "Your profile has been changed.",
            modificationByExpertText: "The changes have been recorded and Civipol now has access to the updated information in our experts database.,",
            uploading: "The information is being saved",
            renewalConsentTitle: "Renouvellement du consentement",
            renewalConsentText: "Merci de vérifier votre profil puis de cliquer sur le boutton ci dessous afin de confirmer que vous accéptez que CIVIPOL conserve vos données personnelles."
        },
        reinit_password: {
            title: "Reset my password",
            newPassword: "New password",
            newPasswordConfirmation: "Confirm the new password",
        },
        profile: {
            identity: "Identity",
            missions: "Missions sought",
            abilities: "Skills",
            individualsInformations: "Individuals Informations",
            CV: "Add a CV",
            documentToUpload: "Supporting documents",
            confirmation: "Confirmation",
            legalMentionLaius: "By clicking, I declare on my honor that the information entered on my profile is correct",
            sections: {
                identity: {
                    title: "Identity",
                    aboutYou: {
                        title: "About You",
                        inputs: {
                            yourFirstName: "First name",
                            yourLastName: "Last name",
                            birthday: "Date of birth",
                            nationality_1: "Nationality 1",
                            nationality_2: "Nationality 2",
                            idPic: "ID photo",
                            male: "Mister",
                            female: "Madam",
                            civility: "Civility",
                            placeholderSelector: "Select a country:",
                        },
                        button: "choose a file",
                    },
                    contactInformations: {
                        title: "Address",
                        firstAddress: {
                            title: "Main address",
                            inputs: {
                                address_1_number_street: "Number and street name",
                                postalCode: "Post code",
                                city: "City",
                                country: "Country"
                            }
                        },
                        secondAddress: {
                            title: "Second address",
                            inputs: {
                                address_2_number_street: "Number and street name",
                                postalCode2: "Post code",
                                city2: "City",
                                country2: "Country"
                            },
                        },
                        buttons: {
                            add: "Add a second address",
                            remove: "Remove second address",
                        },
                        contact: {
                            title: "Contact",
                            inputs: {
                                tel_mobile: "Telephone 1",
                                tel_fix: "Telephone 2",
                                firstEmail: "Email address",
                                secondEmail: "Second email address",
                            }
                        },
                    },
                    personToContact: {
                        title: "Person to contact in case of emergency",
                        inputs: {
                            FirstName: "First name",
                            LastName: "Last name",
                            birthday: "Date of birth",
                            numberAndStreet: "Number and street name",
                            postalCode: "Post code",
                            city: "City",
                            address_country: "Country",
                            telephone: "Telephone",
                            email: "Email address",
                        }
                    }
                },
                missionsSearched: {
                    title: "Missions sought",
                    typeOfMission: {
                        title: "Type of mission",
                        missionDuration: {
                            title: "Length of mission requested",
                            placeholder: "Selection",
                            inputs: {
                                long: "Long term (> 1 year)",
                                medium: "Medium term (> 1 year)",
                                short: "Short term (> 1 month)",
                            }
                        },
                        missionDomain: {
                            title: "Mission field",
                            inputs: {
                                security: "Security",
                                analyse: "Analysis",
                                enseignement: "Teaching",
                                other: "Other",
                            }
                        }
                    }
                },
                abilities: {
                    title: "Skills",
                    languages: {
                        justificatif: "Justificatif",
                        seeJustificatif: "See justificatif",
                        title: "Languages spoken",
                        subtitle: "How do I establish my language level?",
                        subtitleLink: "To the European language standards(Common European Framework of Reference for Languages).",
                        langue: "Language",
                        europeanLevel: "Level",
                        selectLang: "Select a language",
                        selectLevel: "Select a level",
                        nativelLanguage: "Native language",
                        levelsLang: {
                            native: "Native languages",
                            A1: "A1 - Basic",
                            A2: "A2 – Basix",
                            A3: "A3 - Basic",
                            B1: "B1 - Independent",
                            B2: "B2 - Independent",
                            C1: "C1 - Proficient",
                            C2: "C2 - Proficient",
                        },
                        langue1: {
                            title: "Language 1",
                            inputs: {
                                lang1: "Language",
                                level1: "European level",
                            },
                        },
                        langue2: {
                            title: "Language 2",
                            inputs: {
                                lang2: "Language",
                                level2: "European level",
                            }
                        },
                        levels: {
                            0: "A1 - Basic",
                            1: "A2 – Basix",
                            2: "A3 - Basic",
                            3: "B1 - Independent",
                            4: "B2 - Independent",
                            5: "C1 - Proficient",
                            6: "C2 - Proficient",
                        }
                    },
                    abilitiesLevel: {
                        title: "Professional situation",
                        publicAgent: "Public servant",
                        fonctionnaire: "Public service",
                        retired: 'Retired servant',
                        nonFonctionnaire: "Non - public service",
                        errorNoSelect: "Please select an element and the connected items",
                        lastRemuneration: "Last payment",
                        level: "Grade",
                        since: "Since",
                        direction_general: "Body",
                        levels: {
                            SELECT: "Select",
                            RETIRED: "Retired",
                            AP: "A +",
                            A: "A",
                            B: "B",
                            C: "C",
                            OTHER: "Others",
                        },
                        DG: {
                            SELECT: "Select",
                            DGPN: "DGPN",
                            DGGN: "DGGN",
                            DGSCGC: "DGSCGC",
                            DGDDI: "DGDDI",
                            DGEF: "DGEF",
                            DGSI: "DGSI",
                            DGSL: "DGSL",
                            SG: "SG",
                            JUSTICE_MIN: "Ministry of Justice",
                            ECONOMIC_MIN: "Ministry of Economy - finance",
                            DEFENSE_MIN: "Ministry of Defence",
                            FOREIGN_AFF_MIN: "Ministry of Foreign Affairs",
                            FIRST_MIN: "Prime Minister",
                            OTHER: "Others",
                        },
                        typeOfContract: "Contract type",
                        contratSalariale: "Salary contract requested",
                        contratServices: "Service contract requested",
                    },
                    expertiseDomain: {
                        title: "Fields of expertise",
                        error: "You must enter at least one skill on your profile",
                        selectDomain: "Select one or more skills",
                        yearOfExperiences: "Years of experience",
                        tags: {
                            Cadre: "Cadre normatif de l’état civil",
                            CivilState: "Gestion de l’état civil",
                            IDTech: "Technologies de l’identité",
                            Corruption: "Lutte contre la corruption",
                            CriminalJustice: "Justice pénale",
                            FederalPolice: "Police judicaire",
                            PenitentiaryAdministration: "Administration pénitentiaire",
                            ScientificPolice: "Police technique et scientifique",
                            Contrefacon: "Lutte contre la contrefaçon",
                            Fraud: "Lutte contre la fraude",
                            HumanTraffic: "Lutte contre la traite des êtres humains",
                            BorderManagement: "Contrôle des frontières",
                            Immigration: "Lutte contre l'immigration irrégulière",
                            OrganizedCrime: "Lutte contre la criminalité organisée",
                            EconomicCrime: "Lutte contre la criminalité économique",
                            CyberCrime: "Lutte contre la cybercriminalité",
                            PublicOrder: "Ordre public",
                            AviationSafety: "Sureté / sécurité aérienne, portuaire et aéroportuaire",
                            StrongEventsSafety: "Sécurité des grands événements",
                            Crise: "Gestion de crise",
                            Extremism: "Lutte contre l'extrémisme violent",
                            Radicalisation: "Lutte contre la radicalisation",
                            Renseignement: "Renseignement",
                            Financial: "Lutte contre le blanchiment des capitaux et financement du terrorisme",
                            NRBC: "NRBC",
                            MineClearing: "Déminage",
                            CyberTerrorism: "Lutte contre le cyberterrorisme",
                            InfrastructureEngineering: "Ingénierie de protection des infrastructures",
                            AdminFinancialManagement: "Gestion administrative et financière",
                            Communication: "Communication / Presse",
                            TeamManagement: "Encadrement d’équipe",
                            HR: "Ressources Humaines",
                            Research: "Recherche",
                            FormatterTraining: "Formation de formateurs",
                            ProjectCoordiation: "Coordination de projets",
                            TripOrganisation: "Organisation de voyage / évènementiel",
                            Legal: "Juridique",
                            NTIC: "NTIC",
                            Procurement: "Achat / procurement",
                            OffersIngeneering: "Ingénierie d’offres",
                            PublicRelations: "Relations publiques",
                            Traductor: "Interprétariat / Traduction"
                        },
                        diploma: {
                            text: "Certified experience",
                        }
                    },
                    countries: "Country where experience is significant",
                },
                CV: {
                    title: "Add your CV",
                    subtitle: "CV in Europass format",
                    button: "choose a file",
                    advice: "File in Word or PDF formats only",
                    howTo: "How do I create my European format CV?",
                    error_format: "The file format is incorrect",
                    error_miss: "CV is obligatory",
                },
                documentToUpload: {
                    title: "Supporting documents",
                    error: "Please add at least one document",
                    error_2: "The file format in incorrect",
                    subtitle: "You may add supporting documents to your profile",
                    advice: "File formats accepted: Word, PDF, JPG, PNG, BMP",
                    ID: "National identity card",
                    Passport: "Passport",
                    RIB: "Bank details",
                    Diploma: "Diploma",
                    Certificat: "Work certificate",
                    AutorNotCumulActivity: "Authorisation for simultaneous activities",
                    VitalCard: "Social security document",
                    DriveLicence: "Driving licence",
                    KBIS: "Company registration",
                    Siret: "Registered company number",
                    PassportNumber: "Passport Number",
                    VitalNumber: "Vital card number",
                    RCAttestation: "RC attestaion",
                    TransportCertificate: "Transport certificate",
                    MedicalSuitability: "Copy of the last medical aptitude sheet, issued by the occupational medicine during the last 5 years.",
                    SalarySlip: "Copy of the last payslip (if employee).",
                    PensionSlip: "Copy of the last pension form (if retired).",
                    MutualInsuranceCertificat: "Copy of membership in a mutual organization.",
                    ResidencePermit: "Copy of a valid residence permit authorizing you to exercise a professional activity (if foreign worker).",
                    // PassportNumber: "Passport number",
                    // VitalNumber: "Social security number",
                },
                individualsInformations: {
                    title: "Individuals informations",
                    personsToContact: {
                        title: "People to be notified in an emergency",
                        addPerson: "Add a contact",
                        removePerson: "Delete a contact",
                        inputs: {
                            firstName: "Firstname",
                            lastName: "Lastname",
                            relationShip: "Relationship",
                            numberAndStreet: "Number and street name",
                            postalCode: "Zip Code",
                            addressCity: "City",
                            addressCountry: "Country",
                            telephone: "Phone Number",
                            email: "Mail"
                        }
                    },
                    personalInformations: {
                        title: "Peronal Informations",
                        modifyButton: "Modify",
                        seeDocument: "See",
                        inputs: {
                            matrimonialName: "Marital Name",
                            placeOfBirth: "Place of birth",
                            nativeCountry: "Native Country",
                            passportExpirationDate: "Passport expiration date",
                            passportPlaceDelivery: "Passport place delivery",
                            passportNumber: "Passport number",
                            passportFile: "Passport file",
                            importPassportFile: "Upload Passport",
                            rib: "RIB file",
                            importRibFile: "Upload RIB",
                            socialSecurityNumber: "Social security number",
                            vitalCardFile: "File vital card",
                            importVitalCardFile: "Upload vital card",
                            ibanNumber: "IBAN numbers",
                            ribNumber: "RIB numbers"
                        }
                    },
                    medicalInformations: {
                        title: "Medicals Information",
                        inputs: {
                            doctorName: "Doctor name",
                            doctorAddress: "Doctor address",
                            doctorPhoneNumberFix: "Home phone number",
                            doctorCellPhoneNumber: "Cellphone number",
                            doctorEmail: "Mail",
                            bloodGroup: "Blood group",
                            rhesus: "Rhesus",
                            therapeuticsContrindications: "Therapeutics contrindications",
                            allergies: "Allergies",
                            medicalConsent: "By checking this box I agree to send medical information to CIVIPOL. These data are collected in order to ensure my safety during the mission and cannot be used for other purposes."
                        }
                    }
                },
                confirmation: {
                    title: "Confirmation",
                    notificationsPreferences: {
                        title: "Notification preferences",
                        offers: "Receive new job offers by email",
                        actus: "Receive general news about Civipol by email",
                    },
                    howDidYouKnow: {
                        default: "Select an answer",
                        title: "How did you hear about Civipol?",
                        friends: "From friends or colleagues",
                        administration: "Through your original administrative body",
                        institution: "Through the European institutions",
                        medias: "In the media(TV, newspaper, internet)",
                        socialNetworks: "On social networks",
                        salons: "At a Milipol Expo",
                        other: "Other",
                    },
                    condamnations: {
                        title: "Criminal convictions",
                        text: "I solemnly declare that I am not the subject of any criminal conviction, and that I am not the subject of any current criminal procedure.",
                    },
                    infosExacts: {
                        title: "	Correctness of information",
                        text: "	I solemnly declare that the details I have entered in my profile are correct.",
                    },
                    submit: "	Finalise my inscription",
                    submitModification: "	Save changes",
                },
            }
        },
        offers: {
            lookingForOffers: "Search offers",
            inputPlaceHolder: "Find a project, country, etc.",
            search: "Search",
            offersDisponibilities: "{nb_offers} offers available in {nb_projects} projects",
            myMissionTitle: "List of job offers",
            allOffersTitle: "All job offers",
            theme: "{theme}",
            accountGestion: "Account management",
            missionDuration: "Length of mission",
            candidatureLimit: "Final date for submission",
            geoZone: "Geographical zone",
            noResultTitle: "No results correspond to you search criteria",
            noResultText: "To get more results, try changing the key words in you search",
            noResultAllOffersButton: "See all offers",
            backToHome: "Home",
        },
        offer: {
            goBackButton: "Return to offers",
            endCandidature: "Final date for submission",
            contractType: "Type of contract",
            affectationPlace: "Location",
            downLoad: "Download as a PDF",
            postule: "Apply for this position",
            aboutProject: "About the project",
            projectStart: "Start of project",
            projectEnd: "End of project",
            duration: "Duration",
            seeOnWebSite: "See the full project file on the Civipol website",
            offersAssociate: "Job offers for this project",
            alertConfirm: "Confirm",
            alertCancel: "Cancel",
            titleAlert: "Apply for this position",
            textAlert: "Are you sure you want to apply for this position?",
        },
        missions: {
            successPostule: "Your application has been submitted for this mission",
            alreadyPostuled: "You have already applied for this mission",
            dateExpired: "The final date for application for this mission has passed",
            rejected: "This mission has already been filled",
            terminated: "This mission has ended",
            alreadyAgreed: "You have been accepted for this mission",
            alreadySet: "The mission has already been filled",
            depostuler: "Cancel my application",
            myCandidatures: "My applications",
            myMissions: "My missions",
            candidaturesDemands: "You have applied for {nb_demands} job offer{plural} at Civipol",
            candidatureRejected: "Application rejected",
            misssionDesactivated: "The offer has been deactivated",
            seeOfferCandidature: "See the offer",
            missionsPerformed: "You have performed {nb_performed} mission{plural} for Civipol:",
            missionsInProgress: "Current missions",
            missionNotStarted: "Missions accepted but not started",
            missionFinished: "Missions completed",
            seeAllMissions: "See all my missions",
            seeMissionProject: "See the project",
            notificationNoCandidatureTitle: "You have no current applications",
            notificationNoCandidatureText: "To apply, see our job offers",
            notificationNoCandidatureButton: "See job offers",
            notificationNoMissionTitle: "You have not yet performed any missions with Civipol",
            notificationNoMissionText: "Feel free to consult our job offers",
            goBackToMissions: "Return to missions",
            missionStart: "Start of mission",
            missionEnd: "End of mission",
            missionUntied: "Untied:",
            candidatureDate: "Date of your application",
        },
        tools: {
            documentationKit: {
                title: "Documentation Kit",
                text: "",
                allKit: "Download all the kit",
                directory: "Directory",
                mainForm: "Main Form",
                administration: "Administration",
                flowControl: "Flow control",
                identity: "Identity",
                partnership: "Private partnership",
                territorial: "Territorial print",
                organigram: "Organigram",
                activityReport: "Activity report"
            },
            prepareMissions: {
                title: "Prepare my mission",
                arianeText: "Before my departure on a mission, I register on the Ariane website",
                arianeButton: "Access Ariane",
                crisis24Text: "I register on Crissis 24 website.",
                crisis24Button: "Access CRISIS24.com",
                crisis24Text2: "and I download the Crisis messenger application.",
                signalText: "I download the Signal application.",
                signalButton: "Download",
                embassyContactText: "I contact the embassy of the country to which I am going.",
                embassyContactButton: "Access the directory.",
            },
            error: {
                title: "unavailable",
                text: "You have not been accepted or participated in a mission for the moment."
            }
        },
    },

    'fr': {
        general: {
            baseexperts: 'Base Experts',
            yes: "oui",
            no: "non",
            optional: "Facultatif",
            visitSite: "Visiter le site CIVIPOL.fr",
            modify: "Modifier",
            notFilled: "Non renseigné",
            errorPageNotFoundTitle: "Erreur",
            errorPageNotFoundText: "La page que vous demandez n'existe pas.",
            errorInternalTitle: "Erreur Interne",
            errorInternalText: "Une erreur interne s'est produite, merci de réessayer plus tard ou de contacter CIVIPOL.",
            cancelSelection: "Annuler la sélection",
            selector: {
                countryPlaceholder: "Selectionnez un pays"
            },
            error418: "Notre système de vérification anti-spam a renvoyé une erreur. Merci de contacter Civipol pour de plus amples informations." // About reCaptcha errors
        },
        header: {
            english: 'English',
            french: 'Français',
            login: 'Se connecter',
            logout: 'Se déconnecter',
            createProfile: "Créer mon profil expert",
            accountValidation: "Votre profil est en cours de validation",
            accountNotCreated: "Votre profil n'est pas encore complet",
            menu: {
                my_profile: "Profil expert",
                missions: "Missions",
                tools: "Outils",
                offers: "Offres",
            },
            titles: {
                my_profile: "MODIFIER MON PROFIL",
                missions: "MISSIONS",
                tools: "OUTILS",
                offers: "OFFRES"
            }

        },

        footer: {
            contactUs: "Nous contacter",
            enterpriseSpace: "Espace Civipol",
            infosDemand: "Exporter mon profil",
            findUs: "Retrouvez nous sur les réseaux sociaux :"
        },
        account: {
            email: 'Adresse mail',
            password: 'Mot de passe',
            passwordConfirmation: 'Mot de passe (confirmation)',
            firstName: "Votre prénom",
            lastName: "Votre nom",
            birthday: "Date de naissance",
            birthdayPlaceholder: "{dateFormat}",
            signin: 'SE CONNECTER',
            signup: 'CRÉER MON PROFIL',
            noaccount: "VOUS N'AVEZ PAS ENCORE DE COMPTE ?",
            noaccount2: "CRÉER MON PROFIL EXPERT",
            createProfileLaius: "L’accès à cette base experts est réservé aux fonctionnaires retraités, aux fonctionnaires en statut de disponibilité, de détachement et aux personnes issues du secteur privé. Si vous êtes fonctionnaire actif souhaitant vous positionner pour des missions d’expertise à l’étranger, merci de prendre attache avec votre direction d’appartenance.",
            createProfileLaiusConfirmation: "Je confirme que mon profil correspond bien à une de ces trois typologies de profils précisés ci-dessus.",
            cgu: "Je reconnais avoir pris connaissance et accepter les Conditions Générales d'Utilisation.",
            rgpdLaius: "Conformément au règlement général sur la protection des données (RGPD), veuillez nous faire savoir si vous consentez à ce que vos données soient collectées par CIVIPOL en cochant l’option ci-dessous.",
            rgpdCheckbox: "J’accepte que mes données soient collectées par CIVIPOL",
            rgpdLink: "Cliquez sur \"{link_rgp}\" pour plus de détails sur le traitement de vos données par CIVIPOL",
            thisLink: "ce lien",
            haveaccount: "J'AI DÉJÀ UN COMPTE",
            haveaccount2: 'Vous avez déjà un compte ?',
            join: "REJOINDRE LA BASE D'EXPERTS CIVIPOL",
            description1: "CIVIPOL recherche des professionnels hautement qualifiés et expérimentés pour assurer ses missions d'expertise court et long terme dans le cadre de ses projets.",
            description3: "Rejoignez-nous en enregistrant votre profil.",
            discovercivipol: "Découvrir Civipol",
            forgotPassword: "Mot de passe oublié ?",
            reinitPasswordText: "Saisissez votre adresse email pour réinitialiser votre mot de passe.",
            reinitPasswordButton: "Modifier mon mot de passe",
            backToHome: "Revenir à l'accueil",
            oneTryLeftToConnect: "Attention, il ne vous reste plus qu'une tentative pour vous connecter ou votre compte sera bloqué temporairement.",
            accountBloquedLogin: "Nombre de tentatives de connexion atteint, l’accès est temporairement bloqué.",
            oldPassword: "Ancien mot de passe",
            newPassword: "Nouveau mot de passe",
            newPasswordConfirmation: "Confirmation du nouveau mot de passe",
            deleteMyAccount: "Supprimer mon compte",
            deleteAccountAlertTitle: "Suppression du compte",
            deleteAccountAlertText1: "Souhaitez vous supprimer votre compte Expert Civipol ?",
            deleteAccountAlertText2: "Attention cette action est irrévocable, vous ne serez plus en mesure d'accèder a votre compte et perdrez toutes vos données, êtes vous sûr de vouloir continuer ?",
            accountBloquedConsent: "Votre compte expert est bloqué merci de contacter CIVIPOL pour y avoir accès de nouveau."
        },
        modification: {
            passwordTitle: "Modification",
            passwordText1: "Vous avez modifié votre mot de passe.",
            passwordText2: "Bonne journée, l'équipe Civipol",

            employeeModificationProfileTitle: "Votre profil a été modifié par l'équipe Civipol",
            employeeModificationProfileText1: "Voici la liste des modifications :",
        },
        validation: {
            //profile validé par un employé
            confirmationProfileValidatedTitle: "Félicitation !",
            confirmationProfileValidated1: "Votre profil a été validé par notre équipe Civipol.",
            confirmationProfileValidated2: "Bonne journée, l'équipe Civipol.",

            //confirmation profil complété
            confirmationProfileCompletedTitle: "Merci d'avoir complété votre profil !",
            confirmationProfileCompleted1: "Votre profil va maintenant être validé par Civipol.",
            confirmationProfileCompleted2: "Bonne journée, l'équipe Civipol.",

            /**
             * profile en cours de validation
             */
            profileValidatingTitle: "Votre profil est en cours de validation",
            profileValidatingText1: "Votre profil va maintenant être validé par nos conseillers Civipol.",
            profileValidatingText2: "Bonne journée, l'équipe Civipol",
            /**
             * confirmation que le mot de passe est reinitialisé
             */
            confirmPasswordResetTitle: "Votre mot de passe a bien été réinitialisé",
            confirmPasswordResetDescription: "Il est nécessaire de se reconnecter en cliquant sur le lien suivant:",
            /**
             * renitialisation du mot de passe pb de token
             */
            // reinitPasswordTokenNotValid: "Une erreur s'est produite lors de la réinitialisation du mot de passe, merci de réessayer en cliquant <a href='http://localhost:3000/authentication/reinit_password'>ici</a>",

            inscriptionNotActiv: "Vous n'avez pas cliqué sur le lien d'activation du mail que nous vous avons envoyé",
            signinError: "Les informations saisies ne correspondent pas.",
            errorFieldEmpty: "Merci de renseigner les deux champs",
            /**
             * Confirmation Reinit Password
             */
            confirmationPasswordTitle: "Vous allez recevoir un email",
            confirmationPasswordSent1: "Nous venons de vous envoyer un email à l'adresse que vous avez indiqué. Dans ce mail, cliquez sur le lien afin de réinitialiser votre mot de passe.",
            /**
             * Confirmation Email
             */
            confirmationMailSentTitle: "Confirmez votre adresse mail",
            confirmationMailSent1: "Pour vérifier que vous avez bien saisi votre adresse email, nous venons de vous envoyer un message contenant un lien de confirmation.",
            confirmationMailSent2: "Votre inscription sera validée lorsque vous aurez cliqué sur ce lien.",

            required: "Ce champ est requis",
            notvalid: "Ce champ n'est pas valide",
            passwordnotvalid: "Votre mot de passe doit contenir au moins 8 caractères dont au moins une majuscule, une minuscule, un chiffre et un caractère spécial. Les caractères spéciaux autorisés sont #?!@$%^&*-",
            passwordNotMatching: "Les mots de passe ne correspondent pas",
            firstName: "Le prénom doit contenir au moins 2 caractères, sans chiffre",
            lastName: "Le nom doit contenir au moins 2 caractères, sans chiffre",
            birthdayFormatting: "La Date n'est pas au bon format",
            birthday: "La Date de naissance doit être antérieure au {maxBirthDay}",
            userAlreadyExists: "Ce mail existe déjà",
            signinEmailNotValid: "Entrez une adresse email valide",
            badFormatRequired: "Le format est invalide ou le fichier est manquant",
            selectAtLeastOne: "Merci de sélectionner au moins une langue et un niveau européen",
            fieldNotfilled: "Une langue ou niveau n'est pas renseigné",
            textTooLong: "Le nombre de caractères autorisés est atteint.",
            newInscriptionToken: "Nous vous avons envoyé un nouveau mail de confirmation sur votre adresse mail.",
            checkConfirmation: "Vous devez confirmer pour continuer.",
            cguConfirmation: "Vous devez accepter les CGU pour pouvoir continuer.",
            rgpdConfirmation: "Vous devez accepter la collecte de vos données pour pouvoir continuer.",
            fileTooBig: "La taille maximale supportée est de 5Mo.",
            fileInvalidFormat: "Le format du fichier n'est pas valide."
        },
        notifications: {
            title: "Notification",
            titleModification: "Votre profil a été modifié par l'équipe Civipol",
            textModification: "Pour consulter les modifications effectuées, merci de consulter vos mails.",
            mailConfirmationTitle: "Merci d'avoir confirmé votre adresse mail !",
            mailConfirmationText: "Vous pouvez désormais saisir votre profil afin de rejoindre notre base d'experts Civipol.",
            modificationByExpertTitle: "Votre profil a bien été modifié !",
            modificationByExpertText: "Les modifications ont bien été prises en compte et Civipol a accès dès maintenant aux nouvelles informations de votre profil dans notre base experts.",
            uploading: "Les informations sont en cours d'enregistrement",
            accountValidated: "Félicitation! Votre compte à été validé par l'équipe Civipol.",
            candidatureRejected: "Votre candidature n'a pas été retenue pour l'offre suivante: ",
            candidatureAccepted: "Félicitation! Votre candidature a été validée pour l'offre suivante: ",
            renewalConsentTitle: "Renouvellement du consentement",
            renewalConsentText: "Merci de vérifier votre profil puis de cliquer sur le bouton ci-dessous afin de confirmer que vous acceptez que CIVIPOL conserve vos données personnelles.",
            renewalConsentButton: "Je confirme"
        },
        reinit_token: {
            title: "Lien expiré",
            text: "Merci de renouveler votre demande puis de vous rendre sur votre adresse mail pour confirmer votre inscription.",
            button: "Envoyer un nouveau mail",
        },
        reinit_password: {
            title: "Réinitialiser mon mot de passe",
            newPassword: "Nouveau mot de passe",
            newPasswordConfirmation: "Ressaisir le nouveau mot de passe",
        },
        profile: {
            identity: "Identité",
            missions: "Missions recherchées",
            abilities: "Compétences",
            individualsInformations: "Informations individuelles",
            CV: "CV",
            documentToUpload: "Pièces justificatives",
            confirmation: "Confirmation",
            legalMentionLaius: "En cliquant, je déclare sur l'honneur que les informations saisies sur mon profil sont exactes",
            sections: {
                identity: {
                    title: "Identité",
                    aboutYou: {
                        title: "À propos de vous",
                        inputs: {
                            yourFirstName: "Votre prénom",
                            yourLastName: "Votre nom",
                            birthday: "Date de naissance",
                            nationality_1: "Nationalité 1",
                            nationality_2: "Nationalité 2",
                            idPic: "Photo d'identité",
                            placeholderSelector: "Sélectionnez un pays...",
                            male: "Monsieur",
                            female: "Madame",
                            civility: "Civilité",
                            civilityPlaceholder: "Votre civilité"
                        },
                        button: "choisir un fichier",
                    },
                    contactInformations: {
                        title: "Coordonnées",
                        firstAddress: {
                            title: "Adresse principale",
                            inputs: {
                                address_1_number_street: "Numéro et nom de rue",
                                postalCode: "Code postal",
                                city: "Ville",
                                address_1_country: "Pays"
                            }
                        },
                        secondAddress: {
                            title: "Adresse secondaire",
                            inputs: {
                                address_2_number_street: "Numéro et nom de rue",
                                postalCode2: "Code postal",
                                city2: "Ville",
                                address_2_country: "Pays",

                            },
                        },
                        buttons: {
                            add: "ajouter une deuxième adresse",
                            remove: "retirer la deuxième adresse",
                        },
                        contact: {
                            title: "Contact",
                            inputs: {
                                tel_mobile: "Téléphone 1",
                                tel_fix: "Téléphone 2",
                                firstEmail: "Adresse mail",
                                secondEmail: "Adresse mail secondaire",

                            }
                        },
                    },
                    personToContact: {
                        title: "Personne a prévenir en cas d'urgence",
                        inputs: {
                            FirstName: "Prénom",
                            LastName: "Nom",
                            birthday: "Date de naissance",
                            numberAndStreet: "Numéro et nom de rue",
                            postalCode: "Code postal",
                            city: "Ville",
                            address_country: "Pays",
                            telephone: "Téléphone",
                            email: "Adresse mail"
                        }

                    }
                },
                missionsSearched: {
                    title: "Missions recherchées",
                    typeOfMission: {
                        title: "Type de mission",
                        missionDuration: {
                            title: "Durée de la mission souhaitée",
                            placeholder: "Sélection",
                            inputs: {
                                long: "Long terme > 1 an",
                                medium: "Moyen terme < 1 an",
                                short: "Court terme < 1 mois"
                            }
                        },
                        missionDomain: {
                            title: "Domaine de la mission",
                            inputs: {
                                security: "Sécurité",
                                analyse: "Analyse",
                                enseignement: "Enseignement",
                                other: "Autres"
                            }
                        }
                    }
                },
                abilities: {
                    title: "Compétences",
                    languages: {
                        title: "langues maitrisées",
                        justificatif: "Justificatif",
                        seeJustificatif: "Voir le justificatif",
                        subtitle: "Comment déterminer mon niveau de maitrise de la langue?",
                        subtitleLink: "Voir les normes de niveaux européens.",
                        langue: "Langue",
                        europeanLevel: "Niveau européen",
                        selectLang: "Sélectionnez une langue",
                        selectLevel: "Sélectionnez un niveau",
                        nativelLanguage: "Langue maternelle",
                        levelsLang: {
                            native: "Langue maternelle",
                            A1: "A1 - Débutant",
                            A2: "A2 - Débutant",
                            A3: "A3 - Débutant",
                            B1: "B1 - Indépendant",
                            B2: "B2 - Indépendant",
                            C1: "C1 - Avancé",
                            C2: "C2 - Compétent"
                        },

                        langue1: {
                            title: "Langue 1",
                            inputs: {
                                lang1: "Langue",
                                level1: "Niveau européen",
                            },
                        },
                        langue2: {
                            title: "Langue 2",
                            inputs: {
                                lang2: "Langue",
                                level2: "Niveau européen",
                            }
                        },
                        levels: {
                            0: "A1 - Débutant",
                            1: "A2 - Débutant",
                            2: "A3 - Débutant",
                            3: "B1 - Indépendant",
                            4: "B2 - Indépendant",
                            5: "C1 - Avancé",
                            6: "C2 - Compétent"
                        }
                    },
                    abilitiesLevel: {
                        title: " SITUATION PROFESSIONNELLE",
                        publicAgent: "Agent de la fonction publique ?",
                        fonctionnaire: "Fonctionnaire en disponibilité/détachement",
                        retired: "Fonctionnaire retraité",
                        nonFonctionnaire: "Non fonction publique",
                        errorNoSelect: "Merci de sélectionner un élément et ses sous sélections",
                        lastRemuneration: "Derniere rémunération",
                        level: "Grade",
                        direction_general: "Entité",
                        retiredSince: "Retraité depuis",
                        since: "Depuis",
                        levels: {
                            SELECT: "Sélectionnez",
                            RETIRED: "Retraité",
                            AP: "A+",
                            A: "A",
                            B: "B",
                            C: "C",
                            OTHER: "Autres",
                        },
                        DG: {
                            SELECT: "Sélectionnez",
                            DGPN: "DGPN",
                            DGGN: "DGGN",
                            DGSCGC: "DGSCGC",
                            DGDDI: "DGDDI",
                            DGEF: "DGEF",
                            DGSI: "DGSI",
                            DGSL: "DGSL",
                            SG: "SG",
                            JUSTICE_MIN: "Ministère justice",
                            ECONOMIC_MIN: "Ministère économie finance",
                            DEFENSE_MIN: "Ministère défense",
                            FOREIGN_AFF_MIN: "Ministère affaires étrangères",
                            FIRST_MIN: "Premier ministère",
                            OTHER: "Autres",
                        },
                        typeOfContract: "Type de contractualisation souhaité",
                        contratSalariale: "Contrat de travail",
                        contratServices: "Contrat de prestation",
                    },
                    expertiseDomain: {
                        title: "Domaines d'expertise",
                        error: "Vous devez ajouter au moins une compétence à votre profil",
                        selectDomain: "Sélectionnez une ou plusieurs compétences :",
                        yearOfExperiences: "Années d'expériences :",
                        tags: {
                            Cadre: "Cadre normatif de l’état civil",
                            CivilState: "Gestion de l’état civil",
                            IDTech: "Technologies de l’identité",
                            Corruption: "Lutte contre la corruption",
                            CriminalJustice: "Justice pénale",
                            FederalPolice: "Police judicaire",
                            PenitentiaryAdministration: "Administration pénitentiaire",
                            ScientificPolice: "Police technique et scientifique",
                            Contrefacon: "Lutte contre la contrefaçon",
                            Fraud: "Lutte contre la fraude",
                            HumanTraffic: "Lutte contre la traite des êtres humains",
                            BorderManagement: "Contrôle des frontières",
                            Immigration: "Lutte contre l'immigration irrégulière",
                            OrganizedCrime: "Lutte contre la criminalité organisée",
                            EconomicCrime: "Lutte contre la criminalité économique",
                            CyberCrime: "Lutte contre la cybercriminalité",
                            PublicOrder: "Ordre public",
                            AviationSafety: "Sureté / sécurité aérienne, portuaire et aéroportuaire",
                            StrongEventsSafety: "Sécurité des grands événements",
                            Crise: "Gestion de crise",
                            Extremism: "Lutte contre l'extrémisme violent",
                            Radicalisation: "Lutte contre la radicalisation",
                            Renseignement: "Renseignement",
                            Financial: "Lutte contre le blanchiment des capitaux et financement du terrorisme",
                            NRBC: "NRBC",
                            MineClearing: "Déminage",
                            CyberTerrorism: "Lutte contre le cyberterrorisme",
                            InfrastructureEngineering: "Ingénierie de protection des infrastructures",
                            AdminFinancialManagement: "Gestion administrative et financière",
                            Communication: "Communication / Presse",
                            TeamManagement: "Encadrement d’équipe",
                            HR: "Ressources Humaines",
                            Research: "Recherche",
                            FormatterTraining: "Formation de formateurs",
                            ProjectCoordiation: "Coordination de projets",
                            TripOrganisation: "Organisation de voyage / évènementiel",
                            Legal: "Juridique",
                            NTIC: "NTIC",
                            Procurement: "Achat / procurement",
                            OffersIngeneering: "Ingénierie d’offres",
                            PublicRelations: "Relations publiques",
                            Traductor: "Interprétariat / Traduction"
                        },
                        diploma: {
                            text: "Expérience validée par un diplôme",
                        }
                    },
                    countries: "Pays où l'expérience est significative :"
                },
                CV: {
                    title: "CV",
                    subtitle: "Curriculum Vitae au format Europass",
                    button: "choisir un fichier",
                    advice: "Fichier au format Word (.doc ou .docx) ou PDF. (5Mo maximum)",
                    howTo: "Le CV doit être au format Europass",
                    error_format: "Le format du fichier n'est pas correct",
                    error_size: "Le fichier est trop lourd, la limite est de 5Mo.",
                    error_miss: "Le CV est obligatoire"
                },
                documentToUpload: {
                    title: "Pièces justificatives",
                    error_nbMax: "Nombre de fichiers maximum atteint",
                    error: "Merci d'ajouter au moins une pièce justificative",
                    error_2: "Le format d'un fichier n'est pas correct",
                    subtitle: "Vous devez ajouter des pièces justificatives à votre profil :",
                    advice: "Format de fichiers acceptés: Word, PDF, JPG, PNG, BMP",
                    ID: "CNI",
                    Passport: "Passeport",
                    RIB: "RIB",
                    Diploma: "Diplôme",
                    Certificat: "Certificat de travail",
                    AutorNotCumulActivity: "Autorisation de cumul d'activités",
                    VitalCard: "Attestation de carte vitale",
                    DriveLicence: "Permis de conduire",
                    KBIS: "KBIS",
                    Siret: "Siret",
                    PassportNumber: "Numéro de passeport",
                    VitalNumber: "Numéro de carte vitale",
                    RCAttestation: "Attestation RC",
                    TransportCertificate: "Copie du titre de transport (Navigo...)",
                    MedicalSuitability: "Copie de la dernière fiche d'aptitude médicale, délivrée par la médecine du travail au cours des 5 dernières années.",
                    SalarySlip: "Copie du dernier bulletin de salaire (si salarié).",
                    PensionSlip: "Copie du dernier bulletin de pension (si retraité).",
                    MutualInsuranceCertificat: "Copie de l'affiliation à un organisme de mutuelle.",
                    ResidencePermit: "Copie d'un titre de séjour en cours de validité vous autorisant à exercer une activité professionnelle (si travailleur étranger).",
                    error_size: "Le fichier est trop lourd, la limite est de 5Mo.",
                },
                individualsInformations: {
                    title: "Informations individuelles",
                    personsToContact: {
                        title: "Personne a prévenir en cas d'urgence",
                        addPerson: "Ajouter une personne",
                        removePerson: "Supprimer une personne",
                        inputs: {
                            firstName: "Prénom",
                            lastName: "Nom",
                            relationShip: "Lien de parenté",
                            numberAndStreet: "Numéro et nom de rue",
                            postalCode: "Code postal",
                            addressCity: "Ville",
                            addressCountry: "Pays",
                            telephone: "Numéro de téléphone",
                            email: "Email"
                        }
                    },
                    personalInformations: {
                        title: "Informations complémentaires",
                        modifyButton: "Modifier",
                        seeDocument: "Voir",
                        inputs: {
                            matrimonialName: "Nom matrimonial",
                            placeOfBirth: "Commune de naissance",
                            nativeCountry: "Pays de naissance",
                            passportExpirationDate: "Date d'expiration du passeport",
                            passportPlaceDelivery: "Lieu de délivrance du passeport",
                            passportNumber: "Numéro de passeport",
                            passportFile: "Fichier Passeport",
                            importPassportFile: "Importer le passeport",
                            rib: "Ficher RIB",
                            importRibFile: "Importer le RIB",
                            socialSecurityNumber: "Numéro sociale",
                            vitalCardFile: "Fichier carte vitale",
                            importVitalCardFile: "Importer la carte vitale",
                            ibanNumber: "Numéro IBAN",
                            ribNumber: "Numéro de RIB"
                        }
                    },
                    medicalInformations: {
                        title: "Informations médicales",
                        inputs: {
                            doctorName: "Nom du médecin traitant",
                            doctorAddress: "Adresse",
                            doctorPhoneNumberFix: "Numéro de téléphone fixe",
                            doctorCellPhoneNumber: "Numéro de télephone mobile",
                            doctorEmail: "Email",
                            bloodGroup: "Groupe sanguin",
                            rhesus: "Rhésus",
                            therapeuticsContrindications: "Contre-indications médicales",
                            allergies: "Allergies",
                            medicalConsent: "En cochant cette case j'accepte de transmettre des informations médicales à CIVIPOL. Ces données sont collectées dans le but d'assurer ma sécurité lors de la mission et ne peuvent être utilisées pour d'autres finalités."
                        }
                    }
                },
                confirmation: {
                    title: "Confirmation",
                    notificationsPreferences: {
                        title: "Préférences de notification",
                        offers: "Recevoir les nouvelles offres d'emplois par mail",
                        actus: "Recevoir les actualités générales de CIVIPOL par mail",
                    },
                    howDidYouKnow: {
                        default: "Sélectionnez une réponse",
                        title: "Comment avez vous connu CIVIPOL",
                        friends: "Par des proches, des collègues",
                        administration: "Par votre administration d’origine",
                        institution: "Par les institutions européennes",
                        medias: "Par les médias (TV, journaux, internet)",
                        socialNetworks: "Via les réseaux sociaux",
                        salons: "Par les salons MILIPOL",
                        other: "Autre",
                    },
                    condamnations: {
                        title: "Condamnation pénale",
                        text: "Je déclare sur l'honneur ne pas être soumis à une condamnation pénale et ne suis sujet également à aucune procédure pénale en cours"
                    },
                    infosExacts: {
                        title: "Exactitude des informations",
                        text: "Je déclare sur l'honneur que les informations saisies sur mon profil sont exactes."
                    },
                    submit: "Finaliser mon inscription",
                    submitModification: "Enregistrer les modifications"
                },
            }
        },
        offers: {
            lookingForOffers: "Rechercher dans les offres",
            inputPlaceHolder: "Rechercher un projet, un pays, etc.",
            search: "Rechercher",
            offersDisponibilities: "{nb_offers} offre{plural_offer} disponible{plural_offer} dans {nb_projects} projet{plural_project}",
            myMissionTitle: "Liste des offres d'emploi",
            allOffersTitle: "Toutes les offres d'emploi",
            theme: "{theme}",
            accountGestion: "Gestionnaire de compte",
            missionDuration: "Durée de la mission",
            candidatureLimit: "Limite de candidature",
            geoZone: "Zone géographique",
            noResultTitle: "Aucun résultat ne correspond à votre recherche",
            noResultText: "Pour obtenir davantage de résultats, essayez de modifier les mots clés recherchés",
            noResultAllOffersButton: "Voir toutes les offres",
            backToHome: "Retourner a l'accueil",
        },
        offer: {
            goBackButton: "Revenir aux offres",
            endCandidature: "Date de fin de candidature",
            contractType: "Type de contrat",
            affectationPlace: "Lieu d'affectation",
            downLoad: "Télécharger en PDF",
            postule: "Postuler à cette offre",
            aboutProject: "A propos du projet",
            projectStart: "Début du projet",
            projectEnd: "Fin du projet",
            duration: "Durée",
            seeOnWebSite: "Voir la fiche projet complète sur le site internet de civipol",
            offersAssociate: "Les offres d'emploi pour ce projet",
            alertConfirm: "Confirmer",
            alertCancel: "Annuler",
            titleAlert: "Postuler à cette offre",
            textAlert: "Êtes vous sûr de vouloir postuler à cette offre ?"

        },
        missions: {
            successPostule: "Vous avez bien postulé à cette mission",
            alreadyPostuled: "Vous avez déjà postulé à cette mission",
            dateExpired: "La date d'expiration pour cette mission est dépassée",
            rejected: "Votre candidature a déjà été rejetée pour cette mission",
            terminated: "Cette mission est terminée",
            alreadyAgreed: "Vous avez été accepté pour cette mission",
            alreadySet: "La mission a déjà été pourvue",
            depostuler: "Annuler ma candidature",
            myCandidatures: "Mes candidatures",
            myMissions: "Mes missions",
            candidaturesDemands: "Vous avez postulé à {nb_demands} offre{plural} d'emploi Civipol :",
            candidatureRejected: "Candidature rejetée",
            misssionDesactivated: "L'annonce a été désactivée",
            seeOfferCandidature: "Voir l'annonce",
            missionsPerformed: "Vous avez effectué {nb_performed} mission{plural} auprès de Civipol :",
            missionsInProgress: "Missions en cours",
            missionNotStarted: "Missions acceptées mais pas démarrées",
            missionFinished: "Missions terminées",
            seeAllMissions: "Voir toutes mes missions",
            seeMissionProject: "Voir le projet",
            notificationNoCandidatureTitle: "Vous n'avez aucune candidature en cours",
            notificationNoCandidatureText: "Pour postuler, rendez-vous sur nos offres d'emploi",
            notificationNoCandidatureButton: "Voir les offres d'emploi",
            notificationNoMissionTitle: "Vous n'avez pas encore réalisé de mission avec Civipol",
            notificationNoMissionText: "N'hésitez pas à consulter nos offres d'emploi",
            goBackToMissions: "Revenir aux missions",
            missionStart: "Début de la mission",
            missionEnd: "Fin de la mission",
            missionUntied: "Détaché le",
            candidatureDate: "Date de votre candidature",
        },
        evaluationExpert: {
            title: "Évaluation",
            evaluationOf: "Evaluation de {firstName_expert} {lastName_expert}",
            projectTitle: "Projet : ",
            projet: "{project_name} du {start_date} au {end_date}",
            offerTitle: "Offre : ",
            offer: "{offer_name}",
            dateEvaluationTitle: "Date de l'évaluation :",
            dateEvaluation: "{date_evaluation}",
            competencesTitle: "Compétences requises",
            aptitudesTitle: "Aptitudes requises",
            generalTitle: "Appréciation générale",
            chiefPole: "Chef de pole :",
            projectCode: "Code projet interne :",
            projectResponsable: "Responsable de projet :",
            endOfPeriod: "Date de fin de période d'essai :",
            endOfPeriodReniew: "Date de fin de période d'essai renouvelée :",
            country: "Lieu d'intervention :",
            linkToOffer: "Lien vers l'offre d'emploi :",
            competencesCriterias: {
                technicalAbilities: "Techniques",
                administrativGestion: "Gestion administrative",
                financialGestion: "Gestion financière",
                deliverable: "Livrable",
                pedagogy: "Pédagogie"
            },
            aptitudesCriterias: {
                communication: "Communication : aptitude à informer et rendre compte de l'avancée du projet auprès du client et du responsable projet",
                organisation: "Sens de l'organisation : aptitude à planifier et à respecter les échéances qui lui sont dévolues, et à gérer les aléas",
                teamSpirit: "Esprit d'équipe : capacité à collaborer avec les autres pour promouvoir la réussite du projet",
                senseOfHearing: "Sens de l'écoute : capacité à prendre en compte objectivement des avis différents",
                exemplarity: "Exemplarité : adopte un comportement en tout point conforme aux valeurs de l'opérateur (probité, éthique, dignité)"
            },
            notations: {
                nonApplicable: "Non applicable",
                notions: "Insuffisant",
                confirmed: "À améliorer",
                maitrise: "Satisfaisant",
                expert: "Point fort",
            },
            generalNotations: {
                ameliorable: "À améliorer",
                conform: "Conforme aux objectifs du projet",
                depasse: "Au delà des objectifs",
            },
            registerButton: "Enregistrer"
        },
        evaluations: {
            seeEvaluations: "Voir les évaluations",
        },
        tools: {
            documentationKit: {
                title: "Kit de documentation",
                text: "",
                allKit: "Télécharger tout le kit",
                directory: "Annuaire",
                mainForm: "Fiche principale",
                administration: "Administration",
                flowControl: "Contrôle des flux",
                identity: "Identité",
                partnership: "Partenaires privés",
                territorial: "Empreinte territoriale",
                organigram: "Organigramme",
                activityReport: "Rapport d'activité"
            },
            prepareMissions: {
                title: "Préparer sa mission",
                arianeText: "Avant mon départ en mission, je m'inscris sur le site d'Ariane",
                arianeButton: "Accéder à Ariane",
                crisis24Text: "Je m'inscris sur le site Crissis 24",
                crisis24Button: "Accéder à CRISIS24.com",
                crisis24Text2: "et je télécharge l'application Crisis messenger.",
                signalText: "Je télécharge l'application Signal",
                signalButton: "Je télécharge l'application Signal",
                embassyContactText: "Je prends contact avec l'ambassade du pays dans lequel je pars",
                embassyContactButton: "Accèder à l'annuaire",
            },
            error: {
                title: "Indisponible",
                text: "Vous n'avez pas été accepté ou participé à une mission pour le moment."
            }
        },
        errors: {
            101: "Aucun utilisateur avec cette adresse email",
            102: "L'adresse email et le mot de passe ne correspondent pas",
        }
    },
};
