import React, { useState } from 'react';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';

/**
 * Schema to validate datas input
 */

const emailSchema = ({ translateMessage }) => {
    const email = Yup.string()
        .email(translateMessage('validation.notvalid'))
        .required(translateMessage('validation.required'));

    return Yup.object({
        email,
    })
}

/**
 *  mutation which takes the email to change paswd
 */
const UPDATE_PASSWORD = gql`
    mutation updatePassword($email: String!){
        updatePasswordEmail(email: $email)
    }
`;

/**
 * Form to enter the mail to change pswd, if error returns a formated messqge else redirect to authentication/email_password page
 */


const BackToHome = () => (
    <p className="text-left" style={{ fontFamily: "DIN-BOLD" }}>
        <Link to="/authentication/signin" className="btn btn-signin text-center">
            <img src={require("../../Static/Icons/icon-chevron.svg")} alt="precedent" className="mr-2 pb-1" />
            <FormattedMessage id="account.backToHome" defaultMessage="Not translated" />
        </Link>
    </p>
);

const TitleBlock = () => (
    <div id='title-block' className="account d-flex justify-content-center align-items-center">
        <h2 style={{ fontFamily: "DIN-BOLD" }} className="text-uppercase text-center text-white font-weight-bold mb-0">
            <FormattedMessage id="account.forgotPassword" defaultMessage="Not translated" />
        </h2>
    </div>
)

export const ForgottenPassword = ({ translateContext, ...props }) => {
    const [errorSubmit, setErrorSubmit] = useState(null)
    return ((window.location.pathname === "/authentication/reinit_password") ?
        <div>
            <BackToHome />
            <TitleBlock />
            <div id='login-form' className="mb-4">
                <div className="form">
                    <Mutation
                        mutation={UPDATE_PASSWORD}
                    >
                        {(reinitPassword) => {
                            return (
                                <Formik
                                    initialValues={{
                                        email: "",
                                    }}
                                    validationSchema={emailSchema(translateContext)}
                                    onSubmit={(values) => {
                                        reinitPassword({ variables: values })
                                        props.history.push('/authentication/email_password')
                                    }}
                                    render={({ handleChange, values, errors, handleSubmit, validateForm }) => {
                                        return (
                                            <Form className="px-4 px-sm-5 py-5 mb-0" noValidate onSubmit={handleSubmit}>
                                                <label><FormattedMessage className='text-left mb-4' id='account.reinitPasswordText' defaultMessage="Not translated" /></label>
                                                <Form.Label className={errorSubmit && errorSubmit.email && errors.email ? "is-invalid-label" : null} ><FormattedMessage id='account.email' defaultMessage="Not translated" /></Form.Label>
                                                <Form.Control
                                                    type='email'
                                                    name='email'
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    isInvalid={errors.email}
                                                    className='form-group mb-5'
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                                <p className="text-center mt-3">
                                                    <button
                                                        type="submit"
                                                        className={'btn btn-block btn-civipol btn-auth-block'}
                                                        onClick={() => validateForm().then((error) => setErrorSubmit(error))}
                                                    >
                                                        <FormattedMessage id='account.reinitPasswordButton' defaultMessage="Not translated" />
                                                    </button>
                                                </p>
                                            </Form>
                                        )
                                    }}
                                />
                            )
                        }}
                    </Mutation>
                </div>
            </div>
        </div> :
        null
    )
}
