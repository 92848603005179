import React from 'react';

const TitlePage = (props) => {
    const { offer } = props;
    const { job_expertise, title } = offer;
    const { lang } = localStorage;
    let logos;
    if (job_expertise.id_taxo === '51') {
        logos = require("../../Static/Illustrations/flux.svg")
    }
    else if (job_expertise.id_taxo === '50')
        logos = require("../../Static/Illustrations/identity.svg")
    else if (job_expertise.id_taxo === '106')
        logos = require("../../Static/Illustrations/territorial_footprint.svg")
    return (
        <div className="row mb-4">
            <div className='col'>
                <div className="d-flex py-4 title-container flex-column justify-content-center align-items-center">
                    <img className="mb-4 img-fluid" style={{ maxWidth: "120px", maxHeight: "120px" }} src={logos} alt="illu-projet" />
                    <span className='theme-container mb-4'> {job_expertise[lang]} </span>
                    <h1 className='mb-0 text-uppercase'>{title[lang]}</h1>
                </div>
            </div>
        </div>
    )
}

export default TitlePage;
