import React, { Fragment, useEffect, useContext } from 'react';
import TitlePage from "./TitlePage";
import DescriptionOffre from './DescriptionOffre';
import DescriptionProject from "./DescriptionProject";
import ProposalsOffers from './ProposalsOffersOffrePage';
import { GET_OFFER } from '../Offres/queries';
import client from '../../ApolloSetup';
import { OffersContext } from '../../Contexts/OffersContext';
import { Loader } from "../Loader/Loader";
import { Redirect } from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";

const getAll = (id, methods) => {

    try {
        client.query({
            query: GET_OFFER,
            variables: {
                id: id
            }
        }).then((values) => {
            if (values.errors) {
                methods.setError()
            }
            else {
                methods.setOffer(values.data.getOffer);
            }
        })

    } catch (err) {
        console.log(err)
    }
}

const Offre = (props) => {

    const { state, methods } = useContext(OffersContext);
    const { id } = props.match.params;
    useEffect(() => {
        getAll(id, methods);

    }, [id])

    if (state.error) return <Redirect to="/not_found" />
    else if (state.loadingOffer) return <div><Loader class="center" /></div>
    else if (state.offer && state.offer.project) {
        return (
            <Fragment>
                <div className="offre">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <p className="text-left" style={{ fontFamily: "DIN-BOLD" }}>
                                    <Link to="/expert/offers" className="btn btn-signin text-center">
                                        <img src={require("../../Static/Icons/icon-chevron.svg")} alt="precedent" className="mr-2 pb-1" />
                                        <FormattedMessage id="account.backToHome" defaultMessage="Not translated" />
                                    </Link>
                                </p>
                                <TitlePage offer={state.offer} />
                                <DescriptionOffre offer={state.offer} />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ backgroundColor: "#EBEBEB" }} className="mb-5">
                    <div className="container py-5 offre ">
                        <DescriptionProject offer={state.offer} />
                    </div>
                </div>
                <div className="container mb-5">
                    <div className="row">
                        <div className="col">
                            {(state.offer.project.offers.length > 1) && <ProposalsOffers offer={state.offer} />}
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    } else return null;
}

export default Offre;
