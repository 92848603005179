import React, { createContext, useReducer } from "react";

const baseMenu = "header.menu."
const baseTitles = "header.titles."
const baseLink = "/expert/";

const initialState = {
    menu: {
        MY_PROFILE: baseMenu + "my_profile",
        MISSIONS: baseMenu + "missions",
        OFFERS: baseMenu + "offers",
        OUTILS: baseMenu + "tools",
    },
    liens: {
        MY_PROFILE: baseLink + "my_profile",
        MISSIONS: baseLink + "missions",
        OFFERS: baseLink + "offers",
        OUTILS: baseLink + "tools",
    },
    titles: {
        MY_PROFILE: baseTitles + "my_profile",
        MISSIONS: baseTitles + "missions",
        OFFERS: baseTitles + "offers",
        OUTILS: baseTitles + "tools",
    },
    title: baseTitles + 'myProfile',
    status: null,
}

const HeaderContext = createContext(initialState);

const reducer = (state, action) => {
    const {target} = action
    switch (action.type) {
        case 'handleTitle':{
            return {
                ...state,
                title: target
            }
        }
        default:
            return state
    }
}

const HeaderProvider = ({ children }) => {

    const [state, dispatch] = useReducer(reducer, initialState)

    const handleTitle = (value) => {
        dispatch({type: "handleTitle", target: value})
    }

    return (
        <HeaderContext.Provider
            value={{
                state,
                methods: {
                    handleTitle: handleTitle
                }
            }}
        >
            {children}
        </HeaderContext.Provider>
    )
}

export { HeaderProvider, HeaderContext };