import React from 'react';
import { FormattedMessage } from 'react-intl';

const SubmitButton = (props) => {

    return (
        <div className="register-modification">
            <p className="mb-4">
                <FormattedMessage id="profile.legalMentionLaius" defaultMessage=""/>
            </p>
            <button
                type="submit"
                className='btn btn-civipol'
                onClick={e => { props.validateForm() }}
            >
                <FormattedMessage id="profile.sections.confirmation.submitModification" defaultMessage="Not translated" />
            </button>
        </div>
    )
}

export default SubmitButton;