import React from "react";
import { withRouter, Link } from "react-router-dom";

const handleClick = () => {
    window.scrollTo(0, 0);
}


const Pagination = (props) => {

    let page = parseInt(props.match.params.page);
    let { arrayEvaluations } = props.store.state;

    const PrevButton = () => {
        return (
            <li className={(page > 1) ? "d-flex align-items-center page-item previous" : "d-flex align-items-center page-item previous disabled"}>
                <Link onClick={handleClick} className="page-link page-link-arrow previous" to={`${page - 1}`}></Link>
            </li>
        )
    }

    const NexButton = () => {
        return (
            <li className={(page === arrayEvaluations.length) ? " d-flex align-items-center page-item next disabled" : "d-flex align-items-center page-item next"}>
                <Link onClick={handleClick} className="page-link page-link-arrow next" to={`${page + 1}`}></Link>
            </li>
        )
    }

    const PaginationButtons = () => {
        let table = [];
        for (let i = 1; i <= arrayEvaluations.length; i++) {
            table.push(
                <li key={i} className={(parseInt(props.match.params.page) === i) ? "page-item active" : "page-item"}>
                    <Link onClick={handleClick} className="page-link" to={`${i}`}>{i}</Link>
                </li>
            )
        }
        return table;
    }


    return (
        <footer className="mt-5">
            <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                    <PrevButton />
                    <PaginationButtons />
                    <NexButton />
                </ul>
            </nav>
        </footer >
    )
}

export default withRouter(Pagination);