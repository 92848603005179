import React from "react";
import { FormattedMessage } from "react-intl";
import { WhiteButton } from "../Buttons/Buttons";

const Notification = ({ title, text, type }) => {
    let illustration = "";
    if (type === "missions") {
        illustration = require("../../Static/Illustrations/illu_missions.svg")
    } else {
        illustration = require("../../Static/Illustrations/illu_candidatures.svg")
    }

    return (
        <div className="col">
            <div className="d-flex justify-content-between notifications position-relative mb-4">
                <div className="w-100 d-flex flex-column flex-lg-row justify-content-center align-items-center">
                    <img src={illustration} alt="illu_mission" style={{maxHeight: "150px", maxWidth: "150px"}} className="img-fluid mb-4 mb-lg-0 mr-lg-5" />
                    <div className="d-flex flex-column align-items-center align-items-lg-start">
                        <div className="mb-4">
                            <p className="title text-center text-lg-left">
                                <FormattedMessage id={title} defaultMessage="Not translated"></FormattedMessage>
                            </p>
                            <p className="text text-center text-lg-left mb-0">
                                <FormattedMessage id={text} defaultMessage="Not translated"></FormattedMessage>
                            </p>
                        </div>
                        <WhiteButton msge="missions.notificationNoCandidatureButton" link="offers" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Notification;
