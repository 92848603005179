import React from 'react';
import { FormattedMessage } from 'react-intl';
import Toast from 'react-bootstrap/Toast'

const ModificationAlert = ({ alert, openAlert }) => {
    if (alert) {
        return (
            <Toast
                show={alert}
                autohide={true}
                delay={3000}
                onClose={() => openAlert(false)}
                className="toast-notif top-right"
            >
                <div className="d-flex px-1 py-1" >
                    <div className="px-2 py-2">
                        <img src={require("../../Static/Illustrations/illu_profil-edited.svg")} style={{ height: "50px", width: "50px" }} alt="illu_profile" className="img-fluid" />
                    </div>
                    <div className="d-flex flex-column px-2 py-2">
                        <div className="d-flex justify-content-between">
                            <strong className="title text-left text-lg-left">MODIFICATION</strong>
                            <button onClick={() => openAlert(false)} type="button" className="close" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <p className="mb-0 mt-2">
                            <FormattedMessage id="notifications.modificationByExpertTitle" defaultMessage="Not translated"></FormattedMessage>
                        </p>
                    </div>

                </div>
            </Toast>
        )
    } else return null;
}

export default ModificationAlert;