import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Mutation } from "react-apollo";
import { Markup } from 'interweave';
import * as moment from 'moment';
import POSTULE from './Mutation';
import { withCookies } from 'react-cookie';
import LoadingAlert from "../Alerts/LoadingAlert";
import SuccessPostuleAlert from "../Alerts/postuleAlert.success";
import ErrorPostuleAlert from "../Alerts/postuleAlert.error";
import SweetAlert from "react-bootstrap-sweetalert";
import { TranslateContextWrapper } from "../../Contexts/TranslateContext";

const DescriptionOffre = (props) => {

    const { id, introduction_text, description_text, profile_text, geographical_zone, application_expiration, id_drupal, project } = props.offer;
    const { contract_type } = project;
    const [alertUpload, openAlertUpload] = useState(false);
    const [alertSuccess, openAlertSuccess] = useState(false);
    const [alertPostule, openAlertPostule] = useState(false);
    const [alertError, openAlertError] = useState(false);
    const { lang } = localStorage;

    let confirm = props.translateContext.translateMessage("offer.alertConfirm");
    let cancel = props.translateContext.translateMessage("offer.alertCancel");
    let url = process.env.REACT_APP_CIVIPOL_NODE;

    const handlePostule = (callback) => {
        try {
            openAlertPostule(false)
            callback({ variables: { id_offer: id } });
        } catch (err) {
            console.log("description offre => ", err);
        }
    }

    return (
        <div className="row mb-5">
            <LoadingAlert alert={alertUpload} openAlert={openAlertUpload} />
            <SuccessPostuleAlert alert={alertSuccess} openAlert={openAlertSuccess} />
            <div className="col-12 col-lg-8 mb-5 mb-lg-0">
                <div className="mb-5">
                    <Markup content={introduction_text[lang]} />
                    <Markup content={description_text[lang]} />
                    <Markup content={profile_text[lang]} />
                </div>
                <div className="d-flex justify-content-center">
                    <Mutation
                        onCompleted={(datas) => {
                            openAlertSuccess(true);
                        }}
                        onError={(datas) => openAlertError(datas)}
                        mutation={POSTULE}
                    >
                        {(callback, { error, loading }) => {
                            return (
                                <div>
                                    {(alertPostule) &&
                                        <SweetAlert
                                            onConfirm={() => handlePostule(callback)}
                                            onCancel={() => openAlertPostule(false)}
                                            title=""
                                            btnSize="sm"
                                            confirmBtnText={confirm}
                                            confirmBtnCssClass="btn btn-civipol"
                                            cancelBtnText={cancel}
                                            cancelBtnCssClass="btn btn-signin"
                                            showCancel={true}
                                            closeOnClickOutside={true}
                                        >
                                            <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center">
                                                <img src={require("../../Static/Illustrations/illu_offres-demploi.svg")} alt="illu_profile" className="img-fluid mb-4 mb-lg-0 mr-lg-5" />
                                                <div className="d-flex flex-column">
                                                    <p className="title font-weight-bold text-center text-lg-left">
                                                        <FormattedMessage id="offer.titleAlert" defaultMessage="Not translated"></FormattedMessage>
                                                    </p>
                                                    <p className="text text-center text-lg-left mb-0">
                                                        <FormattedMessage id="offer.textAlert" defaultMessage="Not translated"></FormattedMessage>
                                                    </p>
                                                </div>
                                            </div>
                                        </SweetAlert>
                                    }
                                    <ErrorPostuleAlert alert={alertError} openAlert={openAlertError} msgeError={error} />
                                </div>
                            )
                        }}
                    </Mutation>
                </div>
            </div>
            <div className="col-12 col-lg-4 h-50 sticky-top" id="menu-offer">
                <div className="row">
                    <div className="col description-block-offer mb-4">
                        <div className="px-3 py-4" style={{ backgroundColor: "#F7F7F7" }}>
                            <div className="row mb-4">
                                <div className="col">
                                    <h2 className="text-uppercase"><FormattedMessage id="offer.endCandidature" defaultMessage="Not translated" /></h2>
                                    <p>{moment(application_expiration.replace(/"/g, "")).format("DD/MM/YYYY")}</p>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col">
                                    <h2 className="text-uppercase"><FormattedMessage id="offer.contractType" defaultMessage="Not translated" /></h2>
                                    <p>{contract_type[lang]}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <h2 className="text-uppercase"><FormattedMessage id="offer.affectationPlace" defaultMessage="Not translated" /></h2>
                                    <p>{geographical_zone[lang]}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col download-pdf">
                        <a style={{ textDecoration: "none", backgroundColor: "#F7F7F7" }} href={url + id_drupal} className="d-flex align-items-center py-3 px-4">
                            <img src={require('../../Static/Illustrations/download-pdf.svg')} alt="download" />
                            <p className="text-uppercase mb-0"><FormattedMessage id="offer.downLoad" defaultMessage="Not translated" /></p>
                        </a>
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    <button onClick={() => openAlertPostule(true)} className="btn btn-civipol">
                        <FormattedMessage id="offer.postule" defaultMessage="Not translated" />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default TranslateContextWrapper(withCookies(DescriptionOffre));
