import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { ValidationContext } from "../../Contexts/ValidationContext";
import { CollapseContext } from "../../Contexts/CollapseContext";

const circleNewStyle = {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    background: 'red',
};

const Menu = (expertState) => {
    const validationContext = useContext(ValidationContext);
    const collapseContext = useContext(CollapseContext);

    const handleClick = async (section) => {
        await collapseContext.methods.setCollapse(section, !collapseContext.state[section]);
    };
    console.log(validationContext)

    return (
        <div id="padding-menu" className={'sticky-top'}>
            <div id="menu">
                <div className={(collapseContext.state.identity) ? "btn-menu d-flex justify-content-between active" : "btn-menu d-flex justify-content-between"} onClick={() => handleClick("identity")}>
                    <div className="d-flex align-items-center">
                        <div className={(collapseContext.state.identity) ? "number-menu flex-shrink-0 active-number" : "number-menu flex-shrink-0"}>1</div>
                        <FormattedMessage id="profile.identity" defaultMessage="Not translated" />
                    </div>
                    <div className="flex-shrink-0">
                        {validationContext.state.identity && <img src={(collapseContext.state.identity) ? require("../../Static/Icons/icon_filled-section_current-done.svg") : require("../../Static/Icons/icon_filled-section_done.svg")} alt='check' />}
                    </div>
                </div>
                <div className={(collapseContext.state.missionSearched) ? "btn-menu d-flex justify-content-between active" : "btn-menu d-flex justify-content-between"} onClick={() => handleClick("missionSearched")}>
                    <div className="d-flex align-items-center">
                        <div className={(collapseContext.state.missionSearched) ? "number-menu flex-shrink-0 active-number" : "number-menu flex-shrink-0"}>2</div>
                        <FormattedMessage id="profile.missions" defaultMessage="Not translated" />
                    </div>
                    <div className="flex-shrink-0">
                        {validationContext.state.mission_searched && <img src={(collapseContext.state.missionSearched) ? require("../../Static/Icons/icon_filled-section_current-done.svg") : require("../../Static/Icons/icon_filled-section_done.svg")} alt='check' />}
                    </div>
                </div>
                <div className={(collapseContext.state.abilities) ? "btn-menu d-flex justify-content-between active" : "btn-menu d-flex justify-content-between"} onClick={() => handleClick("abilities")}>
                    <div className="d-flex align-items-center">
                        <div className={(collapseContext.state.abilities) ? "number-menu flex-shrink-0 active-number" : "number-menu flex-shrink-0"}>3</div>
                        <FormattedMessage id="profile.abilities" defaultMessage="Not translated" />
                    </div>
                    <div className="flex-shrink-0">
                        {validationContext.state.abilities && <img src={(collapseContext.state.abilities) ? require("../../Static/Icons/icon_filled-section_current-done.svg") : require("../../Static/Icons/icon_filled-section_done.svg")} alt='check' />}
                    </div>
                </div>
                <div className={(collapseContext.state.CV) ? "btn-menu d-flex justify-content-between active" : "btn-menu d-flex justify-content-between"} onClick={() => handleClick("CV")}>
                    <div className="d-flex align-items-center">
                        <div className={(collapseContext.state.CV) ? "number-menu flex-shrink-0 active-number" : "number-menu flex-shrink-0"}>4</div>
                        <FormattedMessage id="profile.CV" defaultMessage="Not translated" />
                    </div>
                    <div className="flex-shrink-0">
                        {validationContext.state.CV && <img src={(collapseContext.state.CV) ? require("../../Static/Icons/icon_filled-section_current-done.svg") : require("../../Static/Icons/icon_filled-section_done.svg")} alt='check' />}
                    </div>
                </div>
                {expertState.wasAcceptedToMission &&
                    <>
                        <div className={(collapseContext.state.individualsInformations) ? "btn-menu d-flex justify-content-between active" : "btn-menu d-flex justify-content-between"} onClick={() => handleClick("individualsInformations")}>
                            <div className="d-flex align-items-center">
                                <div className={(collapseContext.state.individualsInformations) ? "number-menu flex-shrink-0 active-number" : "number-menu flex-shrink-0"}>5</div>
                                <div style={{ fontWeight: "bold" }} className='mr-3'>
                                    <FormattedMessage id="profile.individualsInformations" defaultMessage="Not translated" />
                                </div>
                                {
                                  !validationContext.state.individualsInformations && <div style={circleNewStyle}></div>
                                }
                            </div>
                            <div className="flex-shrink-0">
                                {validationContext.state.individualsInformations && <img src={(collapseContext.state.individualsInformations) ? require("../../Static/Icons/icon_filled-section_current-done.svg") : require("../../Static/Icons/icon_filled-section_done.svg")} alt='check' />}
                            </div>
                        </div>
                        <div className={(collapseContext.state.supporting_documents) ? "btn-menu d-flex justify-content-between active" : "btn-menu d-flex justify-content-between"} onClick={() => handleClick("supporting_documents")}>
                            <div className="d-flex align-items-center">
                                <div className={(collapseContext.state.supporting_documents) ? "number-menu flex-shrink-0 active-number" : "number-menu flex-shrink-0"}>6</div>
                                <div style={{ fontWeight: "bold" }} className='mr-3'>
                                    <FormattedMessage id="profile.documentToUpload" defaultMessage="Not translated" />
                                </div>
                                {
                                  !validationContext.state.supporting_documents && <div style={circleNewStyle}></div>
                                }
                            </div>
                            <div className="flex-shrink-0">
                                {validationContext.state.supporting_documents && <img src={(collapseContext.state.supporting_documents) ? require("../../Static/Icons/icon_filled-section_current-done.svg") : require("../../Static/Icons/icon_filled-section_done.svg")} alt='check' />}
                            </div>
                        </div>
                    </>
                }
            </div >
        </div>
    );
}

export default Menu;
