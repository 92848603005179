import * as yup from 'yup';
import * as moment from 'moment';

const authenticationSchemas = ({ translateMessage }) => {

    const maxBirthDay = moment().subtract(18, 'years');
    const invalidDate = new Date();
    const nameRegex = /^[\w'\-.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{0,}$/;


    const email_1 = yup.string()
        .email(translateMessage('validation.notvalid'))
        .required(translateMessage('validation.required'));
    const password = yup.string()
        .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/, translateMessage('validation.passwordnotvalid'))
        .required(translateMessage('validation.required'));
    const passwordConfirmation = yup.string()
        .equals([yup.ref('password')], translateMessage('validation.passwordNotMatching'))
        .required(translateMessage('validation.required'));
    const firstName = yup.string()
        .matches(nameRegex, translateMessage('validation.firstName'))
        .required(translateMessage('validation.required'));
    const lastName = yup.string()
        .matches(nameRegex, translateMessage('validation.lastName'))
        .required(translateMessage('validation.required'));
    const birthday = yup.date()
        .typeError(translateMessage('validation.notvalid'))
        .max(maxBirthDay.toDate(), translateMessage('validation.birthday', null, { maxBirthDay: maxBirthDay.format("L") }))
        .required(translateMessage('validation.required'))
        .transform((value, originalValue) => {
            value = moment(originalValue, moment.localeData().longDateFormat('L'));
            return value.isValid() ? value.toDate() : invalidDate;
        });
    const statusConfirmation = yup.bool()
        .test('statusConfirmation', translateMessage('validation.checkConfirmation'), (value) => value)
        .required(translateMessage('validation.required'));
    const cguConfirmation = yup.bool()
        .test('cguConfirmation', translateMessage('validation.cguConfirmation'), (value) => value)
        .required(translateMessage('validation.required'));
    const rgpdConfirmation = yup.bool()
        .test('rgpdConfirmation', translateMessage('validation.rgpdConfirmation'), (value) => value)
        .required(translateMessage('validation.required'));

    return yup.object({
        email_1,
        password,
        passwordConfirmation,
        firstName,
        lastName,
        birthday,
        statusConfirmation,
        cguConfirmation,
        rgpdConfirmation
    });
}

const signinSchema = ({ translateMessage }) => {
    const name = yup.string()
        .min(1, 'Too Short!')
        .max(70, 'Too Long!')
        .required('Required')
    const email = yup.string()
        .email(translateMessage('account.signinEmailNotValid'))
        .required('Required')

    return {
        name,
        email
    }
}

export { signinSchema, authenticationSchemas };
