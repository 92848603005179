import React, { Fragment, useState, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Mutation } from 'react-apollo';
import { useLazyQuery } from '@apollo/react-hooks';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { SIGNIN_MUTATION } from './Mutations/Authentications';
import { withCookies } from 'react-cookie';
import ScrollUpButton from 'react-scroll-up-button';
import { ExpertContext } from "../../Contexts/ExpertContext";
import { GET_OFFER } from '../Offres/queries';

/**
 * Schema pour confirmer la connection
 */
const signinSchema = ({ translateMessage }) => {
  const password = Yup.string()
    .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/, translateMessage('validation.passwordnotvalid'))
    .required(translateMessage('validation.required'));

  const email_1 = Yup.string()
    .email(translateMessage('validation.signinEmailNotValid'))
    .required(translateMessage('validation.required'));

  return Yup.object({
    password,
    email_1
  })
}

/**
 * handler d'erreurs pour dispatcher les erreurs retournées par le serveur
 */

const ErrorHandler = (error) => {

  let errorMessage = error.graphQLErrors.map((value, i) => {
    // if errors are mutlitples
    if (Object.getOwnPropertyNames(value.extensions).length !== 0) {
      let errorReturn = [];
      if (value.extensions.code && value.extensions.code.inner) {

        errorReturn = value.extensions.code.inner.map(({ message, path }, i) => (
          <div className="is-invalid font-italic mb-3" key={i}>
            {(path === "birthday" && localStorage['lang'] === 'en') ? path : "Date de naissance"}:
            <FormattedMessage id={message} defaultMessage="Not translated" />
          </div>
        ))
      }
      return (
        errorReturn
      )
    } else {
      // if there is only on response
      let errorReturn;
      errorReturn = value.message;
      return (
        <div className="is-invalid font-italic mb-3" key={i}>
          <FormattedMessage id={errorReturn} defaultMessage="Not translated" />
        </div>
      );
    }
  })
  return errorMessage;
}

//si la mutation s'est bien executée on créé un token et on redirige vers la page my_profile
const onCompleted = async ({ history, cookies, location, match }, datas, methods, setError, getOffer) => {
  const { login } = datas;

  //on regarde le status de l'utilisateur 

  const status = ["CREATED", "COMPLETE"];
  if (status.includes(login.expert.status)) {
    methods.getStatus(login.expert.status);
    if (location.pathname.indexOf("authentication/signin/offer_suggestion") !== -1) {
      history.push('/expert/offers/' + match.params.id);
    } else if (location.pathname.indexOf("authentication/signin/evaluation_expert") !== -1) {
      history.push('/evaluations/' + match.params.id + "/page/" + match.params.page);
    } else if (location.pathname.indexOf("authentication/signin/offer_postulat") !== -1) {
        await getOffer({variables: {id: match.params.id}});
    } else {
      history.push('/expert/my_profile');
    }
  } else {
    if (login.expert.status === 'BLOCKED') {
      setError({
        accountBlocked: true
      });
    }
  }
};

//formulaire de connection
const SigninFormOpen = ({ translate, ...props }) => {

  const [errorSubmit, setError] = useState(null);
  const [inputAdvices, setInputAdvices] = useState(null);
  const { methods } = useContext(ExpertContext);
  const [getOffer, {called, data}] = useLazyQuery(GET_OFFER);

  if (called && data) {
      if (data.getOffer.id) {
        props.history.push('/expert/offers/' + data.getOffer.id);
      } else {
        props.history.push('/not_found');
      }
    }
  

  return (
    <div className="form">
      <Mutation
        // mutation a executer lorsqu'on soumet le formulaire
        mutation={SIGNIN_MUTATION}

        // lorsque la mutation est executée on connecte l'utilisateur en global et on execute la fonction ci dessus onCompleted
        onCompleted={async (datas) => {
          await onCompleted(props, datas, methods, setError, getOffer)
        }}
      >
        {(signin, { loading, error }) => {
          return (
            <Formik

              //formik attend des valeurs initiales par défault
              initialValues={{
                email_1: '',
                password: '',
              }}
              //on appel le schemas de validation
              validationSchema={signinSchema(translate)}
              // lors de la soumission on passe les variables nécessaires a la mutation
              onSubmit={(values) => {
                signin({ variables: values })
              }}
              render={({ handleChange, values, errors, handleSubmit, validateForm }) => {
                return (
                  <Form className="px-4 px-sm-5 py-5 mb-0" noValidate onSubmit={handleSubmit}>
                    <Form.Label
                      className={errorSubmit && errorSubmit.email_1 && errors.email_1 ? "is-invalid-label" : null}
                    ><FormattedMessage id='account.email' defaultMessage="Not translated" /></Form.Label>
                    <Form.Control
                      type="email"
                      name="email_1"
                      value={values.email_1}
                      onChange={handleChange}
                      onClick={() => setInputAdvices(false)}
                      className={"form-group " + (errorSubmit && errorSubmit.email_1 ? null : "mb-4")}
                      maxLength="50"
                    />
                    <p className="invalid-feedback" >{errorSubmit && errorSubmit.email_1 ? errors.email_1 : null}</p>
                    <Form.Label
                      className={errorSubmit && errorSubmit.password && errors.password ? "is-invalid-label" : null}
                    ><FormattedMessage id='account.password' defaultMessage="Not translated" /></Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      maxLength="50"
                      onClick={() => {
                        if (errorSubmit && errorSubmit.password) {
                          delete errorSubmit.password
                        }
                        setInputAdvices(true)
                      }}
                      className={"form-group " + (errorSubmit && errorSubmit.email_1 ? null : "mb-4")}
                    />
                    {inputAdvices ? <p className='password-advice'><FormattedMessage id='validation.passwordnotvalid' defaultMessage="Not translated" /></p> : null}
                    <p className={"invalid-feedback "}>{errorSubmit && errorSubmit.password ? errors.password : null}</p>
                    {error && <ErrorHandler  {...error} />}
                    {errorSubmit && errorSubmit.accountBlocked && <div className="is-invalid-label"><FormattedMessage id="account.accountBloquedConsent" /></div>}
                    <button
                      type="submit"
                      className={'btn btn-civipol btn-block btn-auth-block'}
                      onClick={() => validateForm().then((value) => {
                        setInputAdvices(false);
                        setError(value);
                      })}
                    >
                      <FormattedMessage id='account.signin' defaultMessage="Not translated" />
                    </button>
                    <ScrollUpButton
                      ContainerClassName="text-center mt-4 mb-1 font-weight-bold"
                      StopPosition={0}
                    >
                      <p className="text-center mt-4 mb-0 font-weight-bold">
                        <u><Link to="/authentication/reinit_password"><FormattedMessage id='account.forgotPassword' defaultMessage="Not translated" /></Link></u>
                      </p>
                    </ScrollUpButton>
                  </Form>
                )
              }}
            >
            </Formik>
          )
        }}
      </Mutation>
    </div>

  )
};

//composant qui gère l'affichage du formulaire d'inscription
const Signin = ({ ...props }) => {
  //si on est bien sur la page d'inscription on l'affiche
  return ((window.location.pathname.indexOf("/authentication/signin") !== -1) ?
    <Fragment>
      <div id='login-form' className="mb-4">
        <div id='title-block' className="account d-flex justify-content-center align-items-center">
          <h2 style={{ fontFamily: "DIN-BOLD" }} className="text-center text-white font-weight-bold mb-0">
            <FormattedMessage id="account.haveaccount" defaultMessage="Not translated" />
          </h2>
        </div>
        <SigninFormOpen translate={props.translateContext} {...props} />
      </div>
      <div id="create-account" className="py-4">
        <div className="px-3 mb-5">
          <h2 className="text-uppercase text-center text-white mb-0">
            <FormattedMessage id="account.noaccount" defaultMessage="Not translated" />
          </h2>
        </div>
        <div className="px-5 mb-0">
          <ScrollUpButton StopPosition={0}>
            <p className="my-0">
              <Link to="/authentication/signup" className='btn btn-civipol btn-block btn-auth-block mb-0'>
                <FormattedMessage id="account.signup" defaultMessage="Not translated" />
              </Link>
            </p>
          </ScrollUpButton>

        </div>
      </div>
    </Fragment>
    :
    null
  );
}

export default withCookies(Signin);
