import React from "react";
import TemplateEvent from "../../Templates/TemplateEvents";

const ProfileValidatingNotif = () => {

    const elems = {};
    elems.img = 'illu_validation.svg';
    elems.title = 'validation.profileValidatingTitle';
    elems.msges = [
        'validation.profileValidatingText1',
        'validation.profileValidatingText2'
    ];
    // elems.buttons = [
    //     { color: 'colored', msge: 'account.backToHome', link: '/expert/my_profile' },
    //     { color: 'white', msge: 'account.backToHome', link: '/expert/my_profile' },
    // ]

    return (
        <div className="container mb-5">
            <div className="row">
                <div className="col">
                    <TemplateEvent {...elems} />
                </div>
            </div>
        </div>
    )
}
export default ProfileValidatingNotif;