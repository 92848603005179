import React from "react";
import ReactDOM from "react-dom";
import { addLocaleData, IntlProvider } from "react-intl";
import en from "react-intl/locale-data/en";
import fr from "react-intl/locale-data/fr";
import { flattenMessages, messages } from "../src/Static/Messages/messages";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";
import "./Static/Base_CSS/base.scss";
import "./Static/Base_CSS/style.scss";
import { unregister } from "./serviceWorker";

require("dotenv").config("../dist/preprod.env");

addLocaleData([...en, ...fr]);

let locale = localStorage.getItem("lang");
if (!locale) {
  locale = "fr";
  localStorage.setItem("lang", locale);
}

ReactDOM.render(
  <IntlProvider locale={locale} messages={flattenMessages(messages[locale])}>
    <App />
  </IntlProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();