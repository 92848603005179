import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { withCookies } from 'react-cookie';

const ConfirmationMailNotification = ({cookies}) => {
    const [open, setOpen] = useState(true)
    if (open) {
        return (
            <div className="container-fluid d-flex justify-content-between notifications position-relative mb-4">
                <div className="w-100 d-flex flex-column flex-lg-row justify-content-center align-items-center">
                    <img src={require("../../Static/Illustrations/illu_profil.svg")} alt="illu_profile" className="img-fluid mb-4 mb-lg-0 mr-lg-5" />
                    <div className="d-flex flex-column">
                        <p className="title text-center text-lg-left">
                            <FormattedMessage id="notifications.mailConfirmationTitle" defaultMessage="Not translated"></FormattedMessage>
                        </p>
                        <p className="text text-center text-lg-left mb-0">
                            <FormattedMessage id="notifications.mailConfirmationText" defaultMessage="Not translated"></FormattedMessage>
                        </p>
                    </div>
                </div>
                <button onClick={() => {
                    cookies.remove('notification', {path: '/'})
                    setOpen(false);
                }} className="btn transparent btn-close-notif"></button>
            </div>
        )
    } else {
        return null
    }
}

export default withCookies(ConfirmationMailNotification);
