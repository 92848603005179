import React from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

export const CheckBoxes = ({ name, checked, ...props }) => {
    let sectionAndId = "confirmation-" + name;
    return (
        <div className="row flex-row mb-4 justify-content-between">
            <div className="col text-capitalize">
                <Form.Check className="custom-control custom-radio" type='radio'>
                    <Form.Check.Input type='radio' value={true} name={name} className="custom-control-input" id={sectionAndId}
                        defaultChecked={
                            ((props.values.identity[name]) && ((props.values.identity[name]) !== ""))
                        }
                        onChange={e => {
                            props.setValues({
                                ...props.values,
                                identity: {
                                    ...props.values.identity,
                                    [e.target.name]: JSON.parse(e.target.value)
                                }
                            })
                        }}
                        isInvalid={props.errors.identity && props.errors.identity[name]}
                    />
                    <Form.Check.Label htmlFor={sectionAndId} className='custom-control-label'>
                        <p className="mt-1 ml-3">
                            <FormattedMessage id="general.yes" defaultMessage="Not translated" />
                        </p>
                    </Form.Check.Label>
                </Form.Check>
            </div>
            <div className="col text-capitalize">
                <Form.Check className="custom-control custom-radio" type='radio'>
                    <Form.Check.Input type='radio' name={name} value={false} className="custom-control-input" id={sectionAndId + 'no'}
                        defaultChecked={
                            (!(props.values.identity[name]) && (props.values.identity[name]) !== "")
                        }
                        onChange={e => {
                            props.setValues({
                                ...props.values,
                                identity: {
                                    ...props.values.identity,
                                    [e.target.name]: JSON.parse(e.target.value)
                                }
                            })
                        }}
                        isInvalid={props.errors.identity && props.errors.identity[name]}
                    />
                    <Form.Check.Label htmlFor={sectionAndId + 'no'} className='custom-control-label' >
                        <p className="mt-1 ml-3">
                            <FormattedMessage id="general.no" defaultMessage="Not translated" />
                        </p>
                    </Form.Check.Label>
                </Form.Check>
            </div>
        </div>
    )
}
