import React, { useContext } from 'react'
import { NavLink, Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import LogoCivipolBaseExperts from '../LogoCivipolBaseExperts';
import { withCookies } from 'react-cookie';
import './CSS/header.scss';
import { HeaderContext } from "./HeaderContext";
import { ExpertContext } from "../../Contexts/ExpertContext";
import { Navbar, Nav } from "react-bootstrap";
import { logout } from "../../utils/logout";


const Header = (props) => {
    const { state, methods } = useContext(HeaderContext);
    const expertStore = useContext(ExpertContext);
    const { cookies } = props;
    const url = props.location.pathname.split("/");
    let title = "";
    if (url[1] === "expert") {
        title = url[2];
    } else {
        title = "my_profile";
    }

    if (url[1] !== "employees" && url[1] !== "rgpd") {
        const Menu = () => {
            const { menu, liens, titles } = state;
            const { handleTitle } = methods;

            if (menu) {
                const m = Object.keys(menu);
                return (
                    <Navbar expand="xl">
                        <div className="">
                            <Nav className="mr-auto d-none d-xl-block">
                                {
                                    m.map((value, key) => {
                                        if (value === 'OUTILS' && expertStore.state && expertStore.state.expert && !expertStore.state.expert.wasAcceptedToMission) {
                                            return null;
                                        }
                                        return (
                                            <NavLink to={
                                                liens[value]
                                            }
                                                key={key}
                                                onClick={
                                                    () => handleTitle(titles[value])
                                                }
                                                activeClassName="active"
                                                className="mr-3">
                                                <FormattedMessage id={
                                                    menu[value]
                                                }
                                                    defaultMessage="Not translated" />
                                            </NavLink>
                                        )
                                    })
                                } </Nav>
                            <Navbar.Toggle aria-controls="main-navbar-nav" />
                        </div>
                        <div className="d-block d-xl-none">
                            <Navbar.Collapse id="main-navbar-nav">
                                <div className="bg-white shadow-lg">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col">
                                                <div className="d-flex justify-content-between align-items-center" id="main-menu">
                                                    <LogoCivipolBaseExperts />
                                                    <Navbar.Toggle aria-controls="main-navbar-nav" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="menu">
                                    <div className="container">
                                        <div className="row align-items-center justify-content-center">
                                            <div className="col-12 col-sm-10 col-md-8 py-5">
                                                <a className='btn btn-civipol btn-block mb-5' href="http://civipol.fr/"><FormattedMessage defaultMessage="Not translated" id="account.discovercivipol" /></a>
                                                <Nav className="mr-auto">
                                                    {
                                                        m.map((value, key) => {
                                                            return (
                                                                <NavLink to={
                                                                    liens[value]
                                                                }
                                                                    key={key}
                                                                    onClick={
                                                                        () => handleTitle(titles[value])
                                                                    }
                                                                    activeClassName="active"
                                                                    className="mr-3">
                                                                    <FormattedMessage id={
                                                                        menu[value]
                                                                    }
                                                                        defaultMessage="Not translated" />
                                                                </NavLink>
                                                            )
                                                        })
                                                    } </Nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Navbar.Collapse>
                        </div>
                    </Navbar>
                )
            } else
                return null;
        }

        const TopHeader = () => {
            if (!url.includes('authentication') && !url.includes('reinit_password_form') && !url.includes('confirmPasswordReinit')) {
                return (
                    <div className="top-header">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="d-flex flex-column flex-sm-row justify-content-end align-items-end align-items-sm-center py-2 top-header-menu">
                                        <p className="ml-2 mb-2 mb-sm-0 order-0 order-sm-1 d-flex align-items-center">
                                            <span className="language d-inline-flex align-items-center pr-1"
                                                onClick={
                                                    () => {
                                                        if (localStorage.lang === "fr") {
                                                            localStorage.lang = "en"
                                                        } else
                                                            localStorage.lang = "fr"
                                                        window.location.reload();
                                                    }
                                                }>
                                                {
                                                    (localStorage.lang === 'fr') ? <span>English</span> : <span>Français</span>
                                                }</span>|
                                            <Link onClick={
                                                () => logout(cookies)
                                            }
                                                className="text-white pl-1"
                                                to='/authentication/signin'>
                                                <FormattedMessage id={"header.logout"}
                                                    defaultMessage="Not translated" />
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            } else {
                return null;
            }
        };

        if (window.location.pathname.indexOf("authentication") === -1 && window.location.pathname.indexOf("not_found") === -1 && window.location.pathname.indexOf("internal_error") === -1 && window.location.pathname.indexOf("/reinitRegistration") === -1) {
            return (
                <div className="d-flex flex-column mb-5" id="navbar">
                    {
                        (window.location.pathname.indexOf("reinit_password_form") === -1) && <TopHeader />
                    }
                    <div className="sub-header mt-1" id="sub-header">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="d-flex justify-content-between align-items-center" id="main-menu">
                                        <LogoCivipolBaseExperts />
                                        <div className="d-flex flex-xl-row-reverse align-items-center">
                                            <p className="ml-auto mr-3 mr-xl-0 mb-0 d-none d-md-flex">
                                                <a className='btn btn-civipol' href="http://civipol.fr/"><FormattedMessage defaultMessage="Not translated" id="account.discovercivipol" /></a>
                                            </p>
                                            {
                                                (window.location.pathname.indexOf("reinit_password_form") === -1) && <Menu />
                                            } </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="title-header">
                        <div className="container h-100">
                            <div className="row flex-row align-items-center h-100">
                                <div className="col">
                                    <h1 className="text-uppercase text-center text-lg-left">
                                        <FormattedMessage id={
                                            `header.menu.${title}`
                                        }
                                            defaultMessage="Not translated" />
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )

        } else if (window.location.pathname.indexOf("/not_found") >= 0 || window.location.pathname.indexOf("/internal_error") >= 0 || window.location.pathname.indexOf("/reinitRegistration") >= 0) {
            return (
                <div className="d-flex flex-column mb-5" id="navbar">
                    <div className="sub-header mt-1" id="sub-header">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="d-flex justify-content-between align-items-center" id="main-menu">
                                        <LogoCivipolBaseExperts />
                                        <div className="d-flex flex-xl-row-reverse align-items-center">
                                            <p className="ml-auto mr-3 mr-xl-0 mb-0 d-none d-md-flex">
                                                <a className='btn btn-civipol' href="http://civipol.fr/"><FormattedMessage defaultMessage="Not translated" id="account.discovercivipol" /></a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="title-header">
                        <div className="container h-100">
                            <div className="row flex-row align-items-center h-100">
                                <div className="col">
                                    <h1 className="text-uppercase text-center text-lg-left">
                                        <FormattedMessage defaultMessage="Not translated" id="general.errorPageNotFoundTitle" />
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else
            return null;
    } else {
        return null;
    }
}

export default withCookies(withRouter(Header));
