import * as yup from 'yup';
import * as countries from "i18n-iso-countries";

export const individualInformationsSchema = (values) => {
    const frenchPhoneRegex = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*(\d{1,2})$/;
    const frenchCountries = Object.values(countries.getNames("fr"));
    const SUPPORTED_FORMATS = [
        "image/jpg",
        "image/jpeg",
        "image/png",
        "application/pdf"
    ];
    const FILE_SIZE = 5000000;

    const fileTest = yup
        .mixed()
        .test('fileValidation', '', function (file) {
            if (typeof file === 'string') {
                return true;
            } else if (!file || file.file === null) {
                return this.createError({ path: this.path, message: 'validation.required' });
            } else {
                if (file && !SUPPORTED_FORMATS.includes(file.mimeType)) {
                    return this.createError({ path: this.path, message: 'validation.fileInvalidFormat' });
                }
                if (file && file.size > FILE_SIZE) {
                    return this.createError({ path: this.path, message: 'validation.fileTooBig' });
                }
            }
        });
    
    const ICE = yup.array().of(
        yup.object().shape({
            first_name: yup.string().required("validation.required"),
            last_name: yup.string().required("validation.required"),
            address_number_street: yup.string().required("validation.required"),
            address_postal_code: yup.string().required("validation.required"),
            address_city: yup.string().required("validation.required"),
            address_country: yup.mixed().oneOf(frenchCountries, 'validation.notvalid'),
            relationship: yup.string().required("validation.required"),
            telephone: yup.string().matches(frenchPhoneRegex, { message: 'validation.notvalid', excludeEmptyString: true }),
            email: yup.string().email('validation.notvalid').required("validation.required"),
        })
    );

    const medicalInformations = yup.object().shape({
        doctorName: yup.string().nullable(),
        doctorAddress: yup.string().nullable(),
        doctorPhoneNumberFix: yup.string().matches(frenchPhoneRegex, { message: 'validation.notvalid', excludeEmptyString: true }).nullable(),
        doctorCellPhoneNumber: yup.string().matches(frenchPhoneRegex, { message: 'validation.notvalid', excludeEmptyString: true }).nullable(),
        doctorEmail: yup.string().email('validation.notvalid').nullable(),
        bloodGroup: yup.string().nullable(),
        rhesus: yup.string().nullable(),
        therapeuticsContrindications: yup.string().nullable(),
        allergies: yup.string().nullable()
    });

    const personalInformations = yup.object().shape({
        matrimonialName: yup.string().required("validation.required"),
        placeOfBirth: yup.string().required("validation.required"),
        nativeCountry: yup.string().required("validation.required"),
        passportNumber: yup.string().required("validation.required"),
        passportExpirationDate: yup.date().min(new Date(), 'validation.notvalid').required('validation.required'),
        passportFile: fileTest,
        socialSecurityNumber: yup.string().required("validation.required"),
        vitalCardFile: fileTest.required(),
        ribNumber: yup.string().required("validation.required"),
        ibanNumber: yup.string().required("validation.required"),
        passportPlaceDelivery: yup.string().required("validation.required"),
        rib: fileTest
    });
    
    return yup.object({
        ICE,
        personalInformations,
        medicalInformations: values.medicalInformations.medicalConsent ? medicalInformations : null
    });
};
