import React from "react";
import { Link } from 'react-scroll';
import { FormattedMessage } from "react-intl";
export const ToolsMenu = () => {
    return (
        <div id="padding-menu" className={'sticky-top'}>
                <div id="menu">
                    <Link
                        to="1"
                        id="menu-1"
                        smooth={true}
                        duration={500}
                        spy={true}
                        offset={-200}
                        activeClass="active"
                        className="btn-menu mission d-flex d-flex justify-content-center align-items-center"
                    >
                        <span><FormattedMessage id="tools.prepareMissions.title" defaultMessage="Not translated" /></span>
                    </Link>
                    <Link
                        to="2"
                        id="menu-2"
                        smooth={true}
                        duration={500}
                        spy={true}
                        offset={-200}
                        activeClass="active"
                        className="btn-menu mission d-flex  d-flex justify-content-center align-items-center"
                    >
                        <span><FormattedMessage id="tools.documentationKit.title" defaultMessage="Not translated" /></span>
                    </Link>
                </div>
            </div>
    );
}
