
import * as yup from 'yup';

export const CVSchema = ({ translateMessage }) => {
    const supportedExtensions = ["docx", "doc", "pdf", "PDF"];

    const supporting_documents = yup
        .array()
        .test("value test", "error test", function (array) {
            const { path, createError } = this;
            let isCV = false;
            let errors = {};
            let isError = false;
            for (var i = 0, count = array.length; i < count; i++) {

                if (array[i].type === "CV") {
                    isCV = true;
                    if (array[i].file) {
                        if (array[i].file.size > 5000000) {
                            errors.CV = translateMessage("profile.sections.CV.error_size")
                            isError = true;
                        }
                    }
                    if (array[i].name && (array[i].name.indexOf('.docx') === -1 && array[i].name.indexOf('.doc') === -1 && array[i].name.indexOf('.pdf') === -1 && array[i].name.indexOf('.PDF') === -1) ) {
                        errors.CV = translateMessage('profile.sections.CV.error_format');
                        isError = true;
                    }
                    if (supportedExtensions.includes(array[i].extension)) {
                        isError = false;
                    }
                }
            }
            if (!isCV) {
                errors.CV = translateMessage('profile.sections.CV.error_miss')
                isError = true
            }
            if (isError) {
                return createError({ path, message: errors })
            } else
                return true
        });

    return yup.object({
        supporting_documents: supporting_documents,
        identity: yup.object().shape({
            notificationActus: yup.string().matches(/true|false/, "validation.required").required("validation.required"),
            notificationOffers: yup.string().matches(/true|false/, "validation.required").required("validation.required"),
            criminal_conviction: yup.string().matches(/true/, "validation.required").required("validation.required"),
        })
    });
};