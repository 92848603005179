import React, { Fragment, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { authenticationSchemas } from './Schemas/AuthenticationSchemas';
import { SIGNUP_MUTATION } from './Mutations/Authentications';
import { Mutation } from 'react-apollo';
import ScrollUpButton from 'react-scroll-up-button';
import * as moment from 'moment';
import { TranslateContextWrapper } from "../../Contexts/TranslateContext";
import { GoogleReCaptchaProvider,useGoogleReCaptcha } from 'react-google-recaptcha-v3';

//fonction exécutée lorsque la mutation d'inscription est validée
const signupConfirmed = (value, { history }) => {
    history.push('/authentication/mail_inscription')
}

// en cas d'error on retourne le tableau d'erreur pour le composant mutation
const onError = ({ graphQLErrors }) => {
    let test = graphQLErrors.map(({ message }, i) => (
        message
    ))
    return (test);
}

//handler d'erreurs pour afficher les erreurs
const ErrorHandler = (error) => {
    let errorMessage = error.graphQLErrors.map((value, i) => {
        if (value && value.extensions && Object.getOwnPropertyNames(value.extensions).length !== 0) {
            let errorReturn = [];

            if(value.extensions.code && Array.isArray(value.extensions.code.inner)){
                errorReturn = value.extensions.code.inner.map(({ message, path }, i) => (
                    <div className="is-invalid" key={i}>
                        {(path === "birthday" && localStorage['lang'] === 'en') ? path : "Date de naissance"}:
                        <FormattedMessage id={message} defaultMessage="Not translated" />
                    </div>
                ))
            }
            if(value.extensions.code && value.extensions.code === '418' && value.extensions.undelyingError )
            {
                errorReturn.push((<div className="is-invalid" key={i}>
                    <FormattedMessage id="general.error418" defaultMessage="Not translated" />
                </div>));
            }

            return (
                errorReturn
            )
        } else {
            let errorReturn;
            errorReturn = value.message;
            return (
                <div className="is-invalid" key={i}>
                    <FormattedMessage id={errorReturn} defaultMessage="Not translated" />
                </div>
            );
        }
    })
    return errorMessage;
}


const SignUpFormOpen = ({ translate, ...props }) => {
    const [errorSubmit, setError] = useState(null);
    const [inputAdvices, setInputAdvices] = useState(null);

    // Recaptcha implementation.
    // little different from the doc from https://www.npmjs.com/package/react-google-recaptcha-v3
    // using a state.
    const [reCaptcha, setReCaptcha] = useState(undefined);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const handleReCaptchaVerify = useCallback(() => {
        // Look that the executeRecaptcha is kind of isReady.
        // never happened once well configured.
        // It looks like if its happens, there is bad initialisations on Provider, in SignUp Componenet.
        if (!executeRecaptcha) {
          console.log('Execute recaptcha not yet available');
          return;
        }
        (async () => {
          try {
            const token = await executeRecaptcha('signup');
            setReCaptcha(token);
          } catch (error) {
            console.log(error.response);
          }
        })();
      }, [executeRecaptcha]);


    return (
        <Mutation
            mutation={SIGNUP_MUTATION}
            onCompleted={
                (data) => signupConfirmed(data, props)
            }
            onError={onError}
        >
            {(addUser, { loading, error }) => (
                <Formik

                    //schema de validation pour l'authentification
                    validationSchema={authenticationSchemas(translate)}

                    //formik attend des valeurs initiales par default
                    initialValues={{ firstName: '', lastName: '', email_1: '', password: '', passwordConfirmation: '', birthday: '' }}

                    //lorsqu'on soumet on assigne les valeurs a variables
                    onSubmit={values => {
                        const variables = Object.assign({}, values);

                        // on formate la date 
                        variables.birthday = (moment(values.birthday, 'L').format('YYYY-MM-DD') === 'Invalid date') ? moment().format('YYYY-MM-DD') : moment(values.birthday, 'L').format('YYYY-MM-DD');
                        // ressortir le captcha de l'état.
                        // Peut être undefined eventuellement puisque c'est sa valeur initiale.
                        // Mais normalement il sera set avant d'arriver la.
                        variables.reCaptchaToken = reCaptcha;

                        addUser({ variables });
                    }}
                    
                    render={({ handleSubmit, errors, touched, handleChange, values, validateForm, setValues }) => {
                        
  
                        return (
                            <Form className="px-4 px-sm-5 py-5 mb-0" noValidate onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <div className="text-size-low mb-3"><FormattedMessage id="account.createProfileLaius" /><br /></div>
                                    <div className="text-size-low d-flex align-items-center">
                                        <input type="checkbox" name="statusConfirmation" onClick={handleChange} className="custom-control custom-radio custom-radio-fat mr-2"   ></input>
                                        <FormattedMessage id="account.createProfileLaiusConfirmation" /><br />
                                    </div>
                                    {errors && errors.statusConfirmation && <div className="is-invalid">{errors.statusConfirmation}</div>}
                                </div>

                                <Form.Label className={errorSubmit && errorSubmit.firstName && errors.firstName ? "is-invalid-label" : null}><FormattedMessage id='account.firstName' defaultMessage="Not translated" /></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="firstName"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    onClick={() => setInputAdvices(false)}
                                    isInvalid={errors.firstName}
                                    className="form-group mb-4"
                                    maxLength="50"
                                />
                                <p className={"invalid-feedback "}>{errorSubmit && errorSubmit.firstName ? errors.firstName : null}</p>
                                <Form.Label className={errorSubmit && errorSubmit.lastName && errors.lastName ? "is-invalid-label" : null}><FormattedMessage id='account.lastName' defaultMessage="Not translated" /></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="lastName"
                                    value={values.lastName}
                                    onChange={handleChange}
                                    onClick={() => setInputAdvices(false)}
                                    isInvalid={errors.lastName}
                                    className="form-group mb-4"
                                    maxLength="50"
                                />
                                <Form.Control.Feedback type="invalid">{errorSubmit && errorSubmit.lastName ? errors.lastName : null}</Form.Control.Feedback>
                                <Form.Label className={errorSubmit && errorSubmit.email_1 && errors.email_1 ? "is-invalid-label" : null}><FormattedMessage id='account.email' defaultMessage="Not translated" /></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="email_1"
                                    value={values.email_1}
                                    onChange={handleChange}
                                    isInvalid={errors.email_1}
                                    onClick={() => setInputAdvices(false)}
                                    className="form-group mb-4"
                                    maxLength="50"
                                />
                                <Form.Control.Feedback type="invalid">{errorSubmit && errorSubmit.email_1 ? errors.email_1 : null}</Form.Control.Feedback>
                                <Form.Label className={errorSubmit && errorSubmit.password && errors.password ? "is-invalid-label" : null}><FormattedMessage id='account.password' defaultMessage="Not translated" /></Form.Label>
                                <Form.Control
                                    type="password"
                                    name="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    isInvalid={errors.password}
                                    maxLength="50"
                                    onClick={() => {
                                        if (errorSubmit && errorSubmit.password) {
                                            delete errorSubmit.password
                                        }
                                        setInputAdvices(true)
                                    }}
                                    onFocus={() => {
                                        if (errorSubmit && errorSubmit.password) {
                                            delete errorSubmit.password
                                        }
                                        setInputAdvices(true)
                                    }}
                                    className="form-group mb-4"
                                />
                                {inputAdvices ? <p className='password-advice'><FormattedMessage id='validation.passwordnotvalid' defaultMessage="Not translated" /></p> : null}
                                <Form.Control.Feedback type="invalid">{errorSubmit && errorSubmit.password ? errors.password : null}</Form.Control.Feedback>
                                <Form.Label className={errorSubmit && errorSubmit.passwordConfirmation && errors.passwordConfirmation ? "is-invalid-label" : null}><FormattedMessage id='account.passwordConfirmation' defaultMessage="Not translated" /></Form.Label>
                                <Form.Control
                                    type="password"
                                    name="passwordConfirmation"
                                    value={values.passwordConfirmation}
                                    onChange={handleChange}
                                    isInvalid={errors.passwordConfirmation}
                                    className="form-group mb-4"
                                    onClick={() => setInputAdvices(false)}
                                    maxLength="50"

                                />
                                <Form.Control.Feedback type="invalid">{errorSubmit && errorSubmit.passwordConfirmation ? errors.passwordConfirmation : null}</Form.Control.Feedback>
                                <div className="mb-5">
                                    <Form.Label className={errorSubmit && errorSubmit.birthday && errors.birthday ? "is-invalid-label" : null}><FormattedMessage id='account.birthday' defaultMessage="Not translated" /></Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder="jj/mm/yyyy"
                                        value={values.birthday}
                                        name="birthday"
                                        className="form-group"
                                        onChange={handleChange}
                                        isInvalid={errors.birthday}
                                        maxLength="50"
                                    >
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid" className="invalid-feedback">{errorSubmit && errors.birthday && <p>{errors.birthday}</p>}</Form.Control.Feedback>

                                </div>
                                {error &&
                                    <ErrorHandler {...error} />
                                }
                                <div className="d-flex align-items-center mb-3">
                                    <input type="checkbox" onClick={handleChange} name="cguConfirmation" className="mr-3"   />
                                    <div className="text-size-low">
                                        <FormattedMessage id="account.cgu" defaultMessage="Not translated" />
                                    </div>
                                </div>
                                {errors && errors.cguConfirmation && <div className="is-invalid">{errors.cguConfirmation}</div>}
                                <div className="text-size-low">
                                    <div className="mb-2">
                                        <FormattedMessage id="account.rgpdLaius" defaultMessage="Not translated" />
                                    </div>
                                    <div className="d-flex align-items-center mb-3">
                                        <input type="checkbox" onClick={handleChange} name="rgpdConfirmation" className="mr-3"   />
                                        <FormattedMessage id="account.rgpdCheckbox" defaultMessage="Not translated" />
                                    </div>
                                    {errors && errors.rgpdConfirmation && <div className="is-invalid">{errors.rgpdConfirmation}</div>}
                                    <div className="text-size-low">
                                        <FormattedMessage id="account.rgpdLink" defaultMessage="Not translated" values={{ link_rgp: <Link className="text-decoration-none" to="/rgpd" ><FormattedMessage id="account.thisLink" /></Link> }} />
                                    </div>
                                </div>
                                
                                <p className="text-center mb-0 mt-5">
                                    <button
                                        type="submit"
                                        className={'btn btn-block btn-civipol btn-auth-block'}
                                        // a la soumission on valide le formulaire, on voit si on affiche les conseils de saisie pour le mot de passe et on set les erreurs
                                        // lorsqu'on soumet, si on check le captcha dans le clic, il arrive qu'il n'ait pas le temps de se calculer avant que la reqête ne aprte.
                                        // L'ajouter dans le onMouseEnter a résolu.
                                        // Inconvénient, il se peut que plusieurs captcha soit calculés, lorsqu'on ressort le curseur sans cliquer.
                                        // Ca ne semble pas poser de problème, mais je ne sais pas.

                                        onClick={() => {
                                            validateForm().then((errors) => {
                                                setInputAdvices(false);
                                                setError(errors);
                                            });
                                        }}
                                        onMouseEnter={() =>{
                                            handleReCaptchaVerify();
                                        }}
                                    >
                                        <FormattedMessage id='account.signup' defaultMessage="Not translated" />
                                    </button>
                                </p>
                            </Form>
                        )
                    }
                    }
                />
            )}
        </Mutation>
    )
}

// le comopsant pour l'inscrition
const Signup = ({ ...props }) => {

    // Here added the GoogleReCaptchaProvider. async looks to be necessary (which is little different from the doc.)
    
    //on check si on est bien sur le bon chemin
    return ((window.location.pathname === "/authentication/signup") ?
        <Fragment>
            <div id='login-form' className="mb-4">
                <div id='title-block' className="sign-up d-flex justify-content-center align-items-center">
                    <h2 style={{ fontFamily: "DIN-BOLD" }} className="text-center text-white font-weight-bold mb-0">
                        <FormattedMessage id="account.noaccount2" defaultMessage="Not translated" />
                    </h2>
                </div>
                <div className="form">
                    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}  scriptProps={{ async: true }}>
                        <SignUpFormOpen  translate={props.translateContext} {...props} />
                    </GoogleReCaptchaProvider>
                </div>
            </div>
            <div className="mt-4 d-flex flex-column justify-content-center">
                <p className="text-center py-8">
                    <FormattedMessage id='account.haveaccount2' defaultMessage="Not translated" />
                </p>
                <ScrollUpButton ContainerClassName="text-center"
                    StopPosition={0}
                >
                    <p className="text-center">
                        <Link to="/authentication/signin" className="btn btn-signin">
                            <FormattedMessage id="account.signin" defaultMessage="Not translated" />
                        </Link>
                    </p>
                </ScrollUpButton>
            </div>
        </Fragment>
        :
        null
    );
}

export default TranslateContextWrapper(Signup);
