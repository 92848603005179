import React from "react";
import PrepareMissionNotification from "../Notifications/PrepareMissionNotification";
import KitDocumentation from "../Notifications/KitDocumentation"
import { ToolsMenu } from "./ToolsMenu";
import { Element } from 'react-scroll'
import { FormattedMessage } from "react-intl";
import { withCookies } from "react-cookie";
import ErrorTools from "../Notifications/ErrorTools";
import { useQuery } from '@apollo/react-hooks';
import { GET_EXPERT } from './query/getExpert';
import client from '../../ApolloSetup';

const ToolsPage = (props) => {

    const { data } = useQuery(GET_EXPERT, { client });

    if (data && data.getExpert && data.getExpert.wasAcceptedToMission) {
        return (
            <div className="container my-5 tools">
                <div className="row flex-row">
                    <div className="col-12 col-lg-4 mb-4 mb-lg-0">
                        <ToolsMenu />
                    </div>
                    <div className="col-12 col-lg-8">
                        <Element id="1" >
                            <div className="mb-5">
                                <h1><FormattedMessage id="tools.prepareMissions.title" defaultMessage="Not translated" /></h1>
                                <div className="encoche-title"></div>
                            </div>
                            <PrepareMissionNotification />
                        </Element>
                        <Element id="2">
                            <div className="mb-5">
                                <h1><FormattedMessage id="tools.documentationKit.title" defaultMessage="Not translated" /></h1>
                                <div className="encoche-title"></div>
                            </div>
                            <KitDocumentation />
                        </Element>
                    </div>
                </div>
            </div>
        );
    } else return <ErrorTools />;
}
export default withCookies(ToolsPage);