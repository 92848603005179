import React, {useContext} from "react";
import { OffersContext } from '../../Contexts/OffersContext';
import { Form } from "react-bootstrap";
import { FormattedMessage } from 'react-intl';
import { TranslateContextWrapper } from '../../Contexts/TranslateContext';
import client from '../../ApolloSetup';
import {SEARCH_OFFER} from "./queries";

const getSearchedOffers = async (e, input, methods) => {
    e.preventDefault();
    let searchedOffers = await client.query({query: SEARCH_OFFER, variables: input});
    methods.getOffers(searchedOffers.data.searchOffers);
}


export const InputSearch = (props) => {
    
    const {state, methods} = useContext(OffersContext);

    return (
        <div className="row mb-5">
        
            <Form className="col">
                <Form.Label htmlFor='search'>
                    <FormattedMessage id="offers.lookingForOffers" defaultMessage="Not translated"/>
                </Form.Label>
                <div className="search">
                    <Form.Control
                        name="input"
                        type='text'
                        value={state.input}
                        onChange={(e) => methods.handleSearch(e.target.value)}
                        placeholder={props.translateContext.translateMessage("offers.inputPlaceHolder")}
                        maxLength="90"
                    />
                    <button
                        className="btn btn-civipol"
                        onClick={(e) => getSearchedOffers(e, state, methods)}
                    >
                        <FormattedMessage id="offers.search" defaultMessage="Not translated"/>
                    </button>
                </div>
            </Form>
        </div>
    )
}

export default TranslateContextWrapper(InputSearch);