import React from 'react';
import TemplateEvents from '../Templates/TemplateEvents';
/**
 * page of confirmation of change password mail is sent
 */ 

export const MailPassword = () => {

    let elems = {
        img: 'illu_no-result.svg',
        title: "validation.confirmationPasswordTitle",
        msges: ["validation.confirmationPasswordSent1"],

    };

    return ((window.location.pathname === "/authentication/email_password") ?
        <TemplateEvents {...elems} /> : null
    )
}