import React, { useContext } from "react";
import * as countries from "i18n-iso-countries";
import { ExpertContext } from "../../../Contexts/ExpertContext";
import { Form } from "react-bootstrap";
import { FormattedMessage } from 'react-intl';
import Input from "./Input";
import { CalendarComponent } from "../../Calendar/CalendarComponent";
import Selector from "../Selector";
import ReinitPassword from "./ReinitPassword/ReinitPassword";
import DeleteAccount from "./DeleteAccount/DeleteAccount";

const sectionMessageName = "profile.sections.identity.";

const civilityArray = [
    { label: <FormattedMessage id={`${sectionMessageName}aboutYou.inputs.female`} defaultMessage="Not translated" />, value: "FEMALE" },
    { label: <FormattedMessage id={`${sectionMessageName}aboutYou.inputs.male`} defaultMessage="Not translated" />, value: "MALE" }
]

const AboutYou = (props) => {
    //chemin pour la traduction
    const path = sectionMessageName + "aboutYou";
    let array = Object.values(countries.getNames("fr"));
    const { methods } = useContext(ExpertContext);
    let divStyle = "";
    if (props.values.identity.profile_pic && !props.values.identity.profile_pic.name) {
        divStyle = { backgroundImage: 'url(' + process.env.REACT_APP_API_URL + props.values.identity.profile_pic.substring(1, props.values.identity.profile_pic.length) + ')' };
    }
    //fonction d'affichage de l'image dans le canvas
    const uploadImg = (e) => {

        methods.setImgeStatus(true);
        let canvas = document.getElementById("canvas");
        let context = canvas.getContext('2d');
        let reader = new FileReader();

        return new Promise(resolve => {
            reader.onload = (e) => {
                let image = new Image();
                image.onload = () => {
                    canvas.width = image.height;
                    canvas.height = image.height;
                    context.drawImage(image, canvas.width / 2 - image.width / 2, canvas.height / 2 - image.height / 2);
                    resolve(canvas.src = canvas.toDataURL())
                }
                image.src = e.target.result;
            }
            reader.readAsDataURL(e.target.files[0]);
        })

    }
    //retourne le block concernant les information d'identité de l'expert
    return (
        <div className="mb-4">
            <div className="row">
                <div className="col mb-4">
                    <div className="d-flex justify-content-between">
                        <DeleteAccount />
                        <ReinitPassword />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <h2 className="title text-uppercase"><FormattedMessage id={sectionMessageName + "aboutYou.title"} defaultMessage="Not translated" /> </h2>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-8 col-md-6">
                    <Selector array={civilityArray} section="identity" name="civility" path={path} {...props} required />
                </div>
            </div>
            <div className="row flex-row justify-content-between">
                <Input name="identity.firstName" label={path + ".inputs.yourFirstName"} formik={props} maxlength="50" required />
                <Input name="identity.lastName" label={path + ".inputs.yourLastName"} formik={props} maxlength="50" required />
            </div>
            <div className="row mb-4">
                <div className="col-8 col-md-6">
                    <Form.Label
                        className={props.errors.identity && props.errors.identity.birthday ? "is-invalid-label" : null}
                    ><p className="mb-0"><FormattedMessage id={path + ".inputs.birthday"} defaultMessage="Not translated" />*</p></Form.Label>
                    <CalendarComponent {...props} section='identity' name="birthday" />
                    <Form.Control.Feedback type="invalid" className="invalid-feedback">{props.errors.identity && props.errors.identity.birthday && <p>{props.errors.identity.birthday}</p>}</Form.Control.Feedback>
                </div>
            </div>

            <div className="row flex-row justify-content-between mb-4">
                <div className="col-12 col-md-6 mb-4">
                    <Selector array={array} section="identity" name="nationality_1" path={path} {...props} required />
                </div>
                <div className="col-12 col-md-6">
                    <Selector array={array} section="identity" name="nationality_2" path={path} {...props} />
                </div>
            </div>
            <div className="d-flex identity">
                <Form.Group>
                    <div className="d-flex flex-column">
                        <Form.Label
                            className={props.errors && props.errors.identity && props.errors.identity.profile_pic ? "is-invalid-label" : null}
                        >
                            <p className="mb-3"><FormattedMessage id={path + ".inputs.idPic"} defaultMessage="Not translated" /></p>
                        </Form.Label>
                        <Form.Label
                            htmlFor="profile_image"
                            className={"input-image btn btn-signin"}
                            style={{ maxWidth: "220px" }}
                            accept="image/*"
                        >
                            <FormattedMessage id={path + ".button"} defaultMessage="Not translated" />
                        </Form.Label>
                    </div>
                    <Form.Control
                        type="file"
                        id="profile_image"
                        className="input-file"
                        //composant pour l'image -> lorsqu'on l'a sélectionnée on set le file
                        onChange={e => {
                            if (e.target.files[0]) {
                                if (e.target.files[0].name.indexOf(".jpg") === -1
                                    && e.target.files[0].name.indexOf(".jpeg") === -1
                                    && e.target.files[0].name.indexOf(".JPG") === -1
                                    && e.target.files[0].name.indexOf(".png") === -1
                                    && e.target.files[0].name.indexOf(".PNG") === -1) {
                                    let canvas = document.getElementById("canvas")
                                    canvas.style.display = "none"
                                    props.setValues({
                                        ...props.values,
                                        identity: {
                                            ...props.values.identity,
                                            profile_pic: e.target.files[0]
                                        }
                                    })
                                } else {
                                    let canvas = document.getElementById("canvas")
                                    canvas.style.display = "block"
                                    props.setValues({
                                        ...props.values,
                                        identity: {
                                            ...props.values.identity,
                                            profile_pic: e.target.files[0]
                                        }
                                    })
                                    //on affiche l'image dans le canvas
                                    uploadImg(e)
                                }
                            }
                        }}
                        isInvalid={props.errors && props.errors.identity && props.errors.identity.profile_pic}
                    />
                    <Form.Control.Feedback className="invalid-feedback">
                        {   //feedback pour l'image
                            props.errors && props.errors.identity && props.errors.identity.profile_pic ? <p><FormattedMessage id={"validation.badFormatRequired"} defaultMessage="Not translated" /></p> : null}
                    </Form.Control.Feedback>
                </Form.Group>
            </div>

            <div className={(divStyle) ? "rounded-circle profile-picture profile-picture-large" : null} style={divStyle !== "" ? {
                backgroundImage: `url('${process.env.REACT_APP_API_URL + props.values.identity.profile_pic.substring(1, props.values.identity.profile_pic.length)}')`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
            }
            : { display: "none" }}> </div>
            <canvas id="canvas" height="0" width="0" className={(!divStyle && props.values.identity.profile_pic) ? "rounded-circle profile-picture profile-picture-large" : null} />
        </div>
    )
}

export default AboutYou;
