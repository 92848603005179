import React from 'react';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import * as yup from 'yup';
import { TranslateContextWrapper } from '../../Contexts/TranslateContext.jsx';

/**
 * mutation to update pswd
 */

const UPDATE_PASSWORD_CONFIRMATION = gql`
    mutation updatePasswordInBase($password: String!, $passwordConfirmation: String!, $token: String!){
        updatePasswordInBase(password: $password, passwordConfirmation: $passwordConfirmation, token: $token){
            id
        }
    }
`;

/**
 * schemas to validate values before we send them
 */

const passwordSchema = ({ translateMessage }) => {
    const password = yup.string()
        .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/, translateMessage('validation.passwordnotvalid'))
        .required(translateMessage('validation.required'));
    const passwordConfirmation = yup.string()
        .equals([yup.ref('password')], translateMessage('validation.passwordNotMatching'))
        .required(translateMessage('validation.required'));
    return yup.object({
        password,
        passwordConfirmation
    })
}

/**
 * error handler which format error messages returned by the server
 */

const ErrorHandler = (error) => {
    let errorMessage = error.graphQLErrors.map((value, i) => {
        if (Object.getOwnPropertyNames(value.extensions).length !== 0) {
            let errorReturn = [];
            errorReturn = value.extensions.code.inner.map(({ message, path }, i) => (
                <div className="is-invalid" key={i}>
                    {(path === "birthday" && localStorage['lang'] === 'en') ? path : "Date de naissance"}:
                    <FormattedMessage id={message} defaultMessage="Not translated"/>
                </div>
            ))
            return (
                errorReturn
            )
        } else {
            let errorReturn;
            errorReturn = value.message;
            return (
                <div className="is-invalid" key={i}>
                    <FormattedMessage id={errorReturn} defaultMessage="Not translated"/>
                </div>
            );
        }
    })
    return errorMessage;
}

/**
 * form which submits (if the schemas agrees) the new password to the updatePasswordInBase WS
 */

const ReinitPassword = (props) => {

    return (

        <Mutation
            mutation={UPDATE_PASSWORD_CONFIRMATION}
            onCompleted={() => {
                props.history.push('/confirmPasswordReinit')
            }}
        >
            {(reinitPassword, { loading, error }) => {
                return (
                    <Formik

                        //valuers par défault attendues par formik
                        initialValues={{
                            password: '',
                            passwordConfirmation: ''
                        }}

                        //schemas de validation des données
                        validationSchema={passwordSchema(props.translateContext)}

                        //a la soumission on créé un token pour l'envoyer au back pour la comparaison future entre l'url et ce qui est stocké en base comme token
                        onSubmit={(values) => {

                            const token = props.location.pathname.replace("/reinit_password_form/code=", "");
                            const variables = Object.assign({}, values);
                            variables.token = token;
                            // on exécute la mutation en passant le token de changement de MDP
                            reinitPassword({ variables })
                        }}
                        render={
                            ({ handleChange, values, errors, handleSubmit }) => {

                                //on retourne le formulaire de modification
                                return (
                                    <div className="d-flex flex-column justify-content-between mb-5 mt-2">
                                        <div className="d-flex flex-column align-items-center justify-content-between">
                                            <div className="mt-5" >
                                                <h2 id="reinit-form">Réinitialiser mon mot de passe
                                                <div className="encoche-title"></div>
                                                </h2>
                                                <div id="login-form" className="mt-5  px-0">
                                                    <Form className="pt-3 mb-0 mt-2 form reinit-form" noValidate onSubmit={handleSubmit}>
                                                        <div className="px-5 pl-5 ">
                                                            <h6 className="mt-2 mb-4 title">CHOISISSEZ UN NOUVEAU MOT DE PASSE</h6>
                                                            <Form.Label>
                                                                <FormattedMessage id='reinit_password.newPassword' defaultMessage="Not translated"/>
                                                            </Form.Label>
                                                            <Form.Control
                                                                type='password'
                                                                name='password'
                                                                value={values.password}
                                                                onChange={handleChange}
                                                                isInvalid={errors.password}
                                                                className='form-group mb-4 col-8'
                                                            />
                                                            <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                                            <Form.Label>
                                                                <FormattedMessage id='reinit_password.newPasswordConfirmation' defaultMessage="Not translated"/>
                                                            </Form.Label>
                                                            <Form.Control
                                                                type='password'
                                                                name='passwordConfirmation'
                                                                value={values.passwordConfirmation}
                                                                onChange={handleChange}
                                                                isInvalid={errors.passwordConfirmation}
                                                                className='form-group mb-5 col-8'
                                                            />
                                                            <Form.Control.Feedback type="invalid">{errors.passwordConfirmation}</Form.Control.Feedback>
                                                            {error && <ErrorHandler {...error} />}
                                                        </div>
                                                        <div style={{ backgroundColor: "#F7F7F7", width: "100% !important" }} className='mb-0' >
                                                            <p className="text-center mb-0 mt-5 py-3">

                                                                <button
                                                                    type="submit"
                                                                    className={'btn btn-civipol btn-auth-block'}
                                                                >
                                                                    <FormattedMessage id='account.reinitPasswordButton' defaultMessage="Not translated"/>
                                                                </button>
                                                            </p>
                                                        </div>
                                                    </Form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }}
                    />)
            }}
        </Mutation>

    );
}

export default TranslateContextWrapper(ReinitPassword);