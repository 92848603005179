import React from 'react';
import { FormattedMessage } from 'react-intl';
import Toast from 'react-bootstrap/Toast'
// import SweetAlert from "react-bootstrap-sweetalert";

const ErrorPostuleAlert = ({ alert, openAlert, msgeError }) => {
    if (msgeError) {
        const { graphQLErrors } = msgeError;
        if (alert) {
            return (
                <Toast
                    show={alert}
                    autohide={true}
                    delay={4000}
                    onClose={() => openAlert(false)}
                    className="toast-notif top-right"
                >
                    <div className="d-flex px-1 py-1" >
                        <div className="d-flex flex-column px-2 py-2">
                            <div className="d-flex justify-content-between">
                                <strong className="title text-left text-lg-left">ERREUR</strong>
                                <button onClick={() => openAlert(false)} type="button" className="close" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <p className="mb-0 mt-2">
                                <FormattedMessage id={graphQLErrors[0].message} defaultMessage="Not translated"></FormattedMessage>
                            </p>
                        </div>

                    </div>
                </Toast>
            )
        } else return null;
    } else return null;
}

export default ErrorPostuleAlert;