import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import SweetAlert from "react-bootstrap-sweetalert";
import { TranslateContextWrapper } from "../../../../Contexts/TranslateContext";
import client from "../../../../ApolloSetup";
import { DELETE_EXPERT } from "../../Mutations";
import { withCookies } from "react-cookie";
import { withRouter } from "react-router-dom";
import { logout } from '../../../../utils/logout';

const FirstAlert = (props) => {
    const { setOpenSecond, openFirst, setOpenFirst } = props;
    if (openFirst) {
        return (
            <SweetAlert
                title={<FormattedMessage id="account.deleteAccountAlertTitle" defaultMessage="Not translated" />}
                onConfirm={() => { setOpenFirst(false); setOpenSecond(true); }}
                onCancel={() => setOpenFirst(false)}
                confirmBtnText={<FormattedMessage id="general.yes" defaultMessage="Not translated" />}
                cancelBtnText={<FormattedMessage id="general.no" defaultMessage="Not translated" />}
                closeOnClickOutside={true}
                showCancel={true}
                confirmBtnCssClass="btn btn-civipol"
                cancelBtnCssClass="btn btn-signin"
            >
                <div className="d-flex flex-column">
                    <img className="mb-4" src={require("../../../../Static/Illustrations/illu_offres-demploi.svg")} alt="delete-account" />
                    <p><FormattedMessage id="account.deleteAccountAlertText1" defaultMessage="Not translated" /></p>
                </div>
            </SweetAlert>
        )
    } else {
        return null
    }
}

const deleteMutation = (cookies, allCookies, history) => {
    client.mutate({ mutation: DELETE_EXPERT })
        .then(() => {
            logout();
            history.push("/authentication/signin")
        })
        .catch(err => console.log(err))
}

const SecondAlert = (props) => {
    const { setOpenSecond, openSecond, allCookies, history, cookies } = props;
    if (openSecond) {
        return (
            <SweetAlert
                title={<FormattedMessage id="account.deleteAccountAlertTitle" defaultMessage="Not translated" />}
                onConfirm={() => deleteMutation(cookies, allCookies, history)}
                onCancel={() => setOpenSecond(false)}
                confirmBtnText={<FormattedMessage id="general.yes" defaultMessage="Not translated" />}
                cancelBtnText={<FormattedMessage id="general.no" defaultMessage="Not translated" />}
                closeOnClickOutside={true}
                showCancel={true}
                confirmBtnCssClass="btn btn-civipol"
                cancelBtnCssClass="btn btn-signin"
            >
                <div className="d-flex flex-column">
                    <img className="mb-4" src={require("../../../../Static/Illustrations/illu_offres-demploi.svg")} alt="delete-account" />
                    <p><FormattedMessage id="account.deleteAccountAlertText2" defaultMessage="Not translated" /></p>
                </div>
            </SweetAlert>
        )
    } else {
        return null
    }
}

const DeleteAccount = (props) => {
    const [openFirst, setOpenFirst] = useState(false)
    const [openSecond, setOpenSecond] = useState(false)
    const { translateContext, allCookies, history } = props;

    return (
        <div>
            <FirstAlert openFirst={openFirst} setOpenFirst={setOpenFirst} setOpenSecond={setOpenSecond} translateMessage={translateContext.translateMessage} />
            <SecondAlert history={history} allCookies={allCookies} openSecond={openSecond} setOpenSecond={setOpenSecond} translateMessage={translateContext.translateMessage} />
            <div className="btn btn-signin" onClick={() => { setOpenFirst(true) }}>
                <FormattedMessage id="account.deleteMyAccount" />
            </div>
        </div>
    );
}

export default withRouter(withCookies(TranslateContextWrapper(DeleteAccount)));