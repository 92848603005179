import React, { Fragment, useState } from "react";
import { Form } from "react-bootstrap";
import ReactPhoneInput from 'react-phone-input-2';
import { FormattedMessage } from 'react-intl';

export const buttonStyle = {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#efefef'
};

export const containerStyle = {
    width: '100%',
    height: '60px',
};

export const inputStyle = {
    width: 'inherit',
    height: '60px',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#efefef'
};

const PhoneInput = ({ section, name, facultatif, required, label, ...props }) => {
    const { errors, touched } = props;
    const [countryFlag, setCountryFlag] = useState(localStorage.lang);

    const handleChange = (value, country) => {
        setCountryFlag(country.countryCode);
        props.setValues({
            ...props.values,
            [section]: {
                ...props.values[section],
                [name]: value
            }
        });
    };

    return (
        <Fragment>
            <div className="mb-4">
                <Form.Label
                    // si le composant a été touché et qu'il y a une erreur on le surligne en rouge
                    className={errors[section] && errors[section][name] && touched[section] && touched[section][name] ? "is-invalid-label" : null}>
                        <FormattedMessage id={label} defaultMessage="Not translated" />
                        {required && "*"}
                </Form.Label>
                {facultatif ? <small className="grey-small ml-2"><FormattedMessage id="general.optional" defaultMessage="Not translated" /></small> : null}
                <ReactPhoneInput
                    disabled={props.disabled ? props.disabled : false}
                    enableSearchField={true}
                    country={countryFlag}
                    value={props.values[section][name]}
                    onChange={(value, country) => {
                        handleChange(value, country)
                    }}
                    buttonStyle={buttonStyle}
                    containerStyle={containerStyle}
                    inputStyle={inputStyle}
                />
                {errors[section] && errors[section][name] && touched[section] && touched[section][name] && <p className="is-invalid mb-0">{<FormattedMessage id={errors[section][name]} defaultMessage="" />}</p>}
            </div>
        </Fragment>
    )
}

export default PhoneInput;
