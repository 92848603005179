import React from 'react';
import CandidatureComponent from './CandidatureComponent';

const CandidaturesMaper = ({ candidatures }) => {

    let setUnderlignes = (i, candidatures) => {
        if (i === 0 && candidatures.length > 1) {
            return ("underligne pb-4");
        } else if (i !== 0 && i < candidatures.length - 1) {
            return ("underligne pb-4 pt-4");
        } else if (candidatures.length === 1) {
            return ("");
        } else {
            return ("pt-4");
        }
    }

    return (
        <div>
            {candidatures.map((value, i) => {
                return (
                    <div key={i} className={setUnderlignes(i, candidatures)}>
                        <CandidatureComponent {...value} key={i} />
                    </div>
                )
            })}
        </div>
    )
}

export default CandidaturesMaper;