import gql from 'graphql-tag';

const GET_EXPERT = gql`
    query getExpert {
        getExpert {
            id
            wasAcceptedToMission
        }
    }
`;

export {GET_EXPERT};