import React from 'react';

const Rgpd = () => {
   
    return (
        <div className="p-5">
            <h1 className="mb-3">Mention d’information sur le traitement de vos données personnelles par CIVIPOL</h1>
            <p>CIVIPOL – 9 rue Notre Dame des Victoires 75002 PARIS</p>
            <p>Nous ne traiterons vos données que dans la mesure où cela est nécessaire à la bonne gestion de notre base de recrutement et de la mise en œuvre de nos projets, et ce après recueil de votre consentement.</p>
            <p>Veuillez noter que les réponses aux champs munies d’un astérisque sont obligatoires et nécessaires au traitement de votre demande d’inscription. L’absence de réponse à un champ obligatoire est susceptible de compromettre la création de votre profil.</p>
            <p>Vos données personnelles seront conservées pour une durée de 6 ans à compter de la date de fin du dernier projet auquel vous aurez participé, s’il y a un renouvellement annuel du consentement, et seront archivées de manière chiffrée en cas d’absence de renouvellement du consentement dans les 30 jours sauf si :</p>
            <p>- vous exercez votre droit de suppression des données vous concernant, dans les conditions décrites ci-après ;</p>
            <p>- une durée de conservation plus longue est autorisée ou imposée en vertu d’une disposition légale ou réglementaire.</p>
            <p><i>Après les durées de conservation et d'archivage visées ci-dessus, vos données personnelles seront définitivement supprimées.</i></p>
            <p>L'accès à vos données personnelles est strictement limité aux salariés du siège et, le cas échéant, à nos sous-traitants. Les sous-traitants en question* sont soumis à une obligation de confidentialité et ne peuvent utiliser vos données qu’en conformité avec nos dispositions contractuelles et la législation applicable. Nous nous engageons à ne pas vendre, louer, céder ni donner accès à des tiers à vos données sans votre consentement préalable, à moins d’y être contraints en raison d’un motif légitime (obligation légale, lutte contre la fraude ou l’abus, exercice des droits de la défense, etc.).</p>
            <p>Conformément à la loi « informatique et libertés » du 6 janvier 1978 modifiée et au Règlement européen n°2016/679/UE du 27 avril 2016 (applicable depuis le 25 mai 2018), vous bénéficiez d’un droit d’accès, de rectification, de portabilité et d’effacement de vos données ou encore de limitation du traitement. Vous pouvez également, pour des motifs légitimes, vous opposer au traitement des données vous concernant. Vous pouvez, sous réserve de la production d’un justificatif d’identité valide, exercer vos droits en contactant l’adresse mail suivante : contact@civipol.fr .</p>
            <p>Civipol participe et est conforme à l'ensemble des Spécifications et Politiques du Transparency & Consent Framework de l'IAB Europe. [Il/Elle] utilise la Consent Management Platform n°92.<br/><br/>Vous pouvez modifier vos choix à tout moment 
            en<button id="rgpdButton" type="button" onClick={(e) => {e.preventDefault(); window.Sddan.cmp.displayUI();}} ><span>cliquant ici</span></button></p>
            <p><strong>Dépôt de cookies par Sirdata</strong><br/><br/>
            Sirdata est une entreprise de data marketing qui permet à ses Clients d'adresser aux Utilisateurs des offres pertinentes adaptées à leurs centres d'intérêt.<br/>
            Les Données collectées par Sirdata sont conservées pour une durée maximale de 365 jours, selon la finalité du traitement, conformément aux lois en vigueur et principe de minimisation.<br/>
            En savoir plus : <a href='https://www.sirdata.com/vie-privee/' target='_blank' rel="noopener noreferrer" >https://www.sirdata.com/vie-privee/</a> souhaitez désactiver la collecte de vos données par Sirdata : <a href='https://www.sirdata.com/opposition/' target='_blank' rel="noopener noreferrer">https://www.sirdata.com/opposition/</a>
             </p>
            <p>Contact pour les questions relatives à la procédure RGPD : contact@civipol.fr</p>
            <p>Pour toute information complémentaire ou réclamation, vous pouvez contacter la Commission Nationale de l’Informatique et des Libertés (plus d’information sur​ <a href="​www.cnil.fr​">​www.cnil.fr​</a>  )</p>
            <p>* Identification du sous-traitant : WASSA – 5 rue de l’Eglise 92100 BOULOGNE-BILLANCOURT</p>
        </div>
    );

};

export default Rgpd;