import React, { createContext, useReducer } from 'react';
import graphql_tools from '../utils/graphql-tools.js';
import object_format from '../utils/object-format.js';
const initialState = {
    expert: null,
    status: null,
    img_status: true,
    state: {
        status: false,
        update: true,
    },
}

const ExpertContext = createContext(initialState);

const reducer = (state, action) => {
    const { target } = action;
    switch (action.type) {
        case 'getExpert': {
            return {
                ...state,
                expert: target
            }
        }
        case 'setInSuppDoc': {

            return {
                ...state,
                expert: {
                    ...state.expert,
                    supporting_documents: target

                }
            }
        }
        case 'setImage': {
            const { target } = action;
            return {
                ...state,
                expert: {
                    ...state.expert,
                    identity: {
                        ...state.expert.identity,
                        profile_pic: target
                    }
                }
            }
        }
        case 'connectUser': {
            return {
                ...state,
                status: action.target
            }
        }
        case 'logout': {
            return {
                ...state,
                status: action.target
            }
        }
        case 'resetExpert': {
            return {
                state
            }
        }
        case 'setExpert': {
            return {
                ...state,
                expertID: action.target
            }
        }
        case 'updateExpert': {
            return {
                ...state,
                update: action.target
            }
        }
        case 'getStatus': {
            return {
                ...state,
                status: action.target
            }
        }
        case 'setImgeStatus': {
            return {
                ...state,
                img_status: action.target
            }
        }
        case 'setIdentity': {
            return {
                ...state,
                expert: {
                    ...state.expert,
                    identity: action.target
                }
            }
        }
        case 'updateIdentityAfterFirstUpload': {
            let ice = action.target.ICE
            return {
                ...state,
                expert: {
                    ...state.expert,
                    ICE: ice,
                    identity: action.target
                }
            }
        }
        default:
            return state
    }
}

const ExpertProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const resetExpert = () => {
        dispatch({ type: "resetExpert", target: null })
    }

    const getExpert = (infos) => {
        let values = infos;
        const arrayOfObjsKey = [
            "ICE",
            "expert_ability", 
            "languages", 
            "skills", 
            "supporting_documents", 
            "medicalInformations", 
            "personalInformations",
            "wasAcceptedToMission",
            "needs_renewal_consent"
        ];

        let identity = {};
        for (var value in values) {
            if (!arrayOfObjsKey.includes(value) && value !== "__typename") {
                identity = {
                    ...identity,
                    [value]: values[value]
                }
                delete values[value];
            }
        }
        values = {
            ...values,
            identity
        }
        if (values.identity.countries) {
            values.identity.countries = JSON.parse(values.identity.countries);
        }
        if (values.identity.discovered) {
            values.identity.discovered = JSON.parse(values.identity.discovered);
        }
        if (values.identity.mission_duration) {
            values.identity.mission_duration = JSON.parse(values.identity.mission_duration);
        }
        graphql_tools.deleteTypeName(values);
        object_format.nullToString(values);
        if (values.ICE === "") {
            values.ICE = {
                first_name: "",
                last_name: "",
                address_number_street: "",
                address_postal_code: "",
                address_city: "",
                address_country: "",
                telephone: "",
                email: "",
                discovered: "",
            }
        }
        if (values.languages.length !== 3) {
            const objLang = { language: "", level: "" };
            let nb = values.languages.length;
            for (let i = nb; i < 3; i++) {
                values.languages.push((objLang))
            }
        }
        delete values.identity.status;
        values.identity.infosExacts = true;
        values.identity.criminal_conviction = true;
        dispatch({ type: 'getExpert', target: { ...values } })
        return new Promise(resolve => {
            resolve(values);
        })
    }

    const setInSupportDocuments = (values) => {
        let { supporting_documents } = values;
        for (let i = 0; i < supporting_documents.length; i++) {
            delete supporting_documents[i].__typename
        }
        dispatch({ type: "setInSuppDoc", target: supporting_documents })
    }

    const setImage = (value) => {
        dispatch({ type: "setImage", target: value });
    }

    const connectUser = () => {
        dispatch({ type: "connectUser", target: true })
    }
    const logout = () => {
        dispatch({ type: "connectUser", target: false })
    }

    const setExpert = (expertID) => {
        dispatch({ type: "setExpert", target: expertID })
    }

    const getError = (error) => {
        dispatch({ type: 'getError', target: error })
    }

    const getStatus = (value) => {
        dispatch({ type: "getStatus", target: value })
    }

    const updateExpert = () => {
        dispatch({ type: "updateExpert", target: !state.update })
    }

    const setImgeStatus = (data) => {
        dispatch({ type: "setImgeStatus", target: data })
    }

    const setIdentity = (data) => { dispatch({ type: "setIdentity", target: data }) }

    const updateIdentityAfterFirstUpload = (data) => {
        graphql_tools.deleteTypeName(data);
        object_format.nullToString(data);
        delete data.status;
        delete data.id;

        dispatch({ type: "updateIdentityAfterFirstUpload", target: data })
    }
    return (
        <ExpertContext.Provider
            value={{
                state,
                update: state.update,
                methods: {
                    getExpert: getExpert,
                    getError: getError,
                    setInSupportDocuments: setInSupportDocuments,
                    setImage: setImage,
                    connectUser: connectUser,
                    logout: logout,
                    resetExpert: resetExpert,
                    setExpert: setExpert,
                    updateExpert: updateExpert,
                    getStatus: getStatus,
                    setImgeStatus: setImgeStatus,
                    setIdentity: setIdentity,
                    updateIdentityAfterFirstUpload: updateIdentityAfterFirstUpload
                }
            }}>
            {children}
        </ExpertContext.Provider>
    )
}

export { ExpertContext, ExpertProvider }