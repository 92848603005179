import gql from 'graphql-tag'

const UPSERT_EXPERT = gql`
    mutation upSertExpert($input: Update_Input!){
        upSertExpert(input: $input){
        firstName
        lastName
        nationality_1
        nationality_2
        birthday
        address_1_number_street
        address_1_postal_code
        address_1_city
        address_1_country
        address_2_number_street
        address_2_postal_code
        address_2_city
        address_2_country
        tel_mobile
        tel_fix
        civility
        email_1
        email_2
        mission_duration
        criminal_conviction
        notificationActus
        notificationOffers
        infosExacts
        profile_pic
        status
        ICE{first_name last_name address_number_street address_postal_code address_city address_country telephone email}
        languages {language level}
        supporting_documents{type number link path name}
        expert_ability {fonctionnaire fonc_level DG accept_salarial_contract accept_service_contract last_remuneration since }
        skills{type year_experience diploma}
        }
    }
`;

const UPSERT_IDENTITY = gql`
    mutation  upSertIdentity($input: IdentityInput!){
        upSertIdentity(input: $input){
            id
            profile_pic
            status
            validationIdentity
            firstName
            lastName
            nationality_1
            nationality_2
            birthday
            civility
            address_1_number_street
            address_1_postal_code
            address_1_city
            address_1_country
            address_2_number_street
            address_2_postal_code
            address_2_city
            address_2_country
            tel_mobile
            tel_fix
            email_1
            email_2
            ICE{first_name last_name address_number_street address_postal_code address_city address_country telephone email}
            mission_duration
            criminal_conviction
            notificationActus
            notificationOffers
            infosExacts
        }
    }
`;

const UPSERT_MISSION_SEARCHED = gql`
    mutation  upSertMissionSearched($mission_duration: String!){
        upSertMissionSearched(mission_duration: $mission_duration){
            id
            status
            validationMissionSearched
        }
    }
`;

const UPSERT_ABILITIES = gql`
    mutation upSertExpertAbilities($input: AbilitiesInput!){
        upSertExpertAbilities(input: $input){
            id
            status
            validationExpertAbilities
        }
    }
`;
const UPSERT_CV = gql`
    mutation upsertCV($input: UpsertCVInputs!){
        upsertCV(input: $input){
            id
            status
            supporting_documents{type number link path name extension}
            validationCV
            notificationActus
            notificationOffers
            discovered
        }
    }
`;

const UPSERT_SUPP_DOC = gql`
    mutation upsertSupportingDocuments($input: SupportingDocumentInputs!){
        upsertSupportingDocuments(input: $input){
            id
            status
            supporting_documents{type number link path name extension}
            validationSupportingDocuments
        }
    }
`;

const UPSERT_CONFIRMATION = gql`
    mutation   upsertConfirmation($input: ConfirmationInput!){
        upsertConfirmation(input: $input){
            id
            status
            validationConfirmation
        }
    }
`;

const UPDATE_PASSWORD = gql`
    mutation modifyPassword($oldPassword: String!, $newPassword: String!, $newPasswordConfirmation: String!){
        modifyPassword(oldPassword: $oldPassword, newPassword: $newPassword, newPasswordConfirmation: $newPasswordConfirmation){
            id
        }
    }
`;

const DELETE_EXPERT = gql`
    mutation deleteExpert($expertID: ID) {
        deleteExpert(expertID: $expertID) {
            id
        }
    }
`;

const RENEW_CONSENT = gql`
    mutation acceptRenewalConsent{
        acceptRenewalConsent {
            id
        }
    }
`;

export {
    UPSERT_EXPERT,
    UPSERT_IDENTITY,
    UPSERT_MISSION_SEARCHED,
    UPSERT_ABILITIES,
    UPSERT_CV,
    UPSERT_SUPP_DOC,
    UPSERT_CONFIRMATION,
    UPDATE_PASSWORD,
    DELETE_EXPERT,
    RENEW_CONSENT
}