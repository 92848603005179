import React from 'react';
import { TranslateContextWrapper } from '../Contexts/TranslateContext.jsx';
import { FormattedMessage } from 'react-intl';

const LogoCivipolBaseExperts = (props) => {
    return (
        <div className="d-flex flex-column align-items-center">
            <a id="logo-civipol" href="/authentication/signin">
                <img className="img-fluid"
                    src={require("../Static/Images/logo-civipol-expert-white.svg")}
                    alt='logo'
                />
            </a>
            <div id='base-experts' className="d-flex justify-content-center text-uppercase font-weight-bold">
                <p className="mb-0">
                    <FormattedMessage id='general.baseexperts' defaultMessage="Not translated"/>
                </p>
            </div>
        </div>
    );
}

export default TranslateContextWrapper(LogoCivipolBaseExperts);
