import React from 'react';
import ConfirmationMailNotification from "./ConfirmationMailNotification";
import RenewalNotification from "./RenewalNotification";
import { withCookies } from 'react-cookie';

const typeOfNotif = [
    "confirmation_email"
];

const NotificationComponent = ({ allCookies, expert }) => {


    if (expert.needs_renewal_consent) {
        return <RenewalNotification />
    }

    if (!allCookies.notification) return null;

    if (allCookies.notification) {
        if (!typeOfNotif.includes(allCookies.notification)) return null;

        if (allCookies.notification === "confirmation_email") {
            return <ConfirmationMailNotification />
        }
    }
}

export default withCookies(NotificationComponent);