import gql from "graphql-tag";

const REINIT_CREATION_TOKEN = gql`
    mutation ReinitCreationToken($accountCreationToken: ID!){
        ReinitCreationToken(accountCreationToken: $accountCreationToken){
            id
        }
    }
`;

export {REINIT_CREATION_TOKEN};