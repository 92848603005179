import React, { useState, useContext, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form } from "react-bootstrap";
import { Formik } from 'formik';
import { Mutation } from "react-apollo";
import { ExpertContext } from "../../Contexts/ExpertContext";
import { UPSERT_SUPP_DOC } from "./Mutations";
import SubmitButton from './SubmitButton';
import { toUpdloadSchema } from './schema.toUpload';
import { TranslateContextWrapper } from '../../Contexts/TranslateContext.jsx';
import ModificationAlert from '../Alerts/ModificationAlert';
import DocumentContainer from './documentsToUpload.Container';
import { ValidationContext } from "../../Contexts/ValidationContext";
import LoadingAlert from "../Alerts/LoadingAlert";
import useCollapse from "react-collapsed";
import { CollapseContext } from "../../Contexts/CollapseContext";
import { Element } from 'react-scroll';
import { scrollTo } from "./utils";

const DocumentsToUploadComponent = (props) => {

    //tableau des clés de traduction des supporting documents
    const documents = [
        "ID",
        "Diploma",
        "Certificat",
        "AutorNotCumulActivity",
        "VitalCard",
        "DriveLicence",
        "KBIS",
        "Siret",
        "CriminalRecord",
        "RCAttestation",
        "TransportCertificate",
        "MedicalSuitability",
        "SalarySlip",
        "PensionSlip",
        "MutualInsuranceCertificat",
        "ResidencePermit"
    ];

    return (
        <Fragment>
            <Form.Label className={props.errors.supporting_documents && (props.errors.supporting_documents.documents) ? "is-invalid-label" : null}>
                <p className="mb-0"><FormattedMessage id='profile.sections.documentToUpload.subtitle' defaultMessage="Not translated" /></p>
            </Form.Label>
            {(props.errors.supporting_documents && props.errors.supporting_documents.documents) ? <p className="is-invalid">{props.errors.supporting_documents.documents}</p> : null}
            <p className="mb-0">
                <small className="grey-small"><FormattedMessage id='profile.sections.documentToUpload.advice' defaultMessage="Not translated" /></small>
            </p>
            {documents.map((value, key) => {
                return (
                    <DocumentContainer value={value} key={key} keyInput={key} formik={props} />
                )
            })}
        </Fragment>
    )

}


const DocumentsToUpload = (props) => {
    const { state, methods } = useContext(ExpertContext);
    const [alert, openAlert] = useState(false)
    const [alertUpload, openAlertUpload] = useState(false)

    const validationContext = useContext(ValidationContext);
    const collapseContext = useContext(CollapseContext);
    const { getToggleProps, getCollapseProps } = useCollapse({ isOpen: collapseContext.state.supporting_documents });

    return (
        <Mutation
            mutation={UPSERT_SUPP_DOC}
            onCompleted={(data) => {
                openAlert(true);
                if (data.upsertSupportingDocuments) {
                    methods.setInSupportDocuments(data.upsertSupportingDocuments);
                }
                validationContext.methods.setValidation({ name: "supporting_documents", target: data.upsertSupportingDocuments.validationSupportingDocuments });
            }}
            onError={({graphQLErrors}) => {console.log(graphQLErrors)}}
        >
            {(docToUploadCallback, { error, loading }) => {
                openAlertUpload(loading)

                return (
                    <Formik
                        initialValues={state.expert}
                        onSubmit={values => {
                            docToUploadCallback({ variables: { input: { supporting_documents: values.supporting_documents }, expertID: state.expertID } })
                        }}
                        enableReinitialize
                        validationSchema={toUpdloadSchema(props.translateContext)}
                        render={formikBag => {
                            return (
                                <Element name="supporting_documents">
                                    <ModificationAlert alert={alert} openAlert={openAlert} />
                                    {loading && <LoadingAlert alert={alertUpload} openAlert={openAlertUpload} />}
                                    <div className={(collapseContext.state.supporting_documents) ? "profile-container mt-3" : "profile-container underligne mt-3"}>
                                        <div className="row flex-column flex-md-row mb-3">
                                            <div className="col-12 col-md-9 mb-2 mb-md-0">
                                                <div className="d-flex align-items-center justify-content-start">
                                                    <div className="number-menu mr-2 flex-shrink-0">6</div>
                                                    <h1 className="mb-0 px-0"><FormattedMessage id='profile.sections.documentToUpload.title' defaultMessage="Not translated" /></h1>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md">
                                                <div className="d-flex justify-content-start justify-content-md-end">
                                                    <button className="btn btn-signin" {...getToggleProps({
                                                        onClick: () => {
                                                            collapseContext.methods.setCollapse("supporting_documents", !collapseContext.state.supporting_documents);
                                                            window.scrollTo(0, collapseContext.state.yPos);
                                                        }
                                                    })} >
                                                        <FormattedMessage id="general.modify" defaultMessage="Not translated" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <section name="supporting_documents" {...getCollapseProps()} onTransitionEnd={(e) => {
                                            for (let values in collapseContext.state) {
                                                if (collapseContext.state[values] === true && e.target.getAttribute('name') === values) {
                                                    e.target.style.height = ""
                                                    scrollTo(values);
                                                }
                                            }
                                        }}>
                                            <Form onSubmit={formikBag.handleSubmit}>
                                                <div className="profile-sections to-upload">
                                                    <DocumentsToUploadComponent {...formikBag} />
                                                </div>
                                                <div className="confirmation-container">
                                                    <SubmitButton {...formikBag} />
                                                </div>
                                            </Form>
                                        </section>
                                    </div>
                                </Element>
                            )
                        }}
                    />
                )
            }}
        </Mutation>
    )
}

export default TranslateContextWrapper(DocumentsToUpload);
