import React from 'react';
import TemplateEvents from '../Templates/TemplateEvents';

/**
 * page of confirmation of mail inscription is sent
 */

export const MailInscription = () => {
    let elems = {
        img: 'illu_no-result.svg',
        title: "validation.confirmationMailSentTitle",
        msges: ["validation.confirmationMailSent1", "validation.confirmationMailSent2"],
    };
    return (
        (window.location.pathname === "/authentication/mail_inscription") ?
            <TemplateEvents {...elems} />
            :
            null
    )
}