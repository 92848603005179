import React, { useContext } from 'react';
import MissionComponent from './MissionComponent';
import { MissionsContext } from '../../Contexts/MissionsContext';
import { FormattedMessage } from 'react-intl';

const InMissionComponent = ({ inMission, all }) => {
    if (inMission.length === 0) return null
    else return (
        <div className="mb-5">
            <h3 className="text-uppercase"><FormattedMessage id="missions.missionsInProgress" defaultMessage="Not translated"/></h3>
            {inMission.map((value, i) => {
                if (all)
                    return <div key={i} className={(i < inMission.length - 1) ? "bottom-underligne" : ""}><MissionComponent {...value} /></div>
                else {
                    if (i < 5) {
                        return <div key={i} className={(i < inMission.length - 1) ? "bottom-underligne" : ""}><MissionComponent {...value} /></div>
                    } else return null
                }
            })}
        </div>
    )
}

const FinishedComponent = ({ finished, all }) => {
    if (finished.length === 0) return null
    else return (
        <div className="mb-5">
            <h3 className="text-uppercase"><FormattedMessage id="missions.missionFinished" defaultMessage="Not translated"/></h3>
            {finished.map((value, i) => {
                if (all)
                    return <div key={i} className={(i < finished.length - 1) ? "bottom-underligne" : ""}><MissionComponent {...value} /></div>
                else {
                    if (i < 5) {
                        return <div key={i} className={(i < finished.length - 1) ? "bottom-underligne" : ""}><MissionComponent {...value} /></div>
                    } else return null
                }
            })}
        </div>
    )
}

const AcceptedButNotStartComponent = ({ acceptedButNotStart, all }) => {
    if (acceptedButNotStart.length === 0) return null
    else return (
        <div className="mb-5">
            <h3 className="text-uppercase"><FormattedMessage id="missions.missionNotStarted" defaultMessage="Not translated"/></h3>
            {acceptedButNotStart.map((value, i) => {
                if (all)
                    return <div key={i} className={(i < acceptedButNotStart.length - 1) ? "bottom-underligne" : ""}><MissionComponent {...value} /></div>
                else {
                    if (i < 5) {
                        return <div key={i} className={(i < acceptedButNotStart.length - 1) ? "bottom-underligne" : ""}><MissionComponent {...value} /></div>
                    } else return null
                }
            })}
        </div>
    )
}

const MissionsMaper = (props) => {
    const { state } = useContext(MissionsContext);
    const { finished, inMission, acceptedButNotStart } = { ...state.missions };
    return (
        <div>
            <InMissionComponent inMission={inMission} {...props} />
            <FinishedComponent finished={finished} {...props} />
            <AcceptedButNotStartComponent acceptedButNotStart={acceptedButNotStart} {...props} />
        </div>
    )
}

export default MissionsMaper;
