import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const ColoredButton = ({ msge, link }) => {
    return (
        <Link to={link} className='btn btn-civipol'>
            <FormattedMessage id={msge} defaultMessage="Not translated"/>
        </Link>
    )
}

const WhiteButton = ({ msge, link }) => {
    return (
        <p style={{ fontFamily: "DIN-BOLD" }} className="mb-0">
            <Link to={link} className="btn btn-signin">
                <FormattedMessage id={msge} defaultMessage="Not translated"/>
            </Link>
        </p>
    )
}

const WhiteAndBlueButton = ({ msge, link }) => {
    return (
        <p style={{ fontFamily: "DIN-BOLD"}}>
            <Link to={link} className="btn btn-footer">
                <FormattedMessage id={msge} defaultMessage="Not translated"/>
            </Link>
        </p>
    )
}



export { WhiteButton, ColoredButton, WhiteAndBlueButton }
