import React, {useEffect} from "react";
import gql from 'graphql-tag';
import {withRouter, Redirect} from "react-router-dom";
import client from "../ApolloSetup";

const logoutAsync = async () => {
    await client.mutate({mutation: gql `mutation{
        logout{
            description
        }
    }`});
}

const Logout = () => {
    localStorage.clear();
    useEffect(() => {
        logoutAsync();
    })

    return <Redirect to="/authentication/signin"></Redirect>;
}

export default withRouter(Logout);
