import React, { createContext, useReducer } from "react";

const initialState = {
    arrayEvaluations: [],
    isLoading: true,
    isError: false,

}

const arrayEvaluationsContext = createContext(initialState);

const reducer = (state, action) => {
    const {type} = action;

    switch (type) {
        case "setArray" : {
            return {
                ...state,
                arrayEvaluations: action.target,
                isLoading: false
            }
        }
        case "setError": {
            return {
                ...state,
                isError: true,
                isLoading: false
            }
        }
        default: return state
    }
}

const ArrayEvaluationsProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <arrayEvaluationsContext.Provider value={{ state, dispatch }}>
            {children}
        </arrayEvaluationsContext.Provider>
    )
}

export {arrayEvaluationsContext, ArrayEvaluationsProvider};