import React, { createContext, useReducer } from "react";

const initialState = {
    missions: {
        candidatures: [],
        acceptedButNotStart: [],
        finished: [],
        inMission: [],
    },
    loading: true,
    update: false,
}

const MissionsContext = createContext(initialState);

const MissionReducer = (state, action) => {

    const { target } = action;

    switch (action.type) {
        case 'setMissions': {
            return { ...state, missions: target, loading: false }
        }
        case 'update': {
            return { ...state, update: !target }
        }
        default: return { state };
    }
}

const MissionsProvider = ({ children }) => {
    const [state, dispatch] = useReducer(MissionReducer, initialState);

    const setMissions = (e) => {
        let candidatures = [], acceptedButNotStart = [], finished = [], inMission = [];
        for (let i = 0; i < e.length; i++) {
            if (e[i].staffed === "Postule")
                candidatures.push(e[i]);
            if (e[i].staffed === "Agreed")
                acceptedButNotStart.push(e[i])
            if (e[i].staffed === "Terminated" || e[i].staffed === "Untied")
                finished.push(e[i])
            if (e[i].staffed === "InMission")
                inMission.push(e[i])
        }
        dispatch({ type: "setMissions", target: { candidatures, acceptedButNotStart, finished, inMission } })
    }
    const update = () => {dispatch({ type: "update", target: state.update})}

    return (
        <MissionsContext.Provider
            value={{
                state,
                methods: {
                    setMissions: setMissions,
                    update: update,
                }
            }}
        >
            {children}
        </MissionsContext.Provider>
    )
}

export { MissionsProvider, MissionsContext };