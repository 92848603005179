const graphql_tools = {
    
    deleteTypeName: (expertInfos) => {
        let tmp = expertInfos;
        for (let value in tmp) {
            if (value === "__typename") {
                delete tmp[value];
            }
            if (typeof tmp[value] === "object") {
                if (tmp[value] && tmp[value]['__typename']) {
                    delete tmp[value]['__typename']
                }
            }
            if (Array.isArray(tmp[value])) {
                for (let i = 0, count = tmp[value].length; i < count; i++) {
                    if (tmp[value][i] && tmp[value][i]['__typename']) {
                        delete tmp[value][i]['__typename']
                    }
                }
            }
        }
        return tmp;
    }
}

export default graphql_tools;