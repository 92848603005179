import React from "react";
import { FormattedMessage } from "react-intl";

const KitDocumentation = () => {
    return (
        <div className="container-fluid d-flex justify-content-between notifications position-relative mb-4">
            <div className="w-100 d-flex flex-column flex-lg-row justify-content-start align-items-center">
                <img src={require("../../Static/Illustrations/illu_candidatures.svg")} alt="illu_profile" style={{ maxHeight: "140px", maxWidth: "140px" }} className="img-fluid mb-4 mb-lg-0 mr-lg-5" />
                <div className="text-lg-left">
                    <a className="btn btn-signin mr-2" target="__blanck" rel="noopener noreferrer" href={process.env.REACT_APP_API_URL + "/public/kit-documentation/annuaire_Civipol.pdf"}><FormattedMessage id="tools.documentationKit.directory" defaultMessage="Not translated" /></a>
                    <a className="btn btn-signin mr-2" target="__blanck" rel="noopener noreferrer" href={process.env.REACT_APP_API_URL + "/public/kit-documentation/CIVPI18_FichePrincipal_WEB.pdf"}><FormattedMessage id="tools.documentationKit.mainForm" defaultMessage="Not translated" /></a>
                    <a className="btn btn-signin" target="__blanck" rel="noopener noreferrer" href={process.env.REACT_APP_API_URL + "/public/kit-documentation/CIVPI18_FT_id_WEB_.pdf"}><FormattedMessage id="tools.documentationKit.identity" defaultMessage="Not translated" /></a>
                    <hr />
                    <a className="btn btn-signin mr-2" target="__blanck" rel="noopener noreferrer" href={process.env.REACT_APP_API_URL + "/public/kit-documentation/CIVPI18_FT_administration_WEB.pdf"}><FormattedMessage id="tools.documentationKit.administration" defaultMessage="Not translated" /></a>
                    <a className="btn btn-signin" target="__blanck" rel="noopener noreferrer" href={process.env.REACT_APP_API_URL + "/public/kit-documentation/CIVPI18_FT_flux_bat_WEB.pdf"}><FormattedMessage id="tools.documentationKit.flowControl" defaultMessage="Not translated" /></a>
                    <hr />
                    <a className="btn btn-signin mr-2" target="__blanck" rel="noopener noreferrer" href={process.env.REACT_APP_API_URL + "/public/kit-documentation/CIVPI18_FT_partenaire_WEB.pdf"}><FormattedMessage id="tools.documentationKit.partnership" defaultMessage="Not translated" /></a>
                    <a className="btn btn-signin" target="__blanck" rel="noopener noreferrer" href={process.env.REACT_APP_API_URL + "/public/kit-documentation/CIVPI18_FT_territoriale_WEB.pdf"}><FormattedMessage id="tools.documentationKit.territorial" defaultMessage="Not translated" /></a>
                    <hr />
                    <a className="btn btn-signin mr-2" target="__blanck" rel="noopener noreferrer" href={process.env.REACT_APP_API_URL + "/public/kit-documentation/Organigramme.jpg"}><FormattedMessage id="tools.documentationKit.organigram" defaultMessage="Not translated" /></a>
                    <a className="btn btn-signin" target="__blanck" rel="noopener noreferrer" href={process.env.REACT_APP_API_URL + "/public/kit-documentation/Rapport_d'activite_2018.pdf"}><FormattedMessage id="tools.documentationKit.activityReport" defaultMessage="Not translated" /></a>
                    <hr />
                    <a className="btn btn-civipol" target="__blanck" href={process.env.REACT_APP_API_URL + "/public/kit-documentation/DocumentationKit.zip"} rel="noopener noreferrer"><FormattedMessage id="tools.documentationKit.allKit" defaultMessage="Not translated" /></a>
                </div>
            </div>
        </div>
    )
}

export default KitDocumentation;