import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import * as moment from "moment";
import ScrollUpButton from "react-scroll-up-button";

const OfferComponent = (props) => {

    const { value } = props;
    const { title, job_expertise, mission_length, application_expiration, geographical_zone, id } = value;
    const { lang } = localStorage;
    let mission_duration = application_expiration;
    let img;
    if (job_expertise.id_taxo === "50") {
        img = require("../../Static/Images/theme-72.jpg")
    }
    if (job_expertise.id_taxo === "51") {
        img = require("../../Static/Images/theme-73.jpg")
    }
    if (job_expertise.id_taxo === "106") {
        img = require("../../Static/Images/theme-75.jpg")
    }

    return (
        <ScrollUpButton StopPosition={0} >
            <Link to={{ pathname: "/expert/offers/" + id, state: value }} style={{ textDecoration: "none" }}>
                <div className="row d-flex mb-3">
                    <div  className="col-12 col-md-4 order-1 order-md-0">
                        <img style={{maxHeight: "200px"}}  className="img-fluid" src={img} alt="img-offers" />
                    </div>
                    <div className="col-12 col-md-8 order-0 order-md-1 mb-4 mb-md-0">
                        <div className="row flex-column">
                            <div className="col">
                                <span className='theme-container mb-3'>
                                    {job_expertise[lang]}
                                </span>
                                <h5 style={{fontSize: '25px!important', fontFamily: "DIN-LIGHT", color: "#0F4D95" }} className="text-uppercase" >{title[lang]}</h5>
                            </div>
                            <div className="col">
                                <div className="d-flex offers-infos">
                                    <div className="mr-3">
                                        <h3 className="text-uppercase"><FormattedMessage id="offers.missionDuration" defaultMessage="Not translated"/></h3>
                                        <p>
                                            {mission_length[lang]}
                                        </p>
                                    </div>
                                    <div className="mr-3">
                                        <h3 className="text-uppercase"><FormattedMessage id="offers.candidatureLimit" defaultMessage="Not translated"/></h3>
                                        <p>
                                            {moment(mission_duration.replace(/"/g, "")).format("DD/MM/YYYY")}
                                        </p>
                                    </div>
                                    <div className="mr-3">
                                        <h3 className="text-uppercase"><FormattedMessage id="offers.geoZone" defaultMessage="Not translated"/></h3>
                                        <p>
                                            {geographical_zone && geographical_zone[lang] ? geographical_zone[lang] : "NON RENSEIGNÉ"}
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </ScrollUpButton>
    )
}

export default OfferComponent;