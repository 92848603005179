import React from "react";
import { createUploadLink } from "apollo-upload-client";
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as MyNewProvider } from "@apollo/react-hooks";

const httpLink = createUploadLink({
    uri: process.env.REACT_APP_API_URL,
    credentials: "include",
})

//vanilla js logout function
const logoutManually = async () => {
    await client.mutate({
        mutation: gql`mutation{
            logout{
                description
            }
        }
    `});
    window.location.replace(`/authentication/signin`);
}

// Then the error link (for now it just logs the error)
const errorLink = onError(({ response, graphQLErrors, networkError, invalidArgs }) => {
    if (graphQLErrors) {
        graphQLErrors.map(async ({ message, locations, path, extensions }) => {
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
            if (path && path[0] !== 'confirmSignup' && extensions && extensions.code && extensions.code === "UNAUTHENTICATED") {
                await logoutManually();
            }
        });
    }
    if (invalidArgs) {
        console.log('[ARGS ERROR]', invalidArgs)
    }

    if (networkError) {
        console.log("[network error] => ", networkError);
    }
});

// Queries options
const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
}

// Final link
const link = ApolloLink.from([
    errorLink,
    httpLink,
]);

// Create the Apollo client to make GraphQL requests
const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions,
});

export default client;

export const MyApolloProvider = ({ children }) => {
    return (
        <ApolloProvider client={client}>
            <MyNewProvider client={client}>
                {children}
            </MyNewProvider>
        </ApolloProvider>
    )
}