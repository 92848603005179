import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactPhoneInput from 'react-phone-input-2';
import { buttonStyle, containerStyle, inputStyle } from '../Identity/PhoneInput';
import Select from 'react-select';
import { customStyles } from '../Selector';
import * as countries from "i18n-iso-countries";
import { TranslateContextWrapper } from '../../../Contexts/TranslateContext.jsx';


const InvalidFeedBackICE = ({ formikProps, index, value }) => {
    if (
        (!formikProps.errors || !formikProps.errors.ICE || !formikProps.errors.ICE[index] || !formikProps.errors.ICE[index][value])
        || (!formikProps.touched || !formikProps.touched.ICE || !formikProps.touched.ICE[index] || !formikProps.touched.ICE[index][value])
    ) return null;
    else {
        return (
            <div className="is-invalid">
                <FormattedMessage id={formikProps.errors.ICE[index][value]} />
            </div>
        );
    }
};

const PhoneInputICE = ({
    formikProps,
    counter,
    translationPath,
    required
}) => {
    const [countryFlag, setCountryFlag] = useState(localStorage.lang);


    const handleChange = (value, country) => {
        setCountryFlag(country.countryCode);

        formikProps.setValues({
            ...formikProps.values,
            ICE: formikProps.values.ICE.map((obj, key) => key === counter ? { ...obj, telephone: value } : obj)
        });
    };

    return (
        <div className="mb-4 form-group">
            <label htmlFor={`ICE[${counter}].telephone`} className={formikProps.errors && formikProps.errors.ICE && formikProps.errors.ICE[counter] && formikProps.errors.ICE[counter].telephone ? 'is-invalid' : ""}><FormattedMessage id={`${translationPath}.telephone`} />{required && "*"}</label>
            <ReactPhoneInput
                enableSearchField={true}
                country={countryFlag}
                value={formikProps.values.ICE[counter].telephone}
                onChange={(value, country) => {
                    handleChange(value, country);

                }}
                buttonStyle={buttonStyle}
                containerStyle={containerStyle}
                inputStyle={inputStyle}
            />
            {
                (formikProps.errors && formikProps.errors.ICE && formikProps.errors.ICE[counter] && formikProps.errors.ICE[counter].telephone) &&
                <div className="is-invalid">
                    <FormattedMessage id='validation.notvalid' />
                </div>
            }
        </div>
    );
};

const CountrySelectorICE = ({
    formikProps,
    counter,
    translateContext,
    translationPath,
    required
}) => {
    const arrayCountries = Object.values(countries.getNames("fr"));
    let formattedArray = arrayCountries.map(country => ({ value: country, label: country }));
    formattedArray.unshift({ value: 'cancel', label: translateContext.translateMessage('general.cancelSelection') });

    const handleChange = ({ label }) => {
        formikProps.setValues({
            ...formikProps.values,
            ICE: formikProps.values.ICE.map((obj, key) => key === counter ? { ...obj, address_country: label } : obj)
        });
    };

    return (
        <div className="mb-4 form-group">
            <label htmlFor={`ICE[${counter}].address_country`} className={formikProps.errors && formikProps.errors.ICE && formikProps.errors.ICE[counter] && formikProps.errors.ICE[counter].address_country ? 'is-invalid' : ""}><FormattedMessage id={`${translationPath}.addressCountry`} />{required && "*"}</label>
            <Select
                name={`ICE[${counter}].address_country`}
                styles={customStyles}
                options={formattedArray}
                onChange={handleChange}
                placeholder={translateContext.translateMessage('general.selector.countryPlaceholder')}
                value={{ label: formikProps.values.ICE[counter].address_country, value: formikProps.values.ICE[counter].address_country }}
            />
            {
                (formikProps.errors && formikProps.errors.ICE && formikProps.errors.ICE[counter] && formikProps.errors.ICE[counter].address_country) &&
                <div className="is-invalid">
                    <FormattedMessage id='validation.notvalid' />
                </div>
            }
        </div>
    );
};

const PersonsToContact = ({ formikProps, ICEinputs, translateContext }) => {

    const [ICES, setICES] = useState(formikProps.values.ICE);
    const translationPath = 'profile.sections.individualsInformations.personsToContact.inputs';

    const addIces = () => {
        if (ICES.length < 3) {
            setICES([...formikProps.values.ICE, ICEinputs])
            formikProps.values.ICE.push(ICEinputs);
        }
    };

    const removeIce = (counter) => {
        const ICEStmp = [...ICES];
        ICEStmp.splice(counter, 1);
        setICES(ICEStmp);
        formikProps.values.ICE.splice(counter, 1);
    };

    const isValid = (index, value) => (!formikProps.errors || !formikProps.errors.ICE || !formikProps.errors.ICE[index] || !formikProps.errors.ICE[index][value]) || (!formikProps.touched || !formikProps.touched.ICE || !formikProps.touched.ICE[index] || !formikProps.touched.ICE[index][value])

    const setValues = (index, key) => {
        return (formikProps.values.ICE[index][key]) || "";
    };

    const display = () => {
        return ICES.map((values, counter) =>
            <div key={counter} className="form">
                <div className="row flex-row justify-content-around">
                    <div className="col-12 col-md-6">
                        <div className="mb-4 form-group">
                            <label htmlFor={`ICE[${counter}].first_name`} className={!isValid(counter, 'first_name') ? 'is-invalid' : ""}><FormattedMessage id={`${translationPath}.firstName`} />{counter === 0 && "*"}</label>
                            <input className="form-control" name={`ICE[${counter}].first_name`} onChange={formikProps.handleChange} onBlur={formikProps.handleBlur} value={setValues(counter, 'first_name')}></input>
                            <InvalidFeedBackICE formikProps={formikProps} index={counter} value='first_name' />
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="mb-4 form-group">
                            <label htmlFor={`ICE[${counter}].last_name`} className={!isValid(counter, 'last_name') ? 'is-invalid' : ""}><FormattedMessage id={`${translationPath}.lastName`} />{counter === 0 && "*"}</label>
                            <input className="form-control" name={`ICE[${counter}].last_name`} onChange={formikProps.handleChange} onBlur={formikProps.handleBlur} value={setValues(counter, 'last_name')}></input>
                            <InvalidFeedBackICE formikProps={formikProps} index={counter} value='last_name' />
                        </div>
                    </div>
                </div>
                <div className="row flex-row justify-content-around">
                    <div className="col-12 col-md-6">
                        <div className="mb-4 form-group">
                            <label htmlFor={`ICE[${counter}].address_number_street`} className={!isValid(counter, 'address_number_street') ? 'is-invalid' : ""}><FormattedMessage id={`${translationPath}.numberAndStreet`} />{counter === 0 && "*"}</label>
                            <input className="form-control" name={`ICE[${counter}].address_number_street`} onChange={formikProps.handleChange} onBlur={formikProps.handleBlur} value={setValues(counter, 'address_number_street')}></input>
                            <InvalidFeedBackICE formikProps={formikProps} index={counter} value='address_number_street' />
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="mb-4 form-group">
                            <label htmlFor={`ICE[${counter}].address_postal_code`} className={!isValid(counter, 'address_postal_code') ? 'is-invalid' : ""}><FormattedMessage id={`${translationPath}.postalCode`} />{counter === 0 && "*"}</label>
                            <input className="form-control" name={`ICE[${counter}].address_postal_code`} onChange={formikProps.handleChange} onBlur={formikProps.handleBlur} value={setValues(counter, 'address_postal_code')}></input>
                            <InvalidFeedBackICE formikProps={formikProps} index={counter} value='address_postal_code' />
                        </div>
                    </div>
                </div>
                <div className="row flex-row justify-content-around">
                    <div className="col-12 col-md-6">
                        <div className="mb-4 form-group">
                            <label htmlFor={`ICE[${counter}].address_city`} className={!isValid(counter, 'address_city') ? 'is-invalid' : ""}><FormattedMessage id={`${translationPath}.addressCity`} />{counter === 0 && "*"}</label>
                            <input className="form-control" name={`ICE[${counter}].address_city`} onChange={formikProps.handleChange} onBlur={formikProps.handleBlur} value={setValues(counter, 'address_city')}></input>
                            <InvalidFeedBackICE formikProps={formikProps} index={counter} value='address_city' />
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <CountrySelectorICE formikProps={formikProps} counter={counter} translateContext={translateContext} translationPath={translationPath} required={counter === 0}/>
                    </div>
                </div>
                <div className="row flex-row justify-content-left">
                    <div className='col-6'>
                        <div className="mb-4 form-group">
                            <label htmlFor={`ICE[${counter}].relationship`} className={!isValid(counter, 'relationship') ? 'is-invalid' : ""}><FormattedMessage id={`${translationPath}.relationShip`} />{counter === 0 && "*"}</label>
                            <input className="form-control" name={`ICE[${counter}].relationship`} onChange={formikProps.handleChange} onBlur={formikProps.handleBlur} value={setValues(counter, 'relationship')}></input>
                            <InvalidFeedBackICE formikProps={formikProps} index={counter} value='relationship' />
                        </div>
                    </div>
                </div>
                <div className="row flex-row justify-content-around">
                    <div className="col-12 col-md-6">
                        <PhoneInputICE formikProps={formikProps} counter={counter} translationPath={translationPath} required={counter === 0}/>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="mb-4 form-group">
                            <label htmlFor={`ICE[${counter}].email`} className={!isValid(counter, 'email') ? 'is-invalid' : ""}><FormattedMessage id={`${translationPath}.email`} />{counter === 0 && "*"}</label>
                            <input className="form-control" name={`ICE[${counter}].email`} onChange={formikProps.handleChange} onBlur={formikProps.handleBlur} value={setValues(counter, 'email')}></input>
                            <InvalidFeedBackICE formikProps={formikProps} index={counter} value='email' />
                        </div>
                    </div>
                </div>
                <div className="d-flex mb-4">
                    {(counter === ICES.length - 1) && <button type="button" className="btn btn-signin mr-2" onClick={addIces} ><FormattedMessage id="profile.sections.individualsInformations.personsToContact.addPerson" /> </button>}
                    {ICES.length > 1 && <button type="button" className="btn btn-signin ml-2" onClick={() => removeIce(counter)} ><FormattedMessage id="profile.sections.individualsInformations.personsToContact.removePerson" /></button>}
                </div>
                <hr className='w-100' style={{border: '0.5px solid grey'}}/>
            </div>
        );
    }
    return display();
};

export default TranslateContextWrapper(PersonsToContact);