import React from "react";
import {FormattedMessage} from "react-intl";
import client from "../../ApolloSetup";
import {REINIT_CREATION_TOKEN} from "./Mutations/ReinitCreationToken";

const sendNewMail = (accountCreationToken, history) => {
    
    client.mutate({
        mutation: REINIT_CREATION_TOKEN,
        variables: {accountCreationToken: accountCreationToken}
    })
    .then(values => {history.push("/authentication/mail_inscription")})
    .catch(err => console.log("ReinitRegistration => ", err));
}

const ReinitRegistration = (props) => {
    
    const { accountCreationToken } = props.match.params;
    const {history} = props;

    return (
        <div className="d-flex flex-column align-items-center mt-5 template">
            <div className="d-flex flex-column justify-content-center circle-mail-confirmation px-4">
                <div className="position-absolute logo-mail">
                    <img className="img-fluid mb-8" src={require(`../../Static/Illustrations/illu_password.svg`)} alt='emailImg' />
                </div>
                <div className="px-4 content-text-circle-mail text-center">
                    <p className="font-weight-bold text-center title-template">
                        <FormattedMessage id="reinit_token.title" defaultMessage="Not translated" />
                    </p>
                    <div className="text-template">
                        <p>
                            <FormattedMessage id="reinit_token.text" defaultMessage="Not translated" />
                        </p>
                        <button onClick={() => sendNewMail(accountCreationToken, history)} className='btn btn-civipol'>
                            <FormattedMessage id="reinit_token.button" defaultMessage="Not translated" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReinitRegistration;