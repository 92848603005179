import React, { Fragment, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import OfferComponent from './OfferComponent';
import { OffersContext } from '../../Contexts/OffersContext';

const ProposalsOffers = () => {

    const { state } = useContext(OffersContext);
    const { offers } = state;

    return (
        <Fragment>
            <h2 className="title text-uppercase"><FormattedMessage id="offers.myMissionTitle" defaultMessage="Not translated" /></h2>
            {offers.map((value, key) => {
                let display = true;
                if (value.missions) {
                    for (let mission in value.missions) {
                        if (value.missions[mission].staffed === "Terminated" || value.missions[mission].staffed === "InMission" || value.missions[mission].staffed === "Agreed") {
                            display = false;
                        }
                    }
                }
                if (display) {
                    return (
                        <div className={(key < offers.length - 1) ? "underligne mb-3" : ""} key={key}>
                            <OfferComponent value={value} key={key} />
                        </div>
                    )
                } else return null;
            })}
        </Fragment>
    )
}

export default ProposalsOffers;