import React from "react";
import { FormattedMessage } from "react-intl";
import {Link} from "react-router-dom";

const ReinitPasswordButton = (props) => {
    return (
        <Link className="btn btn-signin" to="/expert/my_profile/reinit_pasword">
            <FormattedMessage id="account.reinitPasswordButton" />
        </Link>
    );
}

export default ReinitPasswordButton;