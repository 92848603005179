import React from 'react';
import { FormattedMessage } from 'react-intl';
import ScrollUpButton from 'react-scroll-up-button';
import { withCookies } from 'react-cookie';
import { withRouter, Link } from 'react-router-dom';

const Footer = (props) => {
    const url = props.location.pathname.split("/");
    if (url[1] !== "employees") {
        return (
            ((window.location.pathname.indexOf("authentication") === -1) ?
                <div id="footer">
                    <div className="top-footer">
                        <div className="container">
                            <div className="d-flex flex-column flex-lg-row align-items-center position-relative">
                                <div className="mr-5 mr-sm-auto mb-4 mb-lg-0">
                                    <img className="img-fluid"
                                        src={require("../../Static/Images/logo-civipol-expert-white.svg")}
                                        alt='logo'
                                    />
                                </div>

                                <ul className="clearfix nav">
                                    <li className="nav-item mb-2 mb-sm-0">
                                        <a href={`${process.env.REACT_APP_CIVIPOL_VITRINE}/fr/contact`} className="btn btn-footer" target="__blank">
                                            <FormattedMessage id="footer.contactUs" />
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href={`${process.env.REACT_APP_CIVIPOL_VITRINE}/fr/contact`} className="btn btn-footer" target="__blank">
                                            <FormattedMessage id="footer.infosDemand" />
                                        </a>
                                    </li>
                                </ul>

                                <div>
                                    <ScrollUpButton StopPosition={0} ContainerClassName="ml-3">
                                        <img
                                            src={require("../../Static/Icons/icon_scrolltotop.svg")}
                                            alt="scrollTop"
                                        ></img>
                                    </ScrollUpButton>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="sub-footer">
                        <div className="container">
                            <div className="row">
                                <div
                                    className="col-12 col-lg-6 mb-4 mb-lg-0 d-flex flex-column align-items-start justify-content-center">
                                    <FormattedMessage id="footer.findUs" defaultMessage="Not translated" />
                                    <div className="d-flex">
                                        <div className="social-networks mr-2">
                                            <div className="d-flex align-items-center">
                                                <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/civi-pol-conseil/">
                                                    <img src={require("../../Static/logos/logo_social_linkedin_blue.svg")}
                                                        className="img-fluid"
                                                        alt="logo_linkedin" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="social-networks">
                                            <div className="d-flex align-items-center">
                                                <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/Civipol">
                                                    <img src={require("../../Static/logos/icon_social_facebook_blue.svg")}
                                                        className="img-fluid"
                                                        alt="logo_facebook" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div
                                        className="d-flex justify-content-center justify-content-lg-end align-content-center align-items-center">
                                        <a className="mr-2" href="https://www.interieur.gouv.fr/">
                                            <img src={require("../../Static/logos/logo-ministerInterieur.svg")}
                                                className="img-fluid"
                                                alt='logo_ministere' />
                                        </a>
                                        <a className="ml-2 mr-2" href="https://www.transtec.be/">
                                            <img src={require("../../Static/logos/logo-transtec.svg")}
                                                className="img-fluid"
                                                alt='logo_transtec' />
                                        </a>
                                        <a className="ml-2" href="https://www.milipol.com/">
                                            <img src={require("../../Static/logos/logo-milipol.svg")}
                                                className="img-fluid"
                                                alt='logo_milipol' />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-2"></hr>
                            <div className="row">
                                <div
                                    className="col-12 col-lg-6 mb-4 mb-lg-0 d-flex justify-content-center justify-content-lg-start align-items-center">
                                    <p className="mb-0">Copyright © 2019 - Site créé par la société <a
                                        href="https://wassa.io/fr/" target="_blank" rel="noopener noreferrer"><u>Wassa</u></a></p>
                                </div>
                                <div
                                    className="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end align-items-center">
                                    <ul className="clearfix nav navbar-nav" id="menu-link-footer">
                                        <li className="nav-item"><a
                                            href="https://civipol.fr/mentions-legales">Mentions
                                        légales</a>
                                        </li>
                                        <li className="nav-item"><a
                                            href="https://civipol.fr/cookies">Cookies</a></li>
                                        <li>
                                            <Link to="/rgpd" className="nav-item">RGPD</Link>
                                        </li>
                                        <li className="nav-item"><a
                                            href="https://civipol.fr/cgu">CGU</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : null)
        )
    } else return null;
}

export default withRouter(withCookies(Footer));
