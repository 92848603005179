import React, { useState } from "react";
import { FormattedMessage } from 'react-intl';
import PhoneInput from "./PhoneInput";
import Input from "./Input";
import Selector from '../Selector';
import * as countries from "i18n-iso-countries";

const sectionMessageName = "profile.sections.identity.";

const SecondAddress = (props) => {
    let array = Object.values(countries.getNames("fr"));
    const path = "profile.sections.identity.contactInformations.secondAddress";

    return (
        <div >
            <h3><FormattedMessage id={props.path + ".secondAddress.title"} defaultMessage="Not translated"/></h3>
            <Input name="identity.address_2_number_street" label={props.path + ".secondAddress.inputs.address_2_number_street"} padding="pl-0" columns="col-12" formik={props} maxlength="60"/>
            <div className="row flex-row justify-content-around">
                <Input name="identity.address_2_postal_code" label={props.path + ".secondAddress.inputs.postalCode2"} formik={props} maxlength="20"/>
                <Input name="identity.address_2_city" label={props.path + ".secondAddress.inputs.city2"} formik={props} maxlength="30" />
            </div>
            <div className="col-6 mb-4 px-0">
                <Selector array={array} section="identity" name="address_2_country" path2={path} {...props} />
            </div>
        </div>
    )
}

// ce composant affiche l'input pour la premiere addresse
const FirstAddress = (props) => {
    let array = Object.values(countries.getNames("fr"));
    const path = "profile.sections.identity.contactInformations.firstAddress";
    return (
        <div>
            <div className="row">
                <div className="col">
                    <h3><FormattedMessage id={props.path + ".firstAddress.title"} defaultMessage="Not translated"/></h3>
                </div>
            </div>
            <div className="row">
                <Input name="identity.address_1_number_street" label={props.path + ".firstAddress.inputs.address_1_number_street"} columns="col" formik={props} required />
            </div>
            <div className="row">
                <Input name="identity.address_1_postal_code" label={props.path + ".firstAddress.inputs.postalCode"} maxlength="20" formik={props} required/>
                <Input name="identity.address_1_city" label={props.path + ".firstAddress.inputs.city"} formik={props} maxlength="30" required/>
            </div>
            <div className="row">
                <div className="col-6 mb-4">
                    <Selector array={array} section="identity" name="address_1_country" path2={path} {...props} required />
                </div>
            </div>
        </div>
    )
}

const Contact = (props) => {
    return (
        <div>
            <div className="row">
                <div className="col">
                    <h3><FormattedMessage id={props.path + ".contact.title"} defaultMessage="Not translated"/></h3>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6">
                    <PhoneInput {...props} label={props.path + ".contact.inputs.tel_mobile"} section="identity" name="tel_mobile" required />
                </div>
                <div className="col-12 col-md-6">
                    <PhoneInput {...props} label={props.path + ".contact.inputs.tel_fix"} section="identity" name="tel_fix" facultatif />
                </div>
            </div>
            <div className="row ">
                <Input name="identity.email_1" label={props.path + ".contact.inputs.firstEmail"} formik={props} maxlength="50" required />
                <Input name="identity.email_2" label={props.path + ".contact.inputs.secondEmail"} formik={props} facultatif maxlength="50"/>
            </div>
        </div>
    )
}

//retourne le composant de contact de l'expert
const ContactInformations = (props) => {
    //base path pour les traductions
    const path = sectionMessageName + "contactInformations";

    //permet de définir si on ouvre ou pas la boite de seconde adresse
    const [secondAddress, setSecondAddress] = useState(false);

    //si on clique sur ce bouton on affiche la boxe de seconde adresse -> lorsqu'on reclique supprime la seconde adresse
    const Button = () => (
        <button
            style={{ fontFamily: "DIN-BOLD" }}
            className="btn btn-signin mb-5"
            onClick={(e) => {
                e.preventDefault();
                setSecondAddress(!secondAddress)
            }}>
            {<FormattedMessage id={(secondAddress) ? path + ".buttons.remove" : path + ".buttons.add"} defaultMessage="Not translated"/>}
        </button>
    )


    //retourne les informations de contact de l'expert
    return (
        <div className="mb-4">
            <h2 className="title text-uppercase"><FormattedMessage id={sectionMessageName + "contactInformations.title"} defaultMessage="Not translated"/> </h2>
            <FirstAddress {...props} path={path} />
            {secondAddress && <SecondAddress {...props} path={path} />}
            <Button />
            <Contact {...props} path={path} />
        </div>
    )
}

export default ContactInformations;
