import React from "react";
import { TranslateContextWrapper } from "../../../Contexts/TranslateContext";
import { evaluationConstants } from "../evaluation.constants";
import * as moment from "moment";

const {
    DATE_TITLE,
} = evaluationConstants;

const HeaderPage = (props) => {

    const { firstName, lastName } = props.elem.expert;
    const { translateMessage } = props.translateContext;
    return (
        <div>
            <div className="row justify-content-center">
                <div className="col-12 mb-5 missions">
                    <h1 className="big-title">{translateMessage("evaluationExpert.evaluationOf", "Not Translated", { firstName_expert: firstName, lastName_expert: lastName })}</h1>
                    <div className="encoche-title"></div>
                </div>
            </div>
            <div className="form-group row">
                <div className="col-12">
                    <div className="d-flex align-items-center mb-4">
                        <h2 className="title text-uppercase  mb-0 mr-3">{DATE_TITLE}</h2>
                        <span>{moment(props.elem.createdAt).format("DD/MM/YYYY")}</span>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default TranslateContextWrapper(HeaderPage);