import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Element } from 'react-scroll'
import { TranslateContextWrapper } from "../../Contexts/TranslateContext";
import MissionsMaper from "./MissionsMaper";
import { MissionsContext } from "../../Contexts/MissionsContext";
import Notification from "./Notifications";

const Missions = (props) => {

    const { translateContext } = props;
    const { state } = useContext(MissionsContext);
    const { finished, inMission, acceptedButNotStart } = { ...state.missions };
    const { loading } = { state };

    let values = {
        nb_performed: finished.length,
        plural: (finished.length > 0) ? "s" : "",
    }
    let subtitle = translateContext.translateMessage("missions.missionsPerformed", "", values);
    return (
        <Element id="2" name="2">
            <div className="row">
                <div className="col mb-4">
                    <h1 ><FormattedMessage id="missions.myMissions" defaultMessage="Not translated"/></h1>
                    <div className="encoche-title mb-2"></div>
                </div>
            </div>
            <div className="row">
                {(!loading && finished.length === 0 && inMission.length === 0 && acceptedButNotStart.length === 0) && <Notification title='missions.notificationNoMissionTitle' text='missions.notificationNoMissionText' type="missions" />}
                {(!loading && (finished.length > 0 || inMission.length > 0 || acceptedButNotStart.length > 0)) &&
                <div className="col">
                    <div className="row mb-4">
                        <div className="col">
                            <p className="subtitle">{subtitle}</p>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col">
                            <MissionsMaper />
                        </div>
                    </div>
                </div>
                }
            </div>
        </Element>

    )
}
export default TranslateContextWrapper(Missions);
